import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-all-solusion',
  templateUrl: './all-solusion.component.html',
  styleUrls: ['./all-solusion.component.css']
})
export class AllSolusionComponent implements OnInit {
  telco_img_hover: boolean = true;
  intl_img_hover: boolean = false;
  supply_img_hover: boolean = false;
  smart_img_hover: boolean = false;

  temp_img_hover: boolean = true;
  press_img_hover: boolean = false;
  power_img_hover: boolean = false;
  motion_img_hover: boolean = false;
  vibration_img_hover: boolean = false;
  distance_img_hover: boolean = false;
  gyro_img_hover: boolean = false;
  gas_img_hover: boolean = false;
  intrusion_img_hover: boolean = false;
  light_img_hover: boolean = false;

  constructor() { }

  ngOnInit(): void {
  
  }

}
