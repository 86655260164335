
<div mat-dialog-content>
  <div class="modal-body Request_demo_new">
    <button type="button" class="close"  (click)="onNoClick()">
      <span aria-hidden="true"><img src="assets/img/cancel.svg"></span>
    </button>
    <div class="sp5_right ml-auto d-flex align-items-center">
      <div class="Request_form_width">
        <form (ngSubmit)="onSubmit()" #contactForm="ngForm">
          <div class="row" [hidden]="submitted">
            <div class="col-md-12 col-12">
              <h2>Request A  Trial</h2>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label>Name*</label>
                <input type="text" name="name" id="name" [(ngModel)]="model.name" class="form-control" required #name="ngModel" placeholder="Your Name">
                <div *ngIf="name.invalid && (name.dirty || name.touched)" class="error" >
                  <div *ngIf="name.errors.required">
                    Name is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label>Contact No.*</label>
                <input type="text" name="phone" id="phone" [(ngModel)]="model.phone" [pattern]="mobNumberPattern" class="form-control" required #phone="ngModel" placeholder="Your Contact No.">
                <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="error">
                <div *ngIf="phone.errors.required">Contact No. is required.</div>
                <div *ngIf="phone.errors.pattern">Contact No. not a valid number.</div> 
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label>Email*</label>
                <input type="text" name="email" id="email" [(ngModel)]="model.email" class="form-control" required email #email="ngModel" placeholder="Email Address">
                <div *ngIf="email.invalid && (email.dirty || email.touched)" class="error">
                  <div *ngIf="email.errors.required">Email is required.</div>
                  <div *ngIf="email.errors.email">Email must be a valid email address.</div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label>Designation*</label>
                <input type="text" name="designation" id="designation" [(ngModel)]="model.designation" class="form-control" required #designation="ngModel" placeholder="Your Designation">
                <div *ngIf="designation.invalid && (designation.dirty || designation.touched)" class="error">
                <div *ngIf="designation.errors.required">Designation is required.</div>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-12">
              <div class="form-group">
                <label>Company Name*</label>
                <input type="text" name="comp_name" id="comp_name" [(ngModel)]="model.comp_name" class="form-control" required #comp_name="ngModel">
                <div *ngIf="comp_name.invalid && (comp_name.dirty || comp_name.touched)" class="error">
                  <div *ngIf="comp_name.errors.required">Company Name is required.</div>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-12">
              <div class="form-group">
                <label>Message*</label>
                <textarea name="message" id="message" [(ngModel)]="model.message" rows="5" class="form-control" placeholder="Start Typing Here" required #message="ngModel"></textarea>
                <div *ngIf="message.invalid && (message.dirty || message.touched)" class="error">
                  <div *ngIf="message.errors.required">Message is required.</div>
                </div>
              </div>
            </div>
            <div class="col-12 submit_center">
              <button [disabled]="!contactForm.form.valid" type="submit" class="btn btn-secondary SUbmit_btn">Submit</button>
            </div>
          </div>
        </form>
        <div class="service-error" *ngIf="error">
          <h1>{{error.errorTitle}}</h1>
          <h3>{{error.errorDesc}}</h3>
        </div>
        <div [hidden]="!submitted" class="contact-message">
          <div *ngIf="!error" class="contact-success">
            <h4>Hey! We have received your request for a trail.One of our specialised consultants will be in touch with you very soon.</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
