import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequestDemoComponent } from '../../modals/request-demo/request-demo.component';
import { YourcolleagueComponent } from '../../modals/yourcolleague/yourcolleague.component';


@Component({
  selector: 'app-sitsensor',
  templateUrl: './sitsensor.component.html',
  styleUrls: ['./sitsensor.component.css']
})
export class SITSensorComponent implements OnInit {

   email: string;
  
  constructor(public dialog: MatDialog) {}

  openDialog(): void {
    const dialogRef = this.dialog.open(RequestDemoComponent,  {
      maxWidth: '50vw',
      maxHeight: '75vh',
      height: '100%',
      width: '100%'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.email = result;
    });
  }

   openDialog11(): void {
    const dialogRef = this.dialog.open(RequestDemoComponent,  {
      maxWidth: '50vw',
      maxHeight: '75vh',
      height: '100%',
      width: '100%'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.email = result;
    });
  }

  ngOnInit(): void {
  }


  toVideo(){
document.getElementById("Video").scrollIntoView({behavior:"smooth"});
}

tofeatures(){
document.getElementById("features").scrollIntoView({behavior:"smooth"});
}



tousecase(){
document.getElementById("usecase").scrollIntoView({behavior:"smooth"});
}

toHowitwork(){
document.getElementById("Howitwork").scrollIntoView({behavior:"smooth"});
}

toTechSpecs(){
document.getElementById("TechSpecs").scrollIntoView({behavior:"smooth"});
}


toDemo(){
document.getElementById("Demo").scrollIntoView({behavior:"smooth"});
}



}
