
<!--====================slider section====================-->
<section class="slider_section text-left all-use-case All-use-case_banner" id="introduction">
    <div class="container">
        <div class="slider_innertext ">
            <h2 data-aos="fade-up">Use Case</h2>
            <p data-aos="fade-up">IoT enables businesses to solve complicated operational issues with remote analysis and data intelligence. Our use cases can be customised as per your business needs to provide access to real-time actionable insights for data-driven decisions and business intelligence.</p>
            <div class="text-left" data-aos="fade-up">
                <button type="button" class="btn btn-primary" [routerLink]="'/contact-us'" fragment="contactus_offset" >Drop a query</button>
        <button type="button" class="btn btn-secoundry" (click)="openDialog()">Request a demo</button>
            </div>
        </div>
    </div>
    <div class="moving-mouse-holder" >
    <a (click)="toRevolutionise()">
  <div class="mouse">
    <div class="mouse-button">&nbsp;</div>
  </div>
  <div class="text">Scroll To Explore</div>
</a>
</div>
</section>
<!--====================slider section end====================-->
<div class="clearfix"></div>
<section class="about_menu" id="mainNav">
    <div class="container-fluid">
        <div class="nav_center">
            <ul class="nav bottom_nav">
                <li class="nav-item"><a (click)="toTemperature()" class="js-scroll-trigger">Temperature & Humidity</a></li>
                <li class="nav-item"><a class="js-scroll-trigger" (click)="toPressure()" >Pressure & flow</a></li>
                <li class="nav-item"><a class="js-scroll-trigger"
                (click)="toPowerDetection()" > Power Detection</a></li>
                <li class="nav-item"><a class="js-scroll-trigger" (click)="toMotion()" > Motion </a></li>
                <li class="nav-item"><a class="js-scroll-trigger" (click)="toVibration()">Vibration</a></li>
                <li class="nav-item"><a class="js-scroll-trigger" 
                    (click)="toDistanceLevel()" >Distance/Level</a></li>
                <li class="nav-item"><a class="js-scroll-trigger" (click)="toGyroscope()">Gyroscope & Accelerometer</a></li>
                <!--   <li class="nav-item"><a class="js-scroll-trigger" href="javascript:void(0);">Gyroscope</a></li> -->
                <li class="nav-item"><a class="js-scroll-trigger"  (click)="toGasDetection()">Gas Detection</a></li>
                <li class="nav-item"><a class="js-scroll-trigger" 
                (click)="toIntrusiondetection()">Intrusion Detection</a></li>
                <li class="nav-item"><a class="js-scroll-trigger" (click)="toLight()" > Light</a></li>
            </ul>
        </div>
    </div>
</section>
<div id="Revolutionise">
<section class="Revolutionise-your-business text-center">
    <div class="container">
        <h3 class="blue-dark" data-aos="fade-up">Revolutionise your business with customised IoT solutions</h3>
        <p data-aos="fade-up">Senstra IoT solutions has explored various critical use cases that can solve a wide spectrum of business challenges. Our use cases can be mapped and customised to match specific business needs for deployment of fit-for-purpose solutions.</p>
    </div>
</section>
</div>
<div class="clearfix"></div>
<section class="Use-Cases">
    <div class="container">
        <div class="row">
            <div class="col-md-1 col-12"></div>
            <div class="col-md-10 col-12">
                <h1 class="gray_color" data-aos="fade-left">Use Cases</h1>
                <div  id="Temperature">
                <div class="row use-case-top_div m-t-0 usecase_margin" data-aos="fade-up">
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-left">
                            <h3 class="blue-dark"> Temperature and Humidity Sensing</h3>
                             <img src="assets/img/Temperature-and-Humidity-Sensing.jpg" alt="Temperature-and-Humidity-Sensing">
                            <p>Our nodes can provide multi-point temperature and humidity readings.</p>
                            <ul>
                                <li>Industry standard Temperature and Humidity sensor reducing the risk of critical assets going down and ensuring business continuity.</li>
                                <li> Multiple temperature sensors can connected per device to provide a range of readings.</li>
                                <li> Data is captured and ingested at the frequency that matters to you.</li>
                                <li> Email, SMS and system alerts when thresholds are crossed to reduce the risk of damage to what matters to you.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-right">
                            <img src="assets/img/Temperature-and-Humidity-Sensing.jpg" alt="Temperature-and-Humidity-Sensing">
                        </div>
                    </div>
                </div>
                </div>
                <div id="Pressure">
                <div class="row use-case-top_div"  data-aos="fade-up">
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-left">
                            <h3 class="blue-dark"> Pressure and flow</h3>
                              <img src="assets/img/Pressure-and-flow.jpg" alt="Pressure-and-flow">
                            <p>Pressure and flow sensing can help prevent disasters and health of the assets. With highly customised threshold settings, pressure and flow detection in hard to reach places via IP68 devices can withstand pressure, water flow, dust and other environmental variables.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-right">
                            <img src="assets/img/Pressure-and-flow.jpg" alt="Pressure-and-flow">
                        </div>
                    </div>
                </div>
                </div>
                <div id="PowerDetection">
                <div class="row use-case-top_div"  data-aos="fade-up">
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-left">
                            <h3 class="blue-dark"> Power Detection</h3>
                             <img src="assets/img/Power-Detection.jpg" alt="Power-Detection">
                            <p>With planned and unplanned power outages bringing down the most valuable assets and thus depriving thousands of customers from using the network; our sensor can detect power failures leading to:</p>
                            <ul>
                                <li>Accurate problem identification and making third parties accountable</li>
                                <li>Reduce operation costs</li>
                                <li>Improving customer experience</li>
                                <li>Accurately calculate KPIs to account for these uncontrollable environmental factors</li>
                            </ul>                          
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-right">
                            <img src="assets/img/Power-Detection.jpg" alt="Power-Detection">
                        </div>
                    </div>
                </div>
                </div>
                <div id="Motion">
                <div class="row use-case-top_div"  data-aos="fade-up">
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-left">
                            <h3 class="blue-dark"> Motion</h3>
                             <img src="assets/img/Motion.jpg" alt="Motion">
                            <p>Motion sensors can help sense the motion of the asset and real time updates of the motion and the details of it every 15 seconds.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-right">
                            <img src="assets/img/Motion.jpg" alt="Motion">
                        </div>
                    </div>
                </div>
                </div>
                <div id="Vibration">
                <div class="row use-case-top_div"  data-aos="fade-up">
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-left">
                            <h3 class="blue-dark"> Vibration</h3>
                             <img src="assets/img/Vibration.jpg" alt="Vibration">
                            <p>Vibration sensing can help sense the vibration at various levels. Combined with noise sensing, the device can detect vibrations at microscopic level.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-right">
                            <img src="assets/img/Vibration.jpg" alt="Vibration">
                        </div>
                    </div>
                </div>
                </div>
                <div id="DistanceLevel">
                <div class="row use-case-top_div"  data-aos="fade-up">
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-left">
                            <h3 class="blue-dark"> Distance/Level</h3>
                             <img src="assets/img/Distance-Level.jpg" alt="DistanceLevel">
                            <p>Distance sensing can be achieved using ultrasonic sensors. This capability can be put to practical use like water level detection, flooding, liquid level in a container etc.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-right">
                            <img src="assets/img/Distance-Level.jpg" alt="DistanceLevel">
                        </div>
                    </div>
                </div>
                </div>
                <div id="Gyroscope">
                <div class="row use-case-top_div"  data-aos="fade-up">
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-left">
                            <h3 class="blue-dark"> Gyroscope and Accelerometer</h3>
                             <img src="assets/img/Gyroscope-and-Accelerometer.jpg" alt="Gyroscope-and-Accelerometer">
                            <p>Traditional supply chain limits the knowledge of the assets to an operator accurately entering the information into the ERP systems such as SAP. With our Gyroscope and Accelerometer equipped sensors, you can report real time location of your shipment and this works across countries. With most of the countries embracing NB-IoT technology, we can have real time location detection while shipment is in Transit.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-right">
                            <img src="assets/img/Gyroscope-and-Accelerometer.jpg" alt="Gyroscope-and-Accelerometer">
                        </div>
                    </div>
                </div>
                </div>
                <div id="GasDetection">
                <div class="row use-case-top_div"  data-aos="fade-up">
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-left">
                            <h3 class="blue-dark"> Gas Detection</h3>
                             <img src="assets/img/Gas-Detection.jpg" alt="Gas-Detection">
                            <p>Gas detection sensors can be used to detect flammable and inflammable gases. Just like Temperature and Humidity; certain products and shipments may require a real time alert system of gas detection and audit of the same. Gas detection sensors in our NB-IoT enabled shipment tracking system can inform you of all the information along with when and where shipment during the gas detection event.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-right">
                            <img src="assets/img/Gas-Detection.jpg" alt="Gas-Detection">
                        </div>
                    </div>
                </div>
                  </div>
                  <div id="Intrusiondetection">
                <div class="row use-case-top_div"  data-aos="fade-up">
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-left">
                            <h3 class="blue-dark">Intrusion detection</h3>
                             <img src="assets/img/Intrusion-detection.jpg" alt="Intrusion-detection">
                            <p>Detect intrusion into restricted areas and protect critical assets.</p>
                            <ul>
                                <li>Real-Time intrusion detection allowing you to act instantly and protect critical assets.</li>
                                <li>Device will wake and provide real time alerts when an intrusion is detected.</li>
                                <li>Real time alerts can be customised for the channel that suits you whether it be email, sms or system alerts.</li>
                                <li> Rest-API access for further data analysis.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-right">
                            <img src="assets/img/Intrusion-detection.jpg" alt="Intrusion-detection">
                        </div>
                    </div>
                </div>
                </div>
                <div id="Light">
                <div class="row use-case-top_div"  data-aos="fade-up">
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-left">
                            <h3 class="blue-dark">Light</h3>
                            <img src="assets/img/Light.jpg" alt="Light">
                            <p>Senstra’s light detection technology can detect the presence of light as well as the intensity of it. This is useful in taking the right steps towards proactive management of the assets where lights are fundamental to operations.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-right">
                            <img src="assets/img/Light.jpg" alt="Light">
                        </div>
                    </div>
                </div>
            </div>
           
              </div>
        </div>
        <div class="col-md-1 col-12"></div>
    </div>
</section>
