<section class="byindustry" id="byindusrytab">
        <div class="container">
             <h1 class="gray_color" data-aos="fade-right">By Industry</h1>
            <div class="row">
                <div class="col-md-3 col-sm-6 col-12" data-aos="fade-up">
                    <a  [routerLink]="'/telco-infrastructure'">
                    <div class="by_industry">
                        <img src="assets/img/Telco & Infrastructure.jpg">
                        <h4 class="blue-dark">Telco & Infrastructure</h4>
                    </div>
                    </a>
                </div>
                <div class="col-md-3 col-sm-6 col-12" data-aos="fade-up">
                    <a [routerLink]="'/intelligent-traffic'">
                    <div class="by_industry" >
                        <img src="assets/img/Intelligent Traffic.jpg">
                        <h4 class="blue-dark">Intelligent Traffic and Transportation Management</h4>
                    </div>
                </a>
                </div>
                <div class="col-md-3 col-sm-6 col-12" data-aos="fade-up">
                    <a  [routerLink]="'/utilities-power'">
                    <div class="by_industry">
                        <img src="assets/img/Supply-Chain-Logistics-desk.jpg">
                        <h4 class="blue-dark">Supply Chain & Logistics</h4>
                    </div>
                </a>
                </div>
                <div class="col-md-3 col-sm-6 col-12" data-aos="fade-up">
                    <a  [routerLink]="'/smart-cities'">
                    <div class="by_industry">
                        <img src="assets/img/Smart Cities.jpg">
                        <h4 class="blue-dark">Smart Cities</h4>
                    </div>
                </a>
                </div>
            </div>
        </div>
    </section>

    <section class="byindustry by_industry_new" id="byusecasetab">
        <div class="container">
             <h1 class="gray_color" data-aos="fade-right">By Use Case</h1>
            <div class="row">
                <div class="col-md-3 col-sm-6 col-12">
                    <a [routerLink]="'/all-use-case'"  fragment="Temperature">
                    <div class="by_industry" data-aos="fade-up">
                        <img src="assets/img/Temperature-and-Humidity-Sensing.jpg">
                        <h4 class="blue-dark">Temperature and Humidity Sensing</h4>
                    </div>
                </a>
                </div>
                <div class="col-md-3 col-sm-6 col-12">
                     <a [routerLink]="'/all-use-case'" fragment="Pressure">
                    <div class="by_industry" data-aos="fade-up">
                        <img src="assets/img/Pressure-and-flow.jpg">
                        <h4 class="blue-dark">Pressure and flow</h4>
                    </div>
                </a>
                </div>
                <div class="col-md-3 col-sm-6 col-12">
                     <a [routerLink]="'/all-use-case'" fragment="PowerDetection">
                    <div class="by_industry" data-aos="fade-up">
                        <img src="assets/img/Power-Detection.jpg">
                        <h4 class="blue-dark">Power Detection</h4>
                    </div>
                </a>
                </div>
                <div class="col-md-3 col-sm-6 col-12">
                     <a [routerLink]="'/all-use-case'" fragment="Motion">
                    <div class="by_industry" data-aos="fade-up">
                        <img src="assets/img/Motion.jpg">
                        <h4 class="blue-dark">Motion</h4>
                    </div>
                </a>
                </div>
                 <div class="col-md-3 col-sm-6 col-12">
                     <a [routerLink]="'/all-use-case'" fragment="Vibration">
                    <div class="by_industry" data-aos="fade-up">
                        <img src="assets/img/Vibration.jpg">
                        <h4 class="blue-dark">Vibration</h4>
                    </div>
                </a>
                </div>
                 <div class="col-md-3 col-sm-6 col-12">
                    <a [routerLink]="'/all-use-case'" fragment="DistanceLevel">
                    <div class="by_industry" data-aos="fade-up">
                        <img src="assets/img/Distance-Level.jpg">
                        <h4 class="blue-dark">Distance/Level</h4>
                    </div>
                </a>
                </div>
                 <div class="col-md-3 col-sm-6 col-12">
                    <a [routerLink]="'/all-use-case'" fragment="Gyroscope">
                    <div class="by_industry" data-aos="fade-up">
                        <img src="assets/img/Gyroscope-and-Accelerometer.jpg">
                        <h4 class="blue-dark">Gyroscope and Accelerometer</h4>
                    </div>
                </a>
                </div>
                 <div class="col-md-3 col-sm-6 col-12">
                    <a [routerLink]="'/all-use-case'" fragment="GasDetection">
                    <div class="by_industry" data-aos="fade-up">
                        <img src="assets/img/Gas-Detection.jpg">
                        <h4 class="blue-dark">Gas Detection</h4>
                    </div>
                </a>
                </div>
                 <div class="col-md-3 col-sm-6 col-12">
                    <a [routerLink]="'/all-use-case'" fragment="Intrusiondetection">
                    <div class="by_industry" data-aos="fade-up">
                        <img src="assets/img/Intrusion-detection.jpg">
                        <h4 class="blue-dark">Intrusion detection</h4>
                    </div>
                </a>
                </div>
                 <div class="col-md-3 col-sm-6 col-12">
                    <a [routerLink]="'/all-use-case'" fragment="Light">
                    <div class="by_industry" data-aos="fade-up">
                        <img src="assets/img/Light.jpg">
                        <h4 class="blue-dark">Light</h4>
                    </div>
                </a>
                </div>
            </div>
        </div>
    </section>