
<!--====================slider section====================-->
<section class="slider_section text-left all-use-case Workforce_banner" id="introduction">
    <div class="container">
        <div class="slider_innertext ">
            <h2 data-aos="fade-up">Workforce Management</h2>
            <p data-aos="fade-up" class="Intelligent-text_p">Our ePITome device is outfitted with logging and sensing technology to allow operators to monitor the location of the technician to stay informed of their well-being and provide further detail for billing reconciliation. Our sensors also provide more data and insights to the workforce in the field when used with our iOS and Android apps. Based on the data, get more insights about the personnels site visits, billable hours, analyse labour cost and improve operational productivity.</p>
            <div class="text-left" data-aos="fade-up">
                 <button type="button" class="btn btn-primary" [routerLink]="'/contact-us'" fragment="contactus_offset"  >Drop a query</button>
        <button type="button" class="btn btn-secoundry" (click)="openDialog()">Request a demo</button>
            </div>
        </div>
    </div>
     <div class="moving-mouse-holder" >
    <a (click)="toRevolutionise()">
  <div class="mouse">
    <div class="mouse-button">&nbsp;</div>
  </div>
  <div class="text">Scroll To Explore</div>
</a>
</div>
</section>
<!--====================slider section end====================-->
<div class="clearfix"></div>
<section class="about_menu" id="mainNav">
    <div class="container">
        <div class="nav_center">
            <ul class="nav bottom_nav" >
                <li class="nav-item"><a (click)="toUseCases()" class="js-scroll-trigger">Use Cases</a></li>
                <li class="nav-item"><a (click)="toKeyCapabilities()"  class="js-scroll-trigger">Key Capabilities and Benefits</a></li>
                <li class="nav-item"><a class="js-scroll-trigger" (click)="toresources()" > Resources</a></li>
                <li class="nav-item"><a class="js-scroll-trigger" (click)="todemo()"> Demo</a></li>
                 </ul>
        </div>
    </div>
</section>
<div id="Revolutionise">
<section class="Revolutionise-your-business text-center">
    <div class="container">
        <h3 class="blue-dark" data-aos="fade-up">Connecting you and your workforce</h3>
        <p data-aos="fade-up">Senstra’s big data insights and audit log associated with workforce and asset management delivers immediate and up to 80% operating cost reduction on non essential site visits and other activities. Arms you with traceability on root cause with predictable maintenance call times. Help identify asset related network issues, reduce unplanned power outages and lower detection costs. Lack of actionable insights can cost your business and remote assets. Avoid spending on non essential exploratory truck rolls, unpredictable maintenance, repair, and human costs and higher financial loss.</p>
    </div>
</section>
</div>
<div class="clearfix"></div>
<section class="Use-Cases">
    <div class="container">
        <div class="row">
            <div class="col-md-1 col-12"></div>
            <div class="col-md-10 col-12">
                <h1 class="gray_color" data-aos="fade-left">USE CASES</h1>
                <div class="row use-case-top_div m-t-0" id="Gyroscope" data-aos="fade-up">
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-left">
                            <h3 class="blue-dark"> Workplace Safety and Scheduling Convenience</h3>
                            <p>In a modern workplace, knowing temperature, humidity, previous hazardous situations can be crucial. Artificial Intelligence (AI) and Machine Learning (ML) can then help in scheduling workforce allowing more productive working hours.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-right">
                            <img src="assets/img/Temperature-and-Humidity-Sensing.jpg" alt="Temperature-and-Humidity-Sensing">
                        </div>
                    </div>
                </div>
                <div class="row use-case-top_div" id="Temperature" data-aos="fade-up">
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-left">
                            <h3 class="blue-dark">Audit and Intrusion Detection</h3>
                            <p>Our Intrusion detection suite comprises mobile apps, mechanical switch and low energy Bluetooth 5.0. This suite allows organizations to detect asset/area access and keep the log of it for auditing purposes.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-right">
                            <img src="assets/img/Asset-access-audit-and-Technician-assistance.jpg" alt="Asset-access-audit-and-Technician-assistance">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-1 col-12"></div>
        </div>
    </div>
</section>
<!--====================Benefits====================-->
<section class="Benefits">
    <div class="container">
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <div class="row Our-usps-box">
                    <h1 class="gray_color">Benefits</h1>
                    <h3 class="blue-dark">Key capabilities and benefits</h3>
                    <div class="col-md-4 col-12" data-aos="fade-up">
                        <div class="Benefits-our-inner-box">
                            <div class="key_img">
                            <img src="assets/img/Workforce productivity.svg" alt="Workforce productivity">
                        </div>
                            <h4>Workforce productivity</h4>
                        </div>
                    </div>
                    <div class="col-md-4 col-12" data-aos="fade-up">
                        <div class="Benefits-our-inner-box">
                            <div class="key_img">
                            <img src="assets/img/Productivity optimisation.svg" alt="Productivity optimisation">
                        </div>
                            <h4>Productivity optimisation</h4>
                        </div>
                    </div>
                    <div class="col-md-4 col-12" data-aos="fade-up">
                        <div class="Benefits-our-inner-box">
                            <div class="key_img">
                            <img src="assets/img/Real time site visit monitoring.svg" alt="Real time site visit monitoring">
                        </div>
                            <h4>Real time site visit monitoring</h4>
                        </div>
                    </div>
                    <div class="col-md-4 col-12" data-aos="fade-up">
                        <div class="Benefits-our-inner-box">
                            <div class="key_img">
                            <img src="assets/img/Personnel on-site reporting.svg" alt="Personnel on-site reporting">
                        </div>
                            <h4>Personnel on-site reporting</h4>
                        </div>
                    </div>
                    <div class="col-md-4 col-12" data-aos="fade-up">
                        <div class="Benefits-our-inner-box">
                            <div class="key_img">
                            <img src="assets/img/Entry  Exit Pit monitoring.svg">
                        </div>
                            <h4>Entry/Exit Pit Monitoring</h4>
                        </div>
                    </div>
                    <div class="col-md-4 col-12" data-aos="fade-up">
                        <div class="Benefits-our-inner-box">
                            <div class="key_img">
                            <img src="assets/img/SMS Email and App alerts.svg" alt="SMS Email and App alertsT">
                        </div>
                            <h4>SMS Email and App alerts</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
    </div>
</section>
<!--====================Our-Insights====================-->
<section class="Our-Insights resources_div">
    <div class="container" id="resources">
       <h1 class="gray_color">Our <br> Insights</h1>
        <div class="row blog_inner mar_res">
            <div class="col-md-6 col-12 aos-init" data-aos="fade-up">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="blog_left">
              <p class="blog_itelic">Blog</p>
              <a [routerLink]="'/telcos'" target="_blank">
                <h5 class="blue-dark">How Is IoT The Most Promising Innovation For Telcos</h5>
              </a>
              <a [routerLink]="'/telcos'" target="_blank">
                <img src="assets/img/Resources_blog_1.png" alt="img_1">
              </a>
              <p class="blog_p">What do you imagine when you think about the future of the Australian Telecommunication Industry? If you are someone from the Telecommunication business, then surely you have got your...</p>
              <a [routerLink]="'/telcos'" target="_blank" class="blue-light">Read More <img src="assets/img/left-arrow.svg"></a>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="blog_img">
              <a [routerLink]="'/telcos'" target="_blank">
                <img src="assets/img/img_1.jpg" alt="img_1">
              </a>
            </div>
          </div>
        </div>
      </div>
            <div class="col-md-6 col-12 aos-init" data-aos="fade-up">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="blog_left">
              <p class="blog_itelic">Article</p>
              <a [routerLink]="'/exclusive-signs'" target="_blank">
                <h5 class="blue-dark">4 Exclusive Signs Of Poor Asset Monitoring &amp; Soaring Need Of The Latest IoT Upgrade</h5>
              </a>
              <a [routerLink]="'/exclusive-signs'" target="_blank">
                <img src="assets/img/Resources_blog_2.png" alt="img_1">
              </a>
              <p class="blog_p">What is the pioneering characteristic of an effective asset monitoring system? In a worldwide survey conducted by IBM, 75% of asset managers cited system reliability as the single...</p>
              <a [routerLink]="'/exclusive-signs'" target="_blank" class="blue-light">Read More <img src="assets/img/left-arrow.svg"></a>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="blog_img">
              <a [routerLink]="'/exclusive-signs'" target="_blank">
                <img src="assets/img/img_2.jpg" alt="img_1">
              </a>
            </div>
          </div>
        </div>
      </div>
        </div>
        <div class="row">
            <div class="col-12 text-center" data-aos="fade-up">
                <button type="button" class="btn btn-secoundry allsolusion" [routerLink]="'/resources'">See all</button>
            </div>
        </div>
    </div>
</section>
<!--====================Work Managment end====================-->
<section id="demo">
<section class="contact_us" data-aos="fade-up">
    <div class="container">
        <div class="contact_us_inner">
            <h2 class="blue-dark">Contact us for personalised demo</h2>
            <button type="button" class="btn btn-primary allsolusion" (click)="openDialog()">Request a demo</button>
        </div>
    </div>
</section>
</section>
