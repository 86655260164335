import { Component, OnInit } from '@angular/core';
import { Report } from '../../../classes/report';
import { ReportService } from '../../../services/report.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  model = new Report();
  submitted = false;
  error: {};
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{9,10}$";

 constructor(
    private cmspageService: ReportService
    ) { }

  ngOnInit() {
  }

  onSubmit() {
    this.submitted = true;
    return this.cmspageService.contactForm(this.model).subscribe(
      data => this.model = data,
      error => this.error = error
    );
  }

 toRevolutionise(){
document.getElementById("Revolutionise").scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
}
}
