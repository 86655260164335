
<!--====================slider section====================-->
<section class="slider_section text-left all-use-case Services_banner" id="introduction">
    <div class="container">
        <div class="slider_innertext ">
            <h2 data-aos="fade-up">Services</h2>
            <p data-aos="fade-up">IoT is enabling businesses across the world the access to data that matters to them. Senstra IoT Solutions aims to help you leverage the true potential of IoT with services that cater your specific business needs. We provide business specific technology roadmaps, IoT consultancy, and customised business solutions in fields of IoT, Data Analytics and Cloud.</p>
            <div class="text-left" data-aos="fade-up">
              <button type="button" class="btn btn-primary" [routerLink]="'/contact-us'" >Drop a query</button>
        <button type="button" class="btn btn-secoundry" (click)="openDialog()">Request a demo</button>
            </div>
        </div>
    </div>
    <div class="moving-mouse-holder" >
    <a (click)="toRevolutionise()">
  <div class="mouse">
    <div class="mouse-button">&nbsp;</div>
  </div>
  <div class="text">Scroll To Explore</div>
</a>
</div>
</section>
<!--====================slider section end====================-->
<div class="clearfix"></div>
<section class="about_menu" id="mainNav">
    <div class="container">
        <div class="nav_center">
            <ul class="nav bottom_nav" >
                <li class="nav-item"><a (click)="toStrategy()"  class="js-scroll-trigger">Strategy & Design</a></li>
                <li class="nav-item"><a class="js-scroll-trigger" (click)="toResearch()"> IoT Product Development </a></li>
                <li class="nav-item"><a class="js-scroll-trigger" (click)="toCloud()"> Cloud Deployment</a></li>
                <li class="nav-item"><a class="js-scroll-trigger" (click)="toDataAnalytics()"> Data Analytics & Science</a></li>
            </ul>
        </div>
    </div>
</section>
<div id="Revolutionise">
<section class="Revolutionise-your-business text-center">
    <div class="container">
        <h3 class="blue-dark" data-aos="fade-up">Capture the true potential of IoT with Senstra IoT Solutions</h3>
        <p data-aos="fade-up">We are a team of qualified professionals with 50+ years of collective experience in providing data insights and data handling in multiple domains. Our services provide data analytics, tools and capability to help you achieve your IoT goals. We want to help you improve your results, no matter the size of your organisation.</p>
    </div>
</section>
</div>
<div class="clearfix"></div>
<section class="Use-Cases">
    <div class="container">
       
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                 <h1 class="gray_color" data-aos="fade-left">Services</h1>
                 <div  id="Strategy">
            <div class="row use-case-top_div m-t-0" data-aos="fade-up">
                <div class="col-md-6 col-sm-12 col-12">
                    <div class="use-case-left">
                        <h3 class="blue-dark"> Strategy & Design</h3>
                         <img src="assets/img/Strategy & Design.jpg" alt="Strategy & Design">
                        <p>We work with you to create a technology roadmap that suits your business and design the IoT solution that helps you achieve the strategy. We are data professionals and we focus the strategy and design of the solution that is driven by data and actionable insights.</p>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12 col-12">
                    <div class="use-case-right">
                        <img src="assets/img/Strategy & Design.jpg" alt="Strategy & Design">
                    </div>
                </div>
            </div>
               </div>
                <div  id="Research">
            <div class="row use-case-top_div" data-aos="fade-up">
                <div class="col-md-6 col-sm-12 col-12">
                    <div class="use-case-left">
                        <h3 class="blue-dark"> IoT Product Development</h3>
                         <img src="assets/img/Research & Solution.jpg" alt="Research & Solution">
                         
                         <p>We research and develop IoT solutions that are robust in the field, secure in the cloud, and scalable for the future. Upon agreeing with you on the strategy, we can help you and your team with identifying, developing, and deploying the IoT hardware solution that meets your needs.</p>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12 col-12">
                    <div class="use-case-right">
                        <img src="assets/img/Research & Solution.jpg" alt="Research & Solution">
                    </div>
                </div>
            </div>
        </div>
        <div id="Cloud">
            <div class="row use-case-top_div"  data-aos="fade-up">
                <div class="col-md-6 col-sm-12 col-12">
                    <div class="use-case-left">
                        <h3 class="blue-dark">Cloud Deployment</h3>
                          <img src="assets/img/Cloud__Deployment.jpg" alt="Cloud__Deployment">
                        <p>We create and manage the solution whether it be on premise or in the cloud (AWS/Azure). Building a custom, secure and robust way for your organization to ingest the data. Not only the deployment is agile and can be scaled to multiple sites but also allow the real time alerts via SMS and emails.</p>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12 col-12">
                    <div class="use-case-right">
                        <img src="assets/img/Cloud__Deployment.jpg" alt="Cloud__Deployment">
                    </div>
                </div>
            </div>
        </div>
        <div id="DataAnalytics">
            <div class="row use-case-top_div"  data-aos="fade-up">
                <div class="col-md-6 col-sm-12 col-12">
                    <div class="use-case-left">
                        <h3 class="blue-dark">Data Analytics & Science</h3>
                          <img src="assets/img/Data_Analytics_Science.jpg" alt="Data_Analytics_Science">
                        <p>We help you ingest, analyse and report on the telemetry data collected. Providing insightful analysis using predictive intelligence and data science to drive business and ensure quick ROI. Our AI experts can help you with different business problems that are driven by advanced analytics practice.</p>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12 col-12">
                    <div class="use-case-right">
                        <img src="assets/img/Data_Analytics_Science.jpg" alt="Data_Analytics_Science">
                    </div>
                </div>
            </div>
             </div>
            </div>
             <div class="col-md-1"></div>
        </div>
    </div>
</section>
