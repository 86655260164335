<div class="mat-dialog-content_new" mat-dialog-content>
  <div class="modal-dialog modal-lg team_modal" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" (click)="onNoClick()">
          <span aria-hidden="true"><img src="assets/img/cancel.svg"></span>
        </button>
        <div class="row modal_bg_left">
          <div class="col-md-5 col-sm-12 col-12">
            <div class="team_left">
              <img src="assets/img/Andrew-Peddar.png">
              <h4 class="blue-dark">Andrew Peddar</h4>
              <p>Chief of Growth</p>
            </div>
          </div>
          <div class="col-md-7 col-sm-7 col-12">
            <div class="team_right">
              <p>Andrew is a global fintech executive with 21 years of experience in constantly delivering high value solutions to the world’s largest financial institutions, while growing top-line revenue and profits.
              </p>
              <p>
                This was achieved by being the operational and executive glue at Board and Senior Management level, by ensuring all facets; Client, Legal, Marketing, Sales, Delivery, Service and Product collaborate, collectively solve problems and align for success.
              </p>
              <p>
                He is someone who is very comfortable in owning all aspects of revenue growth, retention and longevity, while at the same time ensuring costs and sales strategies are kept in line with the overall vision of the company.
              </p>
              <p>
                Andrew is a  big believer in focusing on “why”, encouraging others to ask the right questions, helping to determine what the right noise is to listen to, strives for simplicity and someone who constantly wants to learn.
              </p>

              <a href="https://www.linkedin.com/in/andrewpeddar/" target="_blank" class="blue-light">Linkedin <img src="assets/img/left-arrow.svg"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
