import { Component, OnInit } from '@angular/core';
import { Contact } from '../../../classes/contact';
import { ContactService } from '../../../services/contact.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  model = new Contact();
  submitted = false;
  error: {};
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{9,10}$";

  constructor(
    private cmspageService: ContactService
    ) { }

  ngOnInit() {
  }

  onSubmit() {
    this.submitted = true;
    return this.cmspageService.contactForm(this.model).subscribe(
      data => this.model = data,
      error => this.error = error
    );
  }
  tocontactusoffset(){
document.getElementById("contactusoffset").scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});


}



}
