<div class="mat-dialog-content_new" mat-dialog-content>
  <div class="modal-dialog modal-lg team_modal" role="document">
    <div class="modal-content">
    	<div class="modal-body">
	        <button type="button" class="close" (click)="onNoClick()">
	          <span aria-hidden="true"><img src="assets/img/cancel.svg"></span>
	        </button>
                <div class="row modal_bg_left">
                  <div class="col-md-5 col-sm-12 col-12">
                    <div class="team_left">
                      <img src="assets/img/Kenneth Lam.png">
                      <h4 class="blue-dark">Kenneth Lam</h4>
                      <p>Product and Analytics</p>
                    </div>
                  </div>
                  <div class="col-md-7 col-sm-7 col-12">
                    <div class="team_right">
                      <p class="normal_p">Ken co-founded Senstra in March 2019 alongside Chintan and Hem. Ken brings with him a wealth of knowledge in business analytics, data science and IoT. Ken has over 10 years’ experience delivering projects across a wide range of industries such as Telecoms, FMCG, Asset Management, Transport, Oil & Gas and Banking. Ken was instrumental in building analytics that identified US $1.1 billion in non-essential spares allowing a client to free up working capital.
                      </p>
                      <p class="normal_p">At Senstra, Ken is an innovation leader driving the technical vision to achieve Senstra’s mission in providing an end-to-end IoT solution, and is passionate about enabling companies to use IoT to enrich their data and drive insightful analytics. He focuses on delivering quality and making the company an exceptional place for engineers, developers and researchers to work and learn.</p>
                      <a href="https://www.linkedin.com/in/kennethklam/" target="_blank" class="blue-light">Linkedin <img src="assets/img/left-arrow.svg"></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>