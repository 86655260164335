<div class="mat-dialog-content_new" mat-dialog-content>
  <div class="modal-dialog modal-lg team_modal" role="document">
    <div class="modal-content">
    	<div class="modal-body">
	        <button type="button" class="close" (click)="onNoClick()">
	          <span aria-hidden="true"><img src="assets/img/cancel.svg"></span>
	        </button>
                <div class="row modal_bg_left">
                  <div class="col-md-5 col-sm-12 col-12">
                    <div class="team_left">
                      <img src="assets/img/Neha Bains.png">
                      <h4 class="blue-dark">Neha Bains</h4>
                      <p>Chief Marketing Officer</p>
                    </div>
                  </div>
                  <div class="col-md-7 col-sm-7 col-12">
                    <div class="team_right">
                      <p>Neha is a senior business and marketing leader focused on growth through innovation. With double masters in business marketing, operations management and extensive industry experience, Neha specializes in strategic demand generation with a proven track record of delivering return on investment. Her analytical approach with practical experience helps build teams from the ground up in B2B environments. Neha brings diverse work experience within SaaS and software solutions, Corporations, SMB and Startup space.
                      </p>
                      <p class="normal_p">At Senstra, Neha looks after:</p>
                      <ul>
                        <li>Building partnerships and relationships.</li>
                        <li>Develop innovative marketing strategy.</li>
                        <li>Planning, implementing, and monitoring the overall marketing strategy. </li>
                        <li>Establishing and expanding brand presence.</li>
                        <li> Product enhancement and go-to-market launch.</li>
                        <li> Market Research and Analysis for business growth.</li>
                      </ul>
                      <a href="https://www.linkedin.com/in/nehabains/" target="_blank" class="blue-light">Linkedin <img src="assets/img/left-arrow.svg"></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>