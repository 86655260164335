<div class="mat-dialog-content_new" mat-dialog-content>
  <div class="modal-dialog modal-lg team_modal" role="document">
    <div class="modal-content">
    	<div class="modal-body">
	        <button type="button" class="close" (click)="onNoClick()">
	          <span aria-hidden="true"><img src="assets/img/cancel.svg"></span>
	        </button>
                <div class="row modal_bg_left">
                  <div class="col-md-5 col-sm-12 col-12">
                    <div class="team_left">
                      <img src="assets/img/Nicola Dorling.jpg">
                      <h4 class="blue-dark">Nicola Dorling</h4>
                      <p>Director of the Board</p>
                    </div>
                  </div>
                  <div class="col-md-7 col-sm-7 col-12">
                    <div class="team_right">
                      <p>Nicola has joined Senstra as the Director of the Board and is responsible for governing it and contributing to the success of the clients’ long term vision in adopting cutting edge technology. Nicola holds valuable experience and has led a number of roles that drive strategic change and transformation, right from strategy development to execution. With her experience in digital transformation, financial acumen, and large-scale systems, Nicola continues to help companies such as Google, Deloitte, UNSW, Sydney Transport, Cardno Engineering, and more.</p>
                     
                      <a href="https://www.linkedin.com/in/nicola-dorling-19a76934/" target="_blank" class="blue-light">Linkedin <img src="assets/img/left-arrow.svg"></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>