import { Component, OnInit } from '@angular/core';
import { CheckCompatibility } from '../../../classes/check-compatibility';
import { CheckCompatibilityService } from '../../../services/check-compatibility.service';

@Component({
  selector: 'app-check-compatibility',
  templateUrl: './check-compatibility.component.html',
  styleUrls: ['./check-compatibility.component.css']
})
export class CheckCompatibilityComponent implements OnInit {

  model = new CheckCompatibility();
  submitted = false;
  error: {};

  show_div_1: boolean = false;
  show_div_2: boolean = true;
  show_div_3: boolean = true;
  show_div_4: boolean = true;
  show_div_5: boolean = true;

  constructor(private checkCompatibilityService: CheckCompatibilityService) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.submitted = true;
    return this.checkCompatibilityService.contactForm(this.model).subscribe(
      data => this.model = data,
      error => this.error = error
    );
  }

  goto2nd() {
    this.show_div_1 = true;
    this.show_div_2 = false;
    this.show_div_3 = true;
    this.show_div_4 = true;
    this.show_div_5 = true;

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  goto3rd() {
    this.show_div_1 = true;
    this.show_div_2 = true;
    this.show_div_3 = false;
    this.show_div_4 = true;
    this.show_div_5 = true;

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  goto4th() {
    this.show_div_1 = true;
    this.show_div_2 = true;
    this.show_div_3 = true;
    this.show_div_4 = false;
    this.show_div_5 = true;

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  goto5th() {
    this.show_div_1 = true;
    this.show_div_2 = true;
    this.show_div_3 = true;
    this.show_div_4 = true;
    this.show_div_5 = false;

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  startOver() {
    this.show_div_1 = false;
    this.show_div_2 = true;
    this.show_div_3 = true;
    this.show_div_4 = true;
    this.show_div_5 = true;

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

}
