<!--====================  header area ====================-->
<div class="header-area header-area--default">
    <!-- Header Bottom Wrap Start -->
    <div class="header-bottom-wrap header-sticky">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="header default-menu-style position-relative">
                        <!-- brand logo -->
                        <div class="header__logo">
                            <a [routerLink]="'/'"> 
                                <img src="assets/img/Logo.svg" class="img-fluid" alt="">
                            </a>
                        </div>
                        <!-- header midle box  -->
                        <div class="header-midle-box">
                            <div class="header-bottom-wrap d-md-block d-none">
                                <div class="header-bottom-inner">
                                    <div class="header-bottom-left-wrap">
                                        <!-- navigation menu -->
                                        <div class="header__navigation d-none d-xl-block">
                                            <nav class="navigation-menu primary--menu">
                                                <!-- <ul>
                                                    <li class="has-children has-children--multilevel-submenu">
                                                        <a [routerLink]="'/all-solutions'" (mouseover)="solutions_menu_hover = false"><span>What We Do <i class="fa fa-caret-down" aria-hidden="true"></i></span></a>
                                                        <ul class="megamenu megamenu--mega" [class.d-none]="solutions_menu_hover==true">
                                                            <li>
                                                                <ul>
                                                                    <li class="first_head"><a [routerLink]="'/all-solutions'" (click)="solutions_menu_hover = true"><span>By Industry</span></a></li>
                                                                    <li><a [routerLink]="'/telco-infrastructure'" (click)="solutions_menu_hover = true"><span> Telco & Infrastructure</span></a></li>
                                                                    <li><a [routerLink]="'/utilities-power'" (click)="solutions_menu_hover = true"><span> Utilities (Power)</span></a></li>
                                                                    <li><a [routerLink]="'/smart-cities'" (click)="solutions_menu_hover = true"><span> Smart Cities</span></a></li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a [routerLink]="'/epitome'">Product</a>
                                                    </li>
                                                    <li>
                                                        <a [routerLink]="'/services'">Services</a>
                                                    </li>
                                                    <li>
                                                        <a [routerLink]="'/platform'">Platform</a>
                                                    </li>
                                                    <li>
                                                        <a [routerLink]="'/why-senstra'">Why Senstra</a>
                                                    </li>
                                                    <li>
                                                        <a [routerLink]="'/resources'">Resources</a>
                                                    </li>
                                                    <li class="has-children has-children--multilevel-submenu">
                                                        <a [routerLink]="'/about'" (mouseover)="about_menu_hover = false"><span>About Us <i class="fa fa-caret-down" aria-hidden="true"></i></span></a>
                                                        <ul class="submenu" [class.d-none]="about_menu_hover==true">
                                                            <li><a [routerLink]="'about/'" fragment="team" (click)="about_menu_hover = true"><span>Team</span></a></li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a [routerLink]="'/contact-us'">Contact Us</a>
                                                    </li>
                                                </ul> -->

                                                <ul class="top-level-menu">
                                                    <li>
                                                        <a href="javascript:void(0)">What We Do &nbsp; <i aria-hidden="true" class="fa fa-caret-down"></i></a>
                                                        <ul class="second-level-menu">
                                                            <li>
                                                                <a [routerLink]="'/epitome'">
                                                                    <img src="assets/img/iot_solutions.png" width="25px" />
                                                                    IoT Solutions
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a [routerLink]="'/web3'">
                                                                    <img src="assets/img/web_3.png" width="25px" />
                                                                    Web3
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a [routerLink]="'/services'">
                                                                    <img src="assets/img/professional.png" width="25px" />
                                                                    Professional Services
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a [routerLink]="'/platform'">
                                                                    <img src="assets/img/platforms.png" width="25px" />
                                                                    Platform
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="javascript:void(0)">
                                                                    <img src="assets/img/by_industry.png" width="25px" />
                                                                    By Industry &nbsp; <i aria-hidden="true" class="fa fa-caret-right"></i>
                                                                </a>
                                                                <ul class="third-level-menu">
                                                                    <li>
                                                                        <a [routerLink]="'/telco-infrastructure'">
                                                                            <img src="assets/img/telecomm.png" width="25px" />
                                                                            Telecommunications
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a [routerLink]="'/utilities-power'">
                                                                            <img src="assets/img/power.png" width="25px" />
                                                                            Power
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a [routerLink]="'/smart-cities'">
                                                                            <img src="assets/img/smart_city.png" width="25px" />
                                                                            Smart Cities
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a href="javascript:void(0)">Who We Are &nbsp; <i aria-hidden="true" class="fa fa-caret-down"></i></a>
                                                        <ul class="second-level-menu">
                                                            <li>
                                                                <a [routerLink]="'/why-senstra'">
                                                                    <img src="assets/img/why_senstra.png" width="25px" />
                                                                    Why Senstra
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a [routerLink]="'/about'" fragment="team">
                                                                    <img src="assets/img/our_team.png" width="25px" />
                                                                    Our Team
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li><a [routerLink]="'/resources'">Insights</a></li>
                                                    <li><a [routerLink]="'/contact-us'">Get In Touch</a></li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- header right box -->
                        <div class="header-right-box">
                            <div class="header-right-inner" id="hidden-icon-wrapper" >
                                <a (click)="openDialog()">Request a demo</a>
                            </div>
                            
                            <!-- hidden icons menu -->
                            <div class="hidden-icons-menu d-block d-md-none" id="hidden-icon-trigger">
                                <a (click)="openDialog()">Request a demo</a>
                            </div>
                            <!-- mobile menu -->
                            <div class="mobile-navigation-icon d-block d-xl-none" id="mobile-menu-trigger">
                                <i></i>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Header Bottom Wrap End -->
</div>
<!--====================  header end ====================-->
<!--====================  mobile menu overlay ====================-->
<div class="mobile-menu-overlay" id="mobile-menu-overlay">
    <div class="mobile-menu-overlay__inner">
        <div class="mobile-menu-overlay__header">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-md-6 col-8">
                        <div class="logo">
                            <a [routerLink]="'/'">
                                <img src="assets/img/logo.png" class="img-fluid" alt="">
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6 col-4">
                        <div class="mobile-menu-content text-right">
                            <span class="mobile-navigation-close-icon" id="mobile-menu-close-trigger"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mobile-menu-overlay__body">
            <nav class="offcanvas-navigation">
                <ul>
                    <li class="has-children">
                        <a href="javascript:void(0)">What We Do</a>
                        <ul class="sub-menu">
                            <li class="has-children">
                                <a href="javascript:void(0)"><img src="assets/img/by_industry.png" width="25px" /> &nbsp; <span>By Industry</span></a>
                                <ul class="sub-menu">
                                    <li><a [routerLink]="'/telco-infrastructure'"><img src="assets/img/telecomm.png" width="25px" /> &nbsp; <span>Telecommunications</span></a></li>
                                    <li><a [routerLink]="'/utilities-power'"><img src="assets/img/power.png" width="25px" /> &nbsp; <span>Power</span></a></li>
                                    <li><a [routerLink]="'/smart-cities'"><img src="assets/img/smart_city.png" width="25px" /> &nbsp; <span>Smart Cities</span></a></li>
                                </ul>
                                <a [routerLink]="'/epitome'"><img src="assets/img/iot_solutions.png" width="25px" /> &nbsp; <span>IoT Solutions</span></a>
                                <a [routerLink]="'/web3'"><img src="assets/img/web_3.png" width="25px" /> &nbsp; <span>Web3</span></a>
                                <a [routerLink]="'/services'"><img src="assets/img/professional.png" width="25px" /> &nbsp; <span>Professional Services</span></a>
                                <a [routerLink]="'/platform'"><img src="assets/img/platforms.png" width="25px" /> &nbsp; <span>Platform</span></a>
                            </li>
                        </ul>
                    </li>
                    <li class="has-children">
                        <a href="javascript:void(0)">Who We Are</a>
                        <ul class="sub-menu">
                            <li class="has-children">
                                <a [routerLink]="'/why-senstra'"><img src="assets/img/why_senstra.png" width="25px" /> &nbsp; <span>Why Senstra</span></a>
                                <a [routerLink]="'/about'" fragment="team"><img src="assets/img/our_team.png" width="25px" /> &nbsp; <span>Our Team</span></a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a [routerLink]="'/resources'">Insights</a>
                    </li>
                    <li>
                        <a [routerLink]="'/contact-us'">Get In Touch</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>
<!--====================  End of mobile menu overlay  ====================-->
<router-outlet></router-outlet>