
<!--====================slider section====================-->
<section class="slider_section text-left epitom_topbanner Sit_Sensor">
    <div class="container">
        <div class="slider_innertext ">
           
            <h1 class="gray_color">SIT Sensor</h1>
            
        </div>
    </div>
</section>
<!--====================slider section end====================-->
<div class="clearfix"></div>   
<section class="about_menu" data-aos="fade-up">
    <div class="container">
        <div class="nav_center">
            <ul class="nav bottom_nav" id="mainNav">
                <li class="nav-item"><a  (click)="toVideo()" class="js-scroll-trigger active">Benefits</a></li>
                <li class="nav-item"><a class="js-scroll-trigger" (click)="tofeatures()"> Features & Technology</a></li>
                <li class="nav-item"><a class="js-scroll-trigger" (click)="tousecase()"> Use Cases</a></li>
                <li class="nav-item"><a class="js-scroll-trigger" (click)="toHowitwork()">How it Works</a></li>
                <li class="nav-item"><a class="js-scroll-trigger" (click)="toTechSpecs()"> Tech Specs</a></li>
               
                <li class="nav-item"><a class="js-scroll-trigger" (click)="toDemo()">Demo</a></li>
            </ul>
        </div>
    </div>
</section>

<section class="Revolutionise-your-business text-center" data-aos="fade-up">
    <div class="container">
        <h3 class="blue-dark e_head" data-aos="fade-up">Shipment in Transit Sensor</h3>
        <p data-aos="fade-up">Global shipment tracking solution which is low cost, power efficient and outfitted with the<br> sensors you need.</p>
    </div>
</section>
<div class="clearfix"></div>
<section class="ePITome_bottom sit_sensor-bottom">
    <div class="container">
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10 col-sm-12 col-12">
                <div class="row">
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="ePITome_left_box" data-aos="fade-up">
                               
                            <p class="blue-dark font-1_8">Our device provides real-time data insights and intelligence to help your business:</p>
                            <ul>
                                <li>Optimise your supply chain</li>
                                <li>Reduce costs</li>
                                <li>Increase revenue</li>
                                <li> Enhance compliance</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="ePITome_right_box sit-sensor-right" data-aos="fade-up">
                            <p>We provide a complete solution. The device is pre-configured with an active global roaming SIM and our data ingestion platform.</p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</section>
<!--====================Benefits====================-->
<section class="Benefits_epitome" id="Video">
    <div class="container">
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <div class="row Our-usps-box">
                    <h1 class="gray_color" data-aos="fade-right">Benefits</h1>
                    <div class="col-md-3 col-12" data-aos="fade-up">
                        <div class="Benefits_epitome_box">
                            <img src="assets/img/Quick Deployment.svg" alt="Quick-Deployment">
                            <h4>Quick Deployment</h4>
                        </div>
                    </div>
                    <div class="col-md-3 col-12" data-aos="fade-up">
                        <div class="Benefits_epitome_box">
                            <img src="assets/img/Free_support.svg" alt="Free-Support">
                            <h4>Free Support</h4>
                        </div>
                    </div>
                    <div class="col-md-3 col-12" data-aos="fade-up">
                        <div class="Benefits_epitome_box">
                            <img src="assets/img/Cost.svg" alt="Cost-Friendly">
                            <h4>Cost Friendly</h4>
                        </div>
                    </div>
                    <div class="col-md-3 col-12" data-aos="fade-up">
                        <div class="Benefits_epitome_box">
                            <img src="assets/img/Customisable.svg" alt="Customisable">
                            <h4>Customisable</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
    </div>
</section>

<div class="clearfix"></div>
<section class="ePITome_features" id="features">
    <div class="container">
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10 col-sm-12 col-12">
                <div class="row">
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="ePITome_features_left" data-aos="fade-up">
                            <h2 class="blue-dark"> Features</h2>
                            <ul>
                                <li>Battery Life</li>
                                <li> Communication Protocol</li>
                                <li>3 Layer Encryption</li>
                                <li> AWS/Azure Support and Dashboard </li>
                                <li>Real-time notifications</li>
                                <li>Durability</li>
                            </ul>
                        </div>
                    </div>
                     
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="ePITome_features_right" data-aos="fade-up">
                            <h2 class="blue-dark">Technology</h2>
                            <ul>
                                <li> Small size, so it can easily be placed within the shipment </li>
                                <li> Designed for worldwide LTE and/or 2G coverage </li>
                                <li>Provides accurate and uninterrupted positioning</li>
                                <li> Device transmission and battery that meets flight regulations</li>
                                <li>Our device meets the RF requirements of North American and European certification schemes</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="clearfix"></div>
<section class="Use-Cases Epitome_use-case" id="usecase">
    <div class="container">
        <div class="row">
            <div class="col-md-1 col-12"></div>
            <div class="col-md-10 col-sm-12 col-12">
                <h1 class="gray_color" data-aos="fade-left">Use Cases</h1>
                <div class="row use-case-top_div m-t-0" id="Temperature" data-aos="fade-up">
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-left">
                            <h3 class="blue-dark"> Temperature and Humidity Sensing</h3>
                            <p>Our nodes can provide multi-point temperature and humidity readings</p>
                            <ul>
                                <li>Industry standard Temperature and Humidity sensor reducing the risk of critical assets going down and ensuring business continuity</li>
                                <li> Multiple temperature sensors can connected per device to provide a range of readings</li>
                                <li> Data is captured and ingested at the frequency that matters to you</li>
                                <li> Email, SMS and system alerts when thresholds are crossed to reduce the risk of damage to what matters to you.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-right">
                            <img src="assets/img/Temperature-and-Humidity-Sensing.jpg" alt="Temperature-and-Humidity-Sensing">
                        </div>
                    </div>
                </div>
                <div class="row use-case-top_div" id="Pressure" data-aos="fade-up">
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-left">
                            <h3 class="blue-dark"> Realtime Water Level Indication</h3>
                            <p>Ultrasonic sensors to detect water levels and alert our clients of potential flooding or water ingress
                            </p>assets/
                            <ul>
                                <li>Industry standard Temperature and Humidity sensor reducing the risk of critical assets going down and ensuring business continuity</li>
                                <li> Automatically be informed of flooding or water ingress into areas where sensitive equipment sits</li>
                                <li> Ultrasonic sensor capable of real-time readings to the centimetre</li>
                                <li>Data is captured when it matters to you</li>
                                <li>Email, SMS and system alerts when thresholds are crossed</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-right">
                            <img src="assets/img/Realtime-Water-Level-Indication.jpg" alt="Realtime-Water-Level-Indication">
                        </div>
                    </div>
                </div>
                <div class="row use-case-top_div" id="Intrusion-detection" data-aos="fade-up">
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-left">
                            <h3 class="blue-dark">Intrusion detection</h3>
                            <p>Detect intrusion into restricted areas and protect critical assets</p>
                            <ul>
                                <li>Real-Time intrusion detection allowing you to act instantly and protect critical assets</li>
                                <li>Device will wake and provide real time alerts when an intrusion is detected</li>
                                <li>Real time alerts can be customised for the channel that suits you whether it be email, sms or system alerts</li>
                                <li> Rest-API access for further data analysis</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-right">
                            <img src="assets/img/Intrusion-detection.jpg" alt="Intrusion-detection">
                        </div>
                    </div>
                </div>
                <div class="row use-case-top_div" id="Light" data-aos="fade-up">
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-left">
                            <h3 class="blue-dark">Asset access audit and Technician assistance</h3>
                            <p>Our Android/IOS app alongside our IoT products and cloud solution offerings allows technicians in-field to see realtime readings and record the work they've conducted and allow you to track your technicians time and progress.</p>
                            <ul>
                                <li>Custom built Android/IOS Application suitable for businesses and their workforce</li>
                                <li>App displays historical and real time telemetry and work data for the user</li>
                                <li> Provides transparency to what technicians are doing in-field </li>
                                <li> Notifications generated based on technician's work</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-right">
                            <img src="assets/img/Asset-access-audit-and-Technician-assistance.jpg" alt="Asset-access-audit-and-Technician-assistance">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-1 col-12"></div>
        </div>
    </div>
</section>
<section class="How-it-works" data-aos="fade-up" id="Howitwork">
    <div class="container">
        <div class="row">
            <div class="col-md-1 col-12"></div>
            <div class="col-md-10 col-12">
                <div class="row">
                     <div class="col-md-6 col-sm-12 col-12">
                        <div class="How-it-works-left">
                            <h2 class="blue-dark">How it works</h2>
                            <h3>Readiness Challenge</h3>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-right How-it-works-right">
                            <button type="button" class="btn btn-primary" (click)="openDialog()">Request A Trial</button>
                            <button type="button" class="btn btn-secoundry" [routerLink]="'/check-compatibility'">Check Your Compatibility</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-1 col-12"></div>
        </div>
    </div>
</section>

<section class="ePITome_img" data-aos="fade-up" id="">
	<img src="assets/img/ePITome-banner.png" />
</section>

<section class="Tech_Specs" id="TechSpecs">
    <div class="container">
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10 col-sm-12 col-12">
                <h1 class="gray_color" data-aos="fade-right">Tech Specs</h1>
                <div class="tech-spac_box-shodw" data-aos="fade-up">
                    <div class="tech-specs_inner">
                        <div class="row">
                            <div class="col-md-6 col-12">
                                <div class="tech-specs_left">
                                    <h3>Cellular</h3>
                                    <ul>
                                        <li>
                                            <div class="left_3">
                                                <p class="blue-dark">Comms</p>
                                            </div>
                                            <div class="left_7">
                                                <p>LTE (Cat M1/NB1) and 2G</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="left_3">
                                                <p class="blue-dark">Bands</p>
                                            </div>
                                            <div class="left_7">
                                                <p>2, 3, 4, 5, 8, 12, 13, 20, 26, 28; EGPRS quad-band, 850/900/1800/1900 MHz</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="left_3">
                                                <p class="blue-dark">GNSS</p>
                                            </div>
                                            <div class="left_7">
                                                <p>GPS, GLONASS, BeiDou, QZSS, SBAS</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="left_3">
                                                <p class="blue-dark">Concurrent <br> GNSS</p>
                                            </div>
                                            <div class="left_7">
                                                <p>Yes - 3</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="left_3">
                                                <p class="blue-dark">Bluetooth</p>
                                            </div>
                                            <div class="left_7">
                                                <p>v5.0 (Bluetooth low energy)</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="left_3">
                                                <p class="blue-dark">BLE Mesh</p>
                                            </div>
                                            <div class="left_7">
                                                <p>Yes</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-6 col-12">
                                <div class="tech-specs_right">
                                    <h3>Sensors</h3>
                                    <ul>
                                        <li>
                                            <div class="left_3">
                                                <p class="blue-dark">Temperature</p>
                                            </div>
                                            <div class="left_7">
                                                <p>-55 to 125 °C (±0.5°C)</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="left_3">
                                                <p class="blue-dark">Accelerometer</p>
                                            </div>
                                            <div class="left_7">
                                                <p>3-Axis Capacitive MEMS</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="tech-specs_right">
                                    <h3>Power</h3>
                                    <ul>
                                        <li>
                                            <div class="left_3">
                                                <p class="blue-dark">Supply</p>
                                            </div>
                                            <div class="left_7 ">
                                                <p>Typical 12 V or 24 V extended to 9 V to 36 V</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="left_3">
                                                <p class="blue-dark">Battery</p>
                                            </div>
                                            <div class="left_7">
                                                <p>Li-Po battery with built-in charger</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tech-footer">
                        <button type="button" class="btn btn-primary" (click)="openDialog11()">Send it to your colleague</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--====================Work Managment end====================-->
<section class="contact_us" data-aos="fade-up" id="Demo">
    <div class="container">
        <div class="contact_us_inner">
            <h2 class="blue-dark">Contact us for personalised demo</h2>
            <button type="button" class="btn btn-primary allsolusion m-r_1_5" (click)="openDialog()">Request A Trial</button>
            <button type="button" class="btn btn-secoundry allsolusion m-r_1_5">Readiness Challenge</button>
             <button type="button" class="btn btn-primary allsolusion m-r_1_5" (click)="openDialog()">Request A Demo</button>
        </div>
    </div>
</section>
