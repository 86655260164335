import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-sanket',
  templateUrl: './sanket.component.html',
  styleUrls: ['./sanket.component.css']
})
export class SanketComponent implements OnInit {

    
 constructor( public dialogRef: MatDialogRef<SanketComponent>){}

  onNoClick(): void {
    this.dialogRef.close();
  }
  
  ngOnInit(): void {
  }

}
