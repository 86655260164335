

    <!--====================by industries====================-->
    <section class="all-solusion-by-industries">
        <div class="">
            <div class="row no-gutters reverse-div" id="solusionbyindustry">
                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="col-img" [class.d-none]="telco_img_hover==false" data-aos="fade-up">
                        <img src="assets/img/by-industry-Telco_banner.jpg" alt="by industries img">
                    </div>
                    <div class="col-img" [class.d-none]="intl_img_hover==false" data-aos="fade-up">
                        <img src="assets/img/by-industry-Traffic_banner.jpg" alt="by industries img">
                    </div>
                    <div class="col-img" [class.d-none]="supply_img_hover==false" data-aos="fade-up">
                        <img src="assets/img/by-industry-Supply_banner.jpg" alt="by industries img">
                    </div>
                    <div class="col-img" [class.d-none]="smart_img_hover==false" data-aos="fade-up">
                        <img src="assets/img/by-industry-Smart cities.jpg" alt="by industries img">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-12 ml-auto d-flex align-items-center mt-4 mt-md-0" data-aos="fade-up">
                    <div class="by-industries-right p-right">
                        <h2>By <br> Industry</h2>
                        <ul>
                        <li><a [routerLink]="'/telco-infrastructure'" (mouseover)="telco_img_hover = true; intl_img_hover = false; supply_img_hover = false; smart_img_hover = false;" class="heading_main">Telco & Infrastructure</a>
                        </li>
                        <li><a [routerLink]="'/utilities-power'" (mouseover)="telco_img_hover = false; intl_img_hover = false; supply_img_hover = true; smart_img_hover = false;" class="heading_main">Utilities (Power)</a>
                        
                        </li>
                        <li><a [routerLink]="'/smart-cities'" (mouseover)="telco_img_hover = false; intl_img_hover = false; supply_img_hover = false; smart_img_hover = true;" class="heading_main">Smart Cities</a>
                        </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

