<div class="mat-dialog-content_new" mat-dialog-content>
  <div class="modal-dialog modal-lg team_modal" role="document">
    <div class="modal-content">
    	<div class="modal-body">
	        <button type="button" class="close" (click)="onNoClick()">
	          <span aria-hidden="true"><img src="assets/img/cancel.svg"></span>
	        </button>
                <div class="row modal_bg_left">
                  <div class="col-md-5 col-sm-12 col-12">
                    <div class="team_left">
                      <img src="assets/img/Mike-Robinson.png">
                      <h4 class="blue-dark">Mike Robinson</h4>
                      <p>Director</p>
                    </div>
                  </div>
                  <div class="col-md-7 col-sm-7 col-12">
                    <div class="team_right">
                      <p>
                        Mike Robinson is a highly accomplished Commercial Director with global experience in managing marketing and sales teams in the pursuit of value-enhancing relationships for both small and large customers. Mike has experience across a number of sectors including Telecommunications, Aerospace and, Food and Technology. Having held senior Commercial roles for many years, Mike now operates as an Advisor to start-ups and large-scale enterprises that are striving for growth. He is known for driving a high-performance culture and is adept at understanding the nuances of different sectors and knowing how to be successful in each.
                      </p>
                      <a href="https://www.linkedin.com/in/mike-robinson-77226" target="_blank" class="blue-light">Linkedin
                        <img src="assets/img/left-arrow.svg">
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>