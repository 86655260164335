import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { SendToColleagues } from '../../../classes/send-to-colleagues';
import { SendToColleaguesService } from '../../../services/send-to-colleagues.service';

interface DialogData {
  email: string;
}

@Component({
  selector: 'app-yourcolleague',
  templateUrl: './yourcolleague.component.html',
  styleUrls: ['./yourcolleague.component.css']
})
export class YourcolleagueComponent implements OnInit {

  model = new SendToColleagues();
  submitted = false;
  error: {};

 constructor(
    private cmspageService: SendToColleaguesService,
    public dialogRef: MatDialogRef<YourcolleagueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.submitted = true;
    return this.cmspageService.contactForm(this.model).subscribe(
      data => this.model = data,
      error => this.error = error
    );
  }

  ngOnInit(): void {
  }

}
