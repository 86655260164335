     
<section class="slider_section text-left all-use-case web3" id="introduction" style="margin-bottom: 100px">
  <div class="container">
    <div class="slider_innertext" style="">
      <h2 data-aos="fade-up">Web3</h2>
      <p data-aos="fade-up" class="Intelligent-text_p" style="width: 100%">
        Connecting the physical world with Blockchain technology
      </p>
      <p data-aos="fade-up" class="Intelligent-text_p" style="width: 100%; visibility: hidden;">
        Connecting the physical world with Blockchain technology
      </p>
      <p data-aos="fade-up" class="Intelligent-text_p" style="width: 100%; visibility: hidden;">
        Connecting the physical world with Blockchain technology
      </p>
      <p data-aos="fade-up" class="Intelligent-text_p" style="width: 100%; visibility: hidden;">
        Connecting the physical world with Blockchain technology
      </p>
      <p data-aos="fade-up" class="Intelligent-text_p" style="width: 100%; visibility: hidden;">
        Connecting the physical world with Blockchain technology
      </p>
    </div>
  </div>
</section>