import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-kyle-pattison',
  templateUrl: './kyle-pattison.component.html',
  styleUrls: ['./kyle-pattison.component.css']
})
export class KylePattisonComponent implements OnInit {

   constructor( public dialogRef: MatDialogRef<KylePattisonComponent>){}

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit(): void {
  }

}
