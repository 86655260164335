import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-hayden',
  templateUrl: './hayden.component.html',
  styleUrls: ['./hayden.component.css']
})
export class HaydenComponent implements OnInit {

   
 constructor( public dialogRef: MatDialogRef<HaydenComponent>){}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
