<div class="mat-dialog-content_new" mat-dialog-content>
  <div class="modal-dialog modal-lg team_modal" role="document">
    <div class="modal-content">
    	<div class="modal-body">
	        <button type="button" class="close" (click)="onNoClick()">
	          <span aria-hidden="true"><img src="assets/img/cancel.svg"></span>
	        </button>
                <div class="row modal_bg_left">
                  <div class="col-md-5 col-sm-12 col-12">
                    <div class="team_left">
                      <img src="assets/img/Sanket Chalke.png">
                      <h4 class="blue-dark">Sanket Chalke</h4>
                      <p>Senior Firmware Engineer</p>
                    </div>
                  </div>
                  <div class="col-md-7 col-sm-7 col-12">
                    <div class="team_right">
                      <p>Sanket joined Senstra in July 2020. With masters in Electrical Engineering, he has comprehensive knowledge and experience in microcontrollers and peripherals. He oversees the full product development of embedded projects at Senstra.
                      </p>
                      <p class="normal_p">Responsibilities at Senstra include:</p>
                      <ul>
                        <li>Development of Firmware for IoT projects</li>
                        <li>Testing and validation of IoT products</li>
                        <li>R&D for future improvements and innovations</li>
                      </ul>
                      <a href="https://www.linkedin.com/in/sanket-chalke/" target="_blank" class="blue-light">Linkedin <img src="assets/img/left-arrow.svg"></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>