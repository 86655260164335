<div class="form_compatablelity">
  <div class="form_div">
    <div class="col-md-12 col-12" [hidden]="submitted">
      <form (ngSubmit)="onSubmit()" #checkCompForm="ngForm">
        <h3 class="modal-title" id="">Check Your Compatibility</h3>
        <div id="sf1" class="frm" [class.d-none]="show_div_1==true">
          <fieldset>
            <section class="wizard_1">
              <div class="row no-gutters">
                <div class="col-md-6 col-sm-6 col-12 blue_bg">
                  <div class="left_wizard">
                    <p class="bold_wizard">Telecommunication & Infrastructure</p>
                    <p class="light_wizard">Mobile operators in Australia spend approximately 1.5% of their annual revenues on managing outages caused by natural disasters.</p>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6 col-12 black_bg">
                  <div class="left_wizard">
                    <p class="bold_wizard">Intelligent Traffic and Transportation Management</p>
                    <p class="light_wizard">It is estimated that the cost of road congestion and public transport crowding could increase from $19 billion in 2016 to $39.6 billion in 2031 without continued infrastructure investment.</p>
                  </div>
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col-md-6 col-sm-6 col-12 black_bg">
                  <div class="left_wizard">
                    <p class="bold_wizard">Supply Chain and Logistics</p>
                    <p class="light_wizard">Australia throws away food worth $5.2 billion a year, including more than $1.1 billion worth of fruit and vegetables and $872.5 million worth of fresh meat and fish because of poor cold chain management.</p>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6 col-12 blue_bg">
                  <div class="left_wizard">
                    <p class="bold_wizard">Smart Cities</p>
                    <p class="light_wizard">Between 2002-03 and 2010-11, Australian governments spent 1.6% of the country's total infrastructure spending of about $450 million every year to restore and rebuild critical infrastructure damaged by natural disasters.</p>
                  </div>
                </div>
              </div>
              <div class="last_p">
                <p>IoT based asset monitoring solutions enable predictive maintenance of these high value assets and garner real-time actionable insights for timely decision-making.</p>
              </div>
              <div class="footer-button text-center">
                <button class="btn btn-secondary open1" (click)="goto2nd()" type="button">Get started </button>
              </div>
            </section>
          </fieldset>
        </div>
        <div id="sf2" class="frm" [class.d-none]="show_div_2==true">
          <fieldset>
            <section class="second_div">
              <div class="row">
                <div class="col-md-2">
                </div>
                <div class="col-md-4 col-12">
                  <div class="sf2_left">
                    <div class="form-group">
                      <label class="control-label">Select Your Industry</label>
                      <select class="form-control" name="industry" [(ngModel)]="model.industry" #industry="ngModel">
                        <option value="0" selected>Select Industry</option>
                        <option value="Telecommunication & Infrastructure">Telecommunication & Infrastructure</option>
                        <option value="Intelligent Traffic & Transportation Management">Intelligent Traffic & Transportation Management</option>
                        <option value="Supply Chain & Logistics">Supply Chain & Logistics</option>
                        <option value="Smart Cities">Smart Cities</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                  </div>
                  <div *ngIf="model.industry=='Others'" class="sf2_left">
                    <div class="form-group">
                        <input type="text" name="other_industry" id="other_industry" [(ngModel)]="model.other_industry" class="form-control" required #other_industry="ngModel">
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="sf2_left">
                    <div class="form-group">
                      <label class="control-label">Your Assets</label>
                      <p> Do you have assets that you need to know more about in order to take care of your business and team ?</p>
                      <div class="form-check-inline">
                        <label class="form-check-label" for="radio1">
                          <input type="radio" [(ngModel)]="model.assets" #assets="ngModel" class="form-check-input" id="radio1" name="assets" value="yes" checked>Yes
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label" for="radio2">
                          <input type="radio" [(ngModel)]="model.assets" #assets="ngModel" class="form-check-input" id="radio2" name="assets" value="no">No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div class="footer-button form-group">
              <button *ngIf="model.assets=='no'" class="btn btn-primary open2 next_btn" (click)="goto3rd()" type="button">Next </button>
              <button *ngIf="model.assets=='yes'" class="btn btn-primary open2 next_btn" (click)="goto4th()" type="button">Next </button>
            </div>
          </fieldset>
        </div>
        <div id="sf3" class="frm" [class.d-none]="show_div_3==true">
          <fieldset>
            <section class="second_div ml-auto d-flex align-items-center">
              <div class="sp3_text col-12">
                <p>It seems that you do not have an asset management requirement for now. If <br>you still want to connect with our specialised consultant,<br> please email us at <a href="mailto:iot@senstra.com.au" style="color: #0E65D1;">iot@senstra.com.au</a></p>
              </div>
            </section>
            <div class="footer-button form-group">
              <button class="btn btn-secondary back3 previvew_btn" (click)="startOver()" type="button">Start over</button>
            </div>
          </fieldset>
        </div>
        <div id="sf4" class="frm" [class.d-none]="show_div_4==true">
          <fieldset>
            <section>
              <div class="row">
                <div class="col-md-6 col-12">
                  <div class="sf2_left">
                    <div class="form-group margin_1">
                      <label class="control-label">Your Knowledge</label>
                      <p>How many assets do you have that are critical to your organisation?</p>
                      <select class="form-control" name="assests_range" [(ngModel)]="model.assets_range" #assets_range="ngModel" style="width: 200px;">
                        <option value="0">Select Range</option>
                        <option value="0-50">0-50</option>
                        <option value="50-100">50-100</option>
                        <option value="100-500">100-500</option>
                        <option value="500-1000">500-1000</option>
                        <option value="1000+">1000+</option>
                      </select>
                    </div>
                    <div class="form-group margin_1">
                      <p> Are you aware of the location, environment and condition that your assets are in currently?</p>
                      <div class="form-check-inline">
                        <label class="form-check-label" for="radio22">
                          <input type="radio" class="form-check-input" [(ngModel)]="model.assets_location" #assets_location="ngModel" id="radio22" name="assets_location" value="yes" checked>Yes
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label" for="radio33">
                          <input type="radio" (click)="goto5th()" class="form-check-input" [(ngModel)]="model.assets_location" #assets_location="ngModel" id="radio33" name="assets_location" value="no">No
                        </label>
                      </div>
                    </div>
                    <div class="form-group margin_1">
                      <p>Do you currently receive any visual dashboards to get real time data to evaluate the health of your assets?</p>
                      <div class="form-check-inline">
                        <label class="form-check-label" for="currently1">
                          <input type="radio" class="form-check-input" [(ngModel)]="model.assets_dashboard" #assets_dashboard="ngModel" id="currently1" name="assets_dashboard" value="yes" checked>Yes
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label" for="currently2">
                          <input type="radio" class="form-check-input" [(ngModel)]="model.assets_dashboard" #assets_dashboard="ngModel" id="currently2" name="assets_dashboard" value="no">No
                        </label>
                      </div>
                    </div>
                    <div class="form-group margin_1">
                      <p>Do you currently find it hard to track, analyse and share your assets in one single place?</p>
                      <div class="form-check-inline">
                        <label class="form-check-label" for="singleplace1">
                          <input type="radio" class="form-check-input" [(ngModel)]="model.assets_tracking" #assets_tracking="ngModel" id="singleplace1" name="assets_tracking" value="yes" checked>Yes
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label" for="singleplace2">
                          <input type="radio" class="form-check-input" [(ngModel)]="model.assets_tracking" #assets_tracking="ngModel" id="singleplace2" name="assets_tracking" value="no">No
                        </label>
                      </div>
                    </div>
                    <div class="form-group margin_1">
                      <p>Do you face asset theft and destruction due to external elements like the environment?</p>
                      <div class="form-check-inline">
                        <label class="form-check-label" for="environment1">
                          <input type="radio" class="form-check-input" [(ngModel)]="model.assets_theft" #assets_theft="ngModel" id="environment1" name="assets_theft" value="yes" checked>Yes
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label" for="environment2">
                          <input type="radio" class="form-check-input" [(ngModel)]="model.assets_theft" #assets_theft="ngModel" id="environment2" name="assets_theft" value="no">No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="sf2_left">
                    <div class="form-group ma_bottom">
                      <label class="control-label">Your Insights</label>
                      <p> Do you currently use data analysis and mining for proactive decision making about your assets?</p>
                      <div class="form-check-inline">
                        <label class="form-check-label" for="analysis22">
                          <input type="radio" class="form-check-input" [(ngModel)]="model.assets_analysis" #assets_analysis="ngModel" id="analysis1" name="assets_analysis" value="yes" checked>Yes
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label" for="analysis33">
                          <input type="radio" class="form-check-input" [(ngModel)]="model.assets_analysis" #assets_analysis="ngModel" id="analysis2" name="assets_analysis" value="no">No
                        </label>
                      </div>
                    </div>
                    <div class="form-group ma_bottom">
                      <label class="control-label">Your Decisions</label>
                      <p> Can you make decisions that are critical to your business if you knew more about the health of your assets?</p>
                      <div class="form-check-inline">
                        <label class="form-check-label" for="business22">
                          <input type="radio" class="form-check-input" [(ngModel)]="model.assets_decisions" #assets_decisions="ngModel" id="business1" name="assets_decisions" value="yes" checked>Yes
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label" for="analysis33">
                          <input type="radio" class="form-check-input" [(ngModel)]="model.assets_decisions" #assets_decisions="ngModel" id="business2" name="assets_decisions" value="no">No
                        </label>
                      </div>
                    </div>
                    <div class="form-group ma_bottom">
                      <label class="control-label">Your KPI </label>
                      <p>
                        Will your business KPI be better achieved, if you know that your assets are affected by uncontrollables than controllables?</p>
                      <div class="form-check-inline">
                        <label class="form-check-label" for="business22">
                          <input type="radio" class="form-check-input" [(ngModel)]="model.assets_kpi" #assets_kpi="ngModel" id="controllables1" name="assets_kpi" value="yes" checked>Yes
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label" for="analysis33">
                          <input type="radio" class="form-check-input" [(ngModel)]="model.assets_kpi" #assets_kpi="ngModel" id="controllables2" name="assets_kpi" value="no">No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div class="footer-button form-group">
              <button class="btn btn-secondary back4 previvew_btn" (click)="startOver()" type="button">Start over</button>
              <button class="btn btn-primary open4 next_btn" (click)="goto5th()" type="button">Next </button>
              <button *ngIf="model.assets==='no'" class="btn btn-primary open4 next_btn" (click)="goto3rd()" type="button">Back </button>
              <button *ngIf="model.assets==='yes'" class="btn btn-primary open4 next_btn" (click)="goto2nd()" type="button">Back </button>
              
            </div>
          </fieldset>
        </div>
        <div id="sf5" class="frm" [class.d-none]="show_div_5==true">
          <fieldset>
            <section class="second_div">
              <div class="row">
                <div class="col-md-1 col-12"></div>
                <div class="col-md-5 col-12">
                  <div class="sp5_innerleft">
                    <p>Based on your responses, we feel that we may be able to help you in your quest to know and manage your assets better. You are eligible for a complimentary IoT upgrade consultation with our expert</p>
                    <p>Enter your details to set up a 20 min complimentary consultation.</p>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="sp5_right">
                    <div class="row">
                        <div class="col-md-6 col-12">
                            <div class="form-group">
                                  <label>Name*</label>
                                  <input type="text" name="name" id="name" [(ngModel)]="model.name" class="form-control" required #name="ngModel">
                                  <div *ngIf="name.invalid && (name.dirty || name.touched)" class="error">
                                    <div *ngIf="name.errors.required">
                                      Name is required.
                                    </div>
                                  </div>
                            </div>
                        </div>
                       <div class="col-md-6 col-12">
                          <div class="form-group">
                            <label>Email*</label>
                            <input type="text" name="email" id="email" [(ngModel)]="model.email" class="form-control" required email #email="ngModel">
                            <div *ngIf="email.invalid && (email.dirty || email.touched)" class="error">
                              <div *ngIf="email.errors.required">Email is required.</div>
                              <div *ngIf="email.errors.email">Email must be a valid email address.</div>
                            </div>
                          </div>
                        </div>
                     
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <label>Job Title*</label>
                          <input type="text" name="job_title" id="job_title" [(ngModel)]="model.job_title" class="form-control" required #job_title="ngModel">
                          <div *ngIf="job_title.invalid && (job_title.dirty || job_title.touched)" class="error">
                            <div *ngIf="job_title.errors.required">Job Title is required.</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <label>Company Name*</label>
                          <input type="text" name="comp_name" id="comp_name" [(ngModel)]="model.comp_name" class="form-control" required #comp_name="ngModel">
                          <div *ngIf="comp_name.invalid && (comp_name.dirty || comp_name.touched)" class="error">
                            <div *ngIf="comp_name.errors.required">Company Name is required.</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="form-group">
                          <label>Country*</label>
                          <select name="country" id="country" [(ngModel)]="model.country" class="form-control" required #country="ngModel">
                            <option value="Afganistan">Afghanistan</option>
                            <option value="Albania">Albania</option>
                            <option value="Algeria">Algeria</option>
                            <option value="American Samoa">American Samoa</option>
                            <option value="Andorra">Andorra</option>
                            <option value="Angola">Angola</option>
                            <option value="Anguilla">Anguilla</option>
                            <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                            <option value="Argentina">Argentina</option>
                            <option value="Armenia">Armenia</option>
                            <option value="Aruba">Aruba</option>
                            <option value="Australia">Australia</option>
                            <option value="Austria">Austria</option>
                            <option value="Azerbaijan">Azerbaijan</option>
                            <option value="Bahamas">Bahamas</option>
                            <option value="Bahrain">Bahrain</option>
                            <option value="Bangladesh">Bangladesh</option>
                            <option value="Barbados">Barbados</option>
                            <option value="Belarus">Belarus</option>
                            <option value="Belgium">Belgium</option>
                            <option value="Belize">Belize</option>
                            <option value="Benin">Benin</option>
                            <option value="Bermuda">Bermuda</option>
                            <option value="Bhutan">Bhutan</option>
                            <option value="Bolivia">Bolivia</option>
                            <option value="Bonaire">Bonaire</option>
                            <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                            <option value="Botswana">Botswana</option>
                            <option value="Brazil">Brazil</option>
                            <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                            <option value="Brunei">Brunei</option>
                            <option value="Bulgaria">Bulgaria</option>
                            <option value="Burkina Faso">Burkina Faso</option>
                            <option value="Burundi">Burundi</option>
                            <option value="Cambodia">Cambodia</option>
                            <option value="Cameroon">Cameroon</option>
                            <option value="Canada">Canada</option>
                            <option value="Canary Islands">Canary Islands</option>
                            <option value="Cape Verde">Cape Verde</option>
                            <option value="Cayman Islands">Cayman Islands</option>
                            <option value="Central African Republic">Central African Republic</option>
                            <option value="Chad">Chad</option>
                            <option value="Channel Islands">Channel Islands</option>
                            <option value="Chile">Chile</option>
                            <option value="China">China</option>
                            <option value="Christmas Island">Christmas Island</option>
                            <option value="Cocos Island">Cocos Island</option>
                            <option value="Colombia">Colombia</option>
                            <option value="Comoros">Comoros</option>
                            <option value="Congo">Congo</option>
                            <option value="Cook Islands">Cook Islands</option>
                            <option value="Costa Rica">Costa Rica</option>
                            <option value="Cote DIvoire">Cote DIvoire</option>
                            <option value="Croatia">Croatia</option>
                            <option value="Cuba">Cuba</option>
                            <option value="Curaco">Curacao</option>
                            <option value="Cyprus">Cyprus</option>
                            <option value="Czech Republic">Czech Republic</option>
                            <option value="Denmark">Denmark</option>
                            <option value="Djibouti">Djibouti</option>
                            <option value="Dominica">Dominica</option>
                            <option value="Dominican Republic">Dominican Republic</option>
                            <option value="East Timor">East Timor</option>
                            <option value="Ecuador">Ecuador</option>
                            <option value="Egypt">Egypt</option>
                            <option value="El Salvador">El Salvador</option>
                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                            <option value="Eritrea">Eritrea</option>
                            <option value="Estonia">Estonia</option>
                            <option value="Ethiopia">Ethiopia</option>
                            <option value="Falkland Islands">Falkland Islands</option>
                            <option value="Faroe Islands">Faroe Islands</option>
                            <option value="Fiji">Fiji</option>
                            <option value="Finland">Finland</option>
                            <option value="France">France</option>
                            <option value="French Guiana">French Guiana</option>
                            <option value="French Polynesia">French Polynesia</option>
                            <option value="French Southern Ter">French Southern Ter</option>
                            <option value="Gabon">Gabon</option>
                            <option value="Gambia">Gambia</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Germany">Germany</option>
                            <option value="Ghana">Ghana</option>
                            <option value="Gibraltar">Gibraltar</option>
                            <option value="Great Britain">Great Britain</option>
                            <option value="Greece">Greece</option>
                            <option value="Greenland">Greenland</option>
                            <option value="Grenada">Grenada</option>
                            <option value="Guadeloupe">Guadeloupe</option>
                            <option value="Guam">Guam</option>
                            <option value="Guatemala">Guatemala</option>
                            <option value="Guinea">Guinea</option>
                            <option value="Guyana">Guyana</option>
                            <option value="Haiti">Haiti</option>
                            <option value="Hawaii">Hawaii</option>
                            <option value="Honduras">Honduras</option>
                            <option value="Hong Kong">Hong Kong</option>
                            <option value="Hungary">Hungary</option>
                            <option value="Iceland">Iceland</option>
                            <option value="Indonesia">Indonesia</option>
                            <option value="India">India</option>
                            <option value="Iran">Iran</option>
                            <option value="Iraq">Iraq</option>
                            <option value="Ireland">Ireland</option>
                            <option value="Isle of Man">Isle of Man</option>
                            <option value="Israel">Israel</option>
                            <option value="Italy">Italy</option>
                            <option value="Jamaica">Jamaica</option>
                            <option value="Japan">Japan</option>
                            <option value="Jordan">Jordan</option>
                            <option value="Kazakhstan">Kazakhstan</option>
                            <option value="Kenya">Kenya</option>
                            <option value="Kiribati">Kiribati</option>
                            <option value="Korea North">Korea North</option>
                            <option value="Korea Sout">Korea South</option>
                            <option value="Kuwait">Kuwait</option>
                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                            <option value="Laos">Laos</option>
                            <option value="Latvia">Latvia</option>
                            <option value="Lebanon">Lebanon</option>
                            <option value="Lesotho">Lesotho</option>
                            <option value="Liberia">Liberia</option>
                            <option value="Libya">Libya</option>
                            <option value="Liechtenstein">Liechtenstein</option>
                            <option value="Lithuania">Lithuania</option>
                            <option value="Luxembourg">Luxembourg</option>
                            <option value="Macau">Macau</option>
                            <option value="Macedonia">Macedonia</option>
                            <option value="Madagascar">Madagascar</option>
                            <option value="Malaysia">Malaysia</option>
                            <option value="Malawi">Malawi</option>
                            <option value="Maldives">Maldives</option>
                            <option value="Mali">Mali</option>
                            <option value="Malta">Malta</option>
                            <option value="Marshall Islands">Marshall Islands</option>
                            <option value="Martinique">Martinique</option>
                            <option value="Mauritania">Mauritania</option>
                            <option value="Mauritius">Mauritius</option>
                            <option value="Mayotte">Mayotte</option>
                            <option value="Mexico">Mexico</option>
                            <option value="Midway Islands">Midway Islands</option>
                            <option value="Moldova">Moldova</option>
                            <option value="Monaco">Monaco</option>
                            <option value="Mongolia">Mongolia</option>
                            <option value="Montserrat">Montserrat</option>
                            <option value="Morocco">Morocco</option>
                            <option value="Mozambique">Mozambique</option>
                            <option value="Myanmar">Myanmar</option>
                            <option value="Nambia">Nambia</option>
                            <option value="Nauru">Nauru</option>
                            <option value="Nepal">Nepal</option>
                            <option value="Netherland Antilles">Netherland Antilles</option>
                            <option value="Netherlands">Netherlands (Holland, Europe)</option>
                            <option value="Nevis">Nevis</option>
                            <option value="New Caledonia">New Caledonia</option>
                            <option value="New Zealand">New Zealand</option>
                            <option value="Nicaragua">Nicaragua</option>
                            <option value="Niger">Niger</option>
                            <option value="Nigeria">Nigeria</option>
                            <option value="Niue">Niue</option>
                            <option value="Norfolk Island">Norfolk Island</option>
                            <option value="Norway">Norway</option>
                            <option value="Oman">Oman</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Palau Island">Palau Island</option>
                            <option value="Palestine">Palestine</option>
                            <option value="Panama">Panama</option>
                            <option value="Papua New Guinea">Papua New Guinea</option>
                            <option value="Paraguay">Paraguay</option>
                            <option value="Peru">Peru</option>
                            <option value="Phillipines">Philippines</option>
                            <option value="Pitcairn Island">Pitcairn Island</option>
                            <option value="Poland">Poland</option>
                            <option value="Portugal">Portugal</option>
                            <option value="Puerto Rico">Puerto Rico</option>
                            <option value="Qatar">Qatar</option>
                            <option value="Republic of Montenegro">Republic of Montenegro</option>
                            <option value="Republic of Serbia">Republic of Serbia</option>
                            <option value="Reunion">Reunion</option>
                            <option value="Romania">Romania</option>
                            <option value="Russia">Russia</option>
                            <option value="Rwanda">Rwanda</option>
                            <option value="St Barthelemy">St Barthelemy</option>
                            <option value="St Eustatius">St Eustatius</option>
                            <option value="St Helena">St Helena</option>
                            <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                            <option value="St Lucia">St Lucia</option>
                            <option value="St Maarten">St Maarten</option>
                            <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                            <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                            <option value="Saipan">Saipan</option>
                            <option value="Samoa">Samoa</option>
                            <option value="Samoa American">Samoa American</option>
                            <option value="San Marino">San Marino</option>
                            <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                            <option value="Saudi Arabia">Saudi Arabia</option>
                            <option value="Senegal">Senegal</option>
                            <option value="Seychelles">Seychelles</option>
                            <option value="Sierra Leone">Sierra Leone</option>
                            <option value="Singapore">Singapore</option>
                            <option value="Slovakia">Slovakia</option>
                            <option value="Slovenia">Slovenia</option>
                            <option value="Solomon Islands">Solomon Islands</option>
                            <option value="Somalia">Somalia</option>
                            <option value="South Africa">South Africa</option>
                            <option value="Spain">Spain</option>
                            <option value="Sri Lanka">Sri Lanka</option>
                            <option value="Sudan">Sudan</option>
                            <option value="Suriname">Suriname</option>
                            <option value="Swaziland">Swaziland</option>
                            <option value="Sweden">Sweden</option>
                            <option value="Switzerland">Switzerland</option>
                            <option value="Syria">Syria</option>
                            <option value="Tahiti">Tahiti</option>
                            <option value="Taiwan">Taiwan</option>
                            <option value="Tajikistan">Tajikistan</option>
                            <option value="Tanzania">Tanzania</option>
                            <option value="Thailand">Thailand</option>
                            <option value="Togo">Togo</option>
                            <option value="Tokelau">Tokelau</option>
                            <option value="Tonga">Tonga</option>
                            <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                            <option value="Tunisia">Tunisia</option>
                            <option value="Turkey">Turkey</option>
                            <option value="Turkmenistan">Turkmenistan</option>
                            <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                            <option value="Tuvalu">Tuvalu</option>
                            <option value="Uganda">Uganda</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="Ukraine">Ukraine</option>
                            <option value="United Arab Erimates">United Arab Emirates</option>
                            <option value="United States of America">United States of America</option>
                            <option value="Uraguay">Uruguay</option>
                            <option value="Uzbekistan">Uzbekistan</option>
                            <option value="Vanuatu">Vanuatu</option>
                            <option value="Vatican City State">Vatican City State</option>
                            <option value="Venezuela">Venezuela</option>
                            <option value="Vietnam">Vietnam</option>
                            <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                            <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                            <option value="Wake Island">Wake Island</option>
                            <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                            <option value="Yemen">Yemen</option>
                            <option value="Zaire">Zaire</option>
                            <option value="Zambia">Zambia</option>
                            <option value="Zimbabwe">Zimbabwe</option>
                          </select>
                          <div *ngIf="country.invalid && (country.dirty || country.touched)" class="error">
                            <div *ngIf="country.errors.required">Country is required.</div>
                          </div>
                        </div>
                      </div>
                      
                      <div class="col-12 submit_center">
                        <button [disabled]="!checkCompForm.form.valid" type="submit" class="btn btn-secondary SUbmit_btn">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div class="footer-button form-group">
              <button class="btn btn-primary open4 next_btn" (click)="goto4th()" type="button">Back </button>
              <button class="btn btn-secondary back5 previvew_btn" (click)="startOver()" type="button">Start over</button>
            </div>
          </fieldset>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="service-error" *ngIf="error">
  <h1>{{error.errorTitle}}</h1>
  <h3>{{error.errorDesc}}</h3>
</div>
<div [hidden]="!submitted" class="contact-message specialised_consultant">
  <div *ngIf="!error" class="contact-success">
    <h4>Thankyou, one of our specialised consultants will be in touch with you very soon</h4>
  </div>
</div>
