<!--====================slider section====================-->
<section class="slider_section text-left all-use-case about_us" id="introduction">
  <div class="container">
    <div class="slider_innertext ">
      <h2 data-aos="fade-up">About Us</h2>
      <p data-aos="fade-up" class="Intelligent-text_p">Senstra is an IoT company that provides the complete solution from deploying sensors for data collection to generating data analytics and insights. Our lean and agile business brings together a diverse team with engineering and data expertise to enable us to design bespoke IoT products and data solutions to match our clients’ needs. Keeping the design in-house allows us to work at a high velocity to turn-around a solution for a client while the issue is still relevant.</p>
      <div class="text-left" data-aos="fade-up">
        <button type="button" class="btn btn-primary" [routerLink]="'/contact-us'" fragment="contactus_offset">Drop a query</button>
        <button type="button" class="btn btn-secoundry" (click)="openDialog()">Request a demo</button>
      </div>
    </div>
  </div>
  <div class="moving-mouse-holder" >
    <a (click)="toaboutus()">
  <div class="mouse">
    <div class="mouse-button">&nbsp;</div>
  </div>
  <div class="text">Scroll To Explore</div>
</a>
</div>
</section>
<!--====================slider section end====================-->
<div class="clearfix"></div>
<section class="about_menu" id="mainNav">
  <div class="container">
    <div class="nav_center">

      <ul class="nav bottom_nav">
        <li class="nav-item"><a (click)="toaboutus()" class="js-scroll-trigger">About us</a></li>
        <li class="nav-item"><a class="js-scroll-trigger" (click)="towhatwedo()">What we do</a></li>
        <li class="nav-item"><a class="js-scroll-trigger" (click)="tomission()"> Mission</a></li>
        <li class="nav-item"><a class="js-scroll-trigger" (click)="tomission()"> Vision </a></li>
        <li class="nav-item"><a class="js-scroll-trigger" (click)="tomission()">Purpose Statement</a></li>
        <li class="nav-item"><a class="js-scroll-trigger" (click)="towhySenstra()">Why Senstra?</a></li>
        <li class="nav-item"><a class="js-scroll-trigger" (click)="toteam()">Team</a></li>
      </ul>
     <!--  <ul class="nav bottom_nav">
        <li class="nav-item"><a (click)="toaboutus()" class="js-scroll-trigger">About us</a></li>
        <li class="nav-item"><a class="js-scroll-trigger" (click)="towhatwedo()">What we do</a></li>
        <li class="nav-item"><a class="js-scroll-trigger" (click)="tomission()"> Mission</a></li>
        <li class="nav-item"><a class="js-scroll-trigger" (click)="tomission()"> Vision </a></li>
        <li class="nav-item"><a class="js-scroll-trigger" (click)="tomission()">Purpose Statement</a></li>
        <li class="nav-item"><a class="js-scroll-trigger" (click)="towhySenstra()">Why Senstra?</a></li>
        <li class="nav-item"><a class="js-scroll-trigger" (click)="toteam()">Team</a></li>
      </ul> -->
    </div>
  </div>
</section>
<!--====================About section====================-->

<div>
<section class="about_us" id="aboutus">
  <div class="container">

    <h1 class="gray_color" data-aos="fade-right" id="aboutUSdiv">About Us</h1>

    <div class="row">

      <div class="col-md-1"></div>
      <div class="col-md-11">
        <div class="row about_new_Section" data-aos="fade-up" >
          <div class="col-md-6 col-12">
            <div class="about_left_p">

              <p>We are a lean and agile team of engineers, data scientists, and developers who aim to solve challenges by designing and developing end-to-end customised IoT solutions. Our journey kicked-off with observing some long-standing industry challenges which could be solved using cutting edge technologies but weren't. Seeing the potential of IoT capabilities and the business benefits it could accumulate, we started ‘Senstra IoT Solutions'. Innovation and creative alliance are fundamental to what we build and develop for our customers. </p>
            </div>

          </div>
          <div class="col-md-5 col-12">
            <div class="about_right_p">
              <p>More often than not you can find us help navigate business challenges for our clients and discussing technology roadmaps to solve them. Our aim is to provide remarkable solutions that go beyond gathering telemetry data from remote areas in the field but to also couple it with data available in your business to provide meaningful insights and help make informed decisions.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</div>
<!--==================== what-we-do section====================-->

<section id="whatwedo">
<section class="what-we-do" >
  <div class="container">
    <div class="what-we-do_inner">
      <h1 class="gray_color" data-aos="fade-right">What We <br> Do</h1>
      <div class="img_div" data-aos="fade-up" style="min-height: 450px;">
        <img src="assets/img/what we do.jpg" class="img-responisve" alt="what-we-do">
        <div class="what-we_inner">
          <p>Senstra is an Australia based end to end IoT solutions company of engineers, data scientists and developers who have come together to design and develop custom IoT solutions for our clients. We aim to provide a solution that goes beyond gathering telemetry from far to reach areas in the field but to also couple this with data available in your business to provide meaningful insights and help inform and drive decisions.</p>
        </div>
      </div>
    </div>
  </div>
</section>
</section>
<div class="clearfix"></div>
<!--==================== mission section====================-->
<section class="mission" id="mission">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-sm-12 col-12">
        <div class="mission-inner text-center" data-aos="fade-up" style="min-height: 250px;">
          <div class="img_mission"> <img src="assets/img/Mission.svg"></div>
          <h3 class="blue-dark ">Mission</h3>
          <p>Make the world more connected through IoT and Web3</p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-12 col-12">
        <div class="mission-inner text-center" data-aos="fade-up" style="min-height: 250px;">
          <div class="img_mission"> <img src="assets/img/VIsion.svg"></div>
          <h3 class="blue-dark">Vision</h3>
          <p>Use the power of technology to help organisations be empowered with data and solutions so they can do better for the world</p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-12 col-12">
        <div class="mission-inner1 text-center" data-aos="fade-up" style="min-height: 250px;">
           <div class="img_mission"><img src="assets/img/Personnel on-site reporting.svg"></div>
          <h3 class="blue-dark">Purpose Statement</h3>
          <p>At Senstra, our purpose is connecting the unconnected with IoT and Web3. Our quality and targeted solutions help organisations in solving operational and strategic problems</p>
        </div>
      </div>
    </div>
  </div>
</section>
<!--==================== whySenstra section====================-->
<section class="what-we-do whySenstra" id="whySenstra">
  <div class="container">
    <div class="what-we-do_inner">
      <h1 class="gray_color" data-aos="fade-right">Why <br> Senstra? </h1>
      <div class="img_div" data-aos="fade-up" style="min-height: 450px;">
        <img src="assets/img/why senstra.jpg" class="img-responisve" alt="what-we-do">
        <div class="what-we_inner">
          <ul>
            <li>Our solutions are designed for your use case in mind. We believe that no product can fit all the use cases.</li>
            <li>We work with you to ensure the successful integration of IoT into your ecosystem.</li>
            <li>Our expertise in data analysis and data science ensures our IoT solution not only collects the correct data but ensures that it is analysed and used correctly by your organisation.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="clearfix"></div>
<!--==================== whySenstra section====================-->
<section id="team">
  <div class="container">
    <h1 class="gray_color" data-aos="fade-right">Team</h1>
    <div class="about_p" data-aos="fade-up">
      <p>We are a lightweight high-velocity team of qualified professionals with 50+ years of collective experience crafted to provide our clients with the expertise needed in IoT engineering and data.</p>
    </div>
    <div class="row team_margin">
      <div class="col-md-4 col-sm-6 col-12">
        <div class="team_inner text-center" data-aos="fade-up">
          <a (click)="openDialogkyle()">
            <img src="assets/img/Andrew-Peddar.png">
            <h4 class="blue-dark">Andrew Peddar</h4>
            <p>Chief of Growth</p>
          </a>
        </div>
        
      </div>
          <!-- <div class="col-md-4 col-sm-6 col-12">
          <div class="team_inner text-center" data-aos="fade-up">
            <a (click)="openDialogpeter()">
              <img src="assets/img/Peter Inge.png">
              <h4 class="blue-dark">Peter Inge</h4>
              <p>Chairman of the Board</p>
            </a>
          </div>
        </div> -->
      
      <!-- <div class="col-md-4 col-sm-6 col-12">
        <div class="team_inner text-center" data-aos="fade-up">
         <a (click)="openDialogMike()">
            <img src="assets/img/Mike-Robinson.png">
            <h4 class="blue-dark">Mike Robinson</h4>
            <p>Director</p>
          </a>
        </div>
      </div> -->
       
        <div class="col-md-4 col-sm-6 col-12">
        <div class="team_inner text-center" data-aos="fade-up">
          <a (click)="openDialoghem()">
            <img src="assets/img/Hem Panicker.png">
            <h4 class="blue-dark">Hem Panicker</h4>
            <p>Chief Customer Officer</p>
          </a>
        </div>
       
      </div>
      <div class="col-md-4 col-sm-6 col-12">
        <div class="team_inner text-center" data-aos="fade-up">
          <a (click)="openDialogkenneth()">
            <img src="assets/img/Kenneth Lam.png">
            <h4 class="blue-dark">Kenneth Lam</h4>
            <p>Chief Technical Officer</p>
          </a>
        </div>
       
      </div>
      <div class="col-md-4 col-sm-6 col-12">
        <div class="team_inner text-center" data-aos="fade-up">
         <a (click)="openDialogchintan()">
            <img src="assets/img/Chintan Gatecha.png">
            <h4 class="blue-dark">Chintan Gatecha</h4>
            <p>Chief Operating Officer</p>
          </a>
        </div>
       
      </div>
    
      <div class="col-md-4 col-sm-6 col-12">
        <div class="team_inner text-center" data-aos="fade-up">
          <a (click)="openDialogneha()">
            <img src="assets/img/Neha Bains.png">
            <h4 class="blue-dark">Neha Bains</h4>
            <p>Chief Marketing Officer</p>
          </a>
        </div>
       
      </div>
      <div class="col-md-4 col-sm-6 col-12">
        <div class="team_inner text-center" data-aos="fade-up">
           <a (click)="openDialoghayden()">
            <img src="assets/img/Hayden Van Hamond.png">
            <h4 class="blue-dark">Hayden Van Hamond</h4>
            <p>Senior Product Design Engineer</p>
          </a>
        </div>
      
      </div>
      <div class="col-md-4 col-sm-6 col-12">
        <div class="team_inner text-center" data-aos="fade-up">
         <a (click)="openDialogsanket()">
            <img src="assets/img/Sanket Chalke.png">
            <h4 class="blue-dark">Sanket Chalke</h4>
            <p>Senior Firmware Engineer</p>
          </a>
        </div>
      </div>
       
    
    </div>
  </div>
</section>
