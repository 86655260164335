 <button *ngIf="isShow" (click)="gotoTop()" class="button_fixed"><i class="fa fa-angle-up" aria-hidden="true"></i></button>
 
<!--====================footer start====================-->
<footer>
  <div class="top_footer">
    <div class="container">
      <h2 class="blue-dark" data-aos="fade-up">Contact us to start your IoT journey</h2>
      <!--  <div class="row">
                <div class="col-lg-3 col-md-3 col-12" data-aos="fade-up">
                    <div class="footer-inner">
                        <h3>Address</h3>
                        <h4 class="footer_add">Chatswood, New South<br>Wales, Australia</h4>
                        <h4>283-287 Sir Donald Bradman Drive, Adelaide, SA Australia</h4>
                    </div>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-3  col-12" data-aos="fade-up">
                    <div class="footer-inner">
                        <h3>Email</h3>
                        <h4><a href="mailto:marketing@senstra.com.au">Iot@senstra.com.au</a></h4>
                    </div>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-3  col-12" data-aos="fade-up">
                    <div class="footer-inner">
                        <h3>Contact</h3>
                        <h4>+61 488 227 587</h4>
                    </div>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-3  col-12" data-aos="fade-up">
                    <div class="footer-inner">
                        <h3>Linkedin</h3>
                        <h4>company/SenstraloT</h4>
                    </div>
                </div>
            </div> -->
      <div class="row">
        <div class="col-md-12">
          <div class="subscribe_inner" data-aos="fade-up">
            <span>Subscribe to our Newsletter</span>
            <form (ngSubmit)="onSubmit()" #contactForm="ngForm">
              <div [hidden]="submitted">
                <div class="input-group">
                  <input type="text" name="email" id="email" [(ngModel)]="model.email" class="form-control" required email #email="ngModel" placeholder="Email Address">
                  <button class="btn btn-secoundry" [disabled]="!contactForm.form.valid" type="submit">Subscribe <img src="assets/img/left-arrow_n.svg"></button>
                </div>
                <div *ngIf="email.invalid && (email.dirty || email.touched)" class="error">
                  <div *ngIf="email.errors.required">Email is required.</div>
                  <div *ngIf="email.errors.email">Email must be a valid email address.</div>
                </div>
              </div>
              <div class="service-error" *ngIf="error">
                <h1>{{error.errorTitle}}</h1>
                <h3>{{error.errorDesc}}</h3>
              </div>
              <div [hidden]="!submitted" class="contact-message">
                <div *ngIf="!error" class="contact-success">
                  <h5 style="margin-top: 7px;">Congratulations! You are now subscribed to Senstra’s IoT Newsletter.</h5>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
       <div class="row">
        <div class="col-md-12">
          <div class="bottom_menu" data-aos="fade-up">
      <!--      <ul class="nav">
              <li class="nav-item">
                <a class="nav-link" [routerLink]="'/all-solutions'">Solutions</a>
               !--  <ul class="bottom_submenu">
                  <li><a [routerLink]="'/all-solutions'">By Industry</a></li>
                  <li><a [routerLink]="'/all-use-case'">By Use Case</a></li>
                </ul> --
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="'/epitome'">Product</a>
                <ul class="bottom_submenu">
                  <li><a [routerLink]="'/epitome'">ePITome</a></li>
                 !--  <li><a href="sitsensor">SIT Sensor</a></li> --
                </ul>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="'/services'">Services</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="'/platform'">Platform</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="'/why-senstra'">Why Senstra</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="'/resources'">Resources </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="'/about'">About Us</a>
                <ul class="bottom_submenu">
                  <li><a [routerLink]="'/about'" fragment = "team">Team</a></li>
                </ul>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="'/contact-us'">Contact Us</a>
              </li>
            </ul> -->
          </div> 
        </div> 
      </div> 
    </div>
    <img src="assets/img/footer_bg_mobile.png" class="mobile_footer">
  </div>
  <div class="bottom_footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="bottom_footer_ul">
            <ul class="social_link left_social">
              <li><a routerLink="." class="follow">Follow Us</a></li>
             <!--  <li><a routerLink="." target="_blank">Facebook</a></li> -->
              <li><a class="before_none" href="https://www.linkedin.com/company/senstraiot" target="_blank">LinkedIn</a></li>
             <!--  <li><a routerLink="." target="_blank">Twitter</a></li>
              <li><a routerLink="." class="before_none" target="_blank">Instagram</a></li> -->
            </ul>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="bottom_footer_ul privacy-policy">
            <ul class="social_link">
              <li><a [routerLink]="'/privacy-policy'">Privacy Policy</a></li>
             <!--  <li><a routerLink=".">Cookies Policy</a></li> -->
              <li><a routerLink="." class="before_none">Copyright ©2020 Senstra loT Solutions All Rights Reserved</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

