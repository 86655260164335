export class CheckCompatibility {
    id: number;
    industry: string = '0';
    other_industry: string;
    assets: string = 'yes';
    assets_range: string = '0';
    assets_location: string;
    assets_dashboard: string;
    assets_tracking: string;
    assets_theft: string;
    assets_analysis: string;
    assets_decisions: string;
    assets_kpi: string;
    name: string;
    email: string;
    job_title: string;
    comp_name: string;
    country: string = 'Australia';   
}
