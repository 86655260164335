import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequestDemoComponent } from '../../modals/request-demo/request-demo.component';
import { RequestATrailComponent } from '../../modals/request-a-trail/request-a-trail.component';

import { YourcolleagueComponent } from '../../modals/yourcolleague/yourcolleague.component';

@Component({
  selector: 'app-e-pitome',
  templateUrl: './e-pitome.component.html',
  styleUrls: ['./e-pitome.component.css']
})
export class EPITomeComponent implements OnInit {

   email: string;
  
  constructor(public dialog: MatDialog) {}

  openDialog(): void {
    const dialogRef = this.dialog.open(RequestDemoComponent,  {
      maxWidth: '50vw',
      maxHeight: '75vh',
      height: '100%',
      width: '100%'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.email = result;
    });
  }

   openDialog2(): void {
    const dialogRef = this.dialog.open(RequestATrailComponent,  {
      maxWidth: '50vw',
      maxHeight: '75vh',
      height: '100%',
      width: '100%'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.email = result;
    });
  }


  openDialog1(): void {
    const dialogRef = this.dialog.open(YourcolleagueComponent,  {
      maxWidth: '50vw',
      maxHeight: '75vh',
      height: '100%',
      width: '100%'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.email = result;
    });
  }

  ngOnInit(): void {
   function playVideo() { 
            $('#videoEpotomi').trigger('play'); 
        } 
     
      $(document).ready(function($) {

  var $navBar = $('#mainNav');
  var showNavBarMinimum = $('#introduction').height() - $navBar.height();

  var wasNavBarVisible = false;
  // change nav bar visibility on scroll
  function onScroll() {
    var isNavBarVisible = window.pageYOffset >= showNavBarMinimum;
    if ( isNavBarVisible != wasNavBarVisible ) {
      $navBar.toggleClass('show');
      wasNavBarVisible = isNavBarVisible;
    }
  }
  // initial check
  onScroll();

  // http://davidwalsh.name/function-debounce
  function debounce( fn, wait ) {
    var timeout;
    return function() {
      var _this = this;
      var args = arguments;
      var later = function() {
        timeout = null;
        fn.apply( _this, args );
      };
      clearTimeout( timeout );
      timeout = setTimeout( later, wait || 100 );
    };
  }

  $(window).scroll( debounce( onScroll, 30 ) );

 
  });
     
  }

  playVideo() {
    let audioPlayer = <HTMLVideoElement> document.getElementById('videoNew');
    audioPlayer.play(); 
    document.getElementById('video-play-button').style.display = "none";
  }

toVideo(){
document.getElementById("epitomie_videonew").scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
}

toBenefitsandusps(){
document.getElementById("Benefitsandusps").scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
}

tofeatures(){
document.getElementById("features").scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
}

tousecase(){
document.getElementById("usecase").scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
}

toReadiness(){
document.getElementById("Readiness").scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
}



toTechSpecs(){
document.getElementById("TechSpecs").scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
}

toDemo(){
document.getElementById("Demo").scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
}

toRevolutionise(){
document.getElementById("Revolutionise").scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
}






}
