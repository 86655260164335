import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequestDemoComponent } from '../../modals/request-demo/request-demo.component';
import { KylePattisonComponent } from '../../modals/kyle-pattison/kyle-pattison.component';
import { PeterIngeComponent } from '../../modals/peter-inge/peter-inge.component';
import { HemPanickerComponent } from '../../modals/hem-panicker/hem-panicker.component';
import { KennethLamComponent } from '../../modals/kenneth-lam/kenneth-lam.component';
import { ChintanComponent } from '../../modals/chintan/chintan.component';
import { NehaComponent } from '../../modals/neha/neha.component';
import { HaydenComponent } from '../../modals/hayden/hayden.component';
import { SanketComponent } from '../../modals/sanket/sanket.component';
import { MikeComponent } from '../../modals/mike/mike.component';
import { NicolaDorlingComponent } from '../../modals/nicola-dorling/nicola-dorling.component';


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  email: string;
  
  constructor(public dialog: MatDialog) {}

  openDialog(): void {
    const dialogRef = this.dialog.open(RequestDemoComponent,  {
      maxWidth: '50vw',
      maxHeight: '75vh',
      height: '100%',
      width: '100%'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.email = result;
    });
  }

  openDialogkyle(): void {
    const dialogRef = this.dialog.open(KylePattisonComponent,  {
      maxWidth: '100vh',
      maxHeight: '100vh',
      height: '100%',
      width: '100%'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.email = result;
    });
  }

  openDialogpeter(): void {
    const dialogRef = this.dialog.open(PeterIngeComponent,  {
      maxWidth: '100vh',
      maxHeight: '100vh',
      height: '100%',
      width: '100%'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.email = result;
    });
  }

   openDialoghem(): void {
    const dialogRef = this.dialog.open(HemPanickerComponent,  {
      maxWidth: '100vh',
      maxHeight: '100vh',
      height: '100%',
      width: '100%'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.email = result;
    });
  }


openDialogkenneth(): void {
    const dialogRef = this.dialog.open(KennethLamComponent,  {
      maxWidth: '100vh',
      maxHeight: '100vh',
      height: '100%',
      width: '100%'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.email = result;
    });
}
openDialogchintan(): void {
    const dialogRef = this.dialog.open(ChintanComponent,  {
      maxWidth: '100vh',
      maxHeight: '100vh',
      height: '100%',
      width: '100%'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.email = result;
    });
}


openDialognicola(): void {
    const dialogRef = this.dialog.open(NicolaDorlingComponent,  {
      maxWidth: '100vh',
      maxHeight: '100vh',
      height: '100%',
      width: '100%'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.email = result;
    });
}

openDialogneha(): void {
    const dialogRef = this.dialog.open(NehaComponent,  {
      maxWidth: '100vh',
      maxHeight: '100vh',
      height: '100%',
      width: '100%'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.email = result;
    });
}

openDialoghayden(): void {
    const dialogRef = this.dialog.open(HaydenComponent,  {
      maxWidth: '100vh',
      maxHeight: '100vh',
      height: '100%',
      width: '100%'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.email = result;
    });
}

openDialogsanket(): void {
    const dialogRef = this.dialog.open(SanketComponent,  {
      maxWidth: '100vh',
      maxHeight: '100vh',
      height: '100%',
      width: '100%'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.email = result;
    });
}

  openDialogMike(): void {
    const dialogRef = this.dialog.open(MikeComponent,  {
      maxWidth: '100vh',
      maxHeight: '100vh',
      height: '100%',
      width: '100%'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.email = result;
    });
  }

  ngOnInit(): void {
   $(document).ready(function($) {

  var $navBar = $('#mainNav');
  var showNavBarMinimum = $('#introduction').height() - $navBar.height();

  var wasNavBarVisible = false;
  // change nav bar visibility on scroll
  function onScroll() {
    var isNavBarVisible = window.pageYOffset >= showNavBarMinimum;
    if ( isNavBarVisible != wasNavBarVisible ) {
      $navBar.toggleClass('show');
      wasNavBarVisible = isNavBarVisible;
    }
  }
  // initial check
  onScroll();

  // http://davidwalsh.name/function-debounce
  function debounce( fn, wait ) {
    var timeout;
    return function() {
      var _this = this;
      var args = arguments;
      var later = function() {
        timeout = null;
        fn.apply( _this, args );
      };
      clearTimeout( timeout );
      timeout = setTimeout( later, wait || 100 );
    };
  }

  $(window).scroll( debounce( onScroll, 30 ) );

 
  });





  }
 

toaboutus(){
document.getElementById("aboutUSdiv").scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
}
towhatwedo(){
document.getElementById("whatwedo").scrollIntoView({behavior:"smooth"});
}
tomission(){
document.getElementById("mission").scrollIntoView({behavior:"smooth"});
}
towhySenstra(){
document.getElementById("whySenstra").scrollIntoView({behavior:"smooth"});
}
toteam(){
document.getElementById("team").scrollIntoView({behavior:"smooth"});
}

}
