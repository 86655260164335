<div class="mat-dialog-content_new" mat-dialog-content>
  <div class="modal-dialog modal-lg team_modal" role="document">
    <div class="modal-content">
    	<div class="modal-body">
	        <button type="button" class="close" (click)="onNoClick()">
	          <span aria-hidden="true"><img src="assets/img/cancel.svg"></span>
	        </button>
                <div class="row modal_bg_left">
                  <div class="col-md-5 col-sm-12 col-12">
                    <div class="team_left">
                      <img src="assets/img/Chintan Gatecha.png">
                      <h4 class="blue-dark">Chintan Gatecha</h4>
                      <p>Chief Operating Officer</p>
                    </div>
                  </div>
                  <div class="col-md-7 col-sm-7 col-12">
                    <div class="team_right">
                      <p>Chintan oversees the business operations for Senstra and makes sure Senstra is set up for delivering the highest quality service and product for the clients. At Senstra, Chintan is responsible for:</p>
                      <ul>
                        <li>Planning, mapping and implementing organization’s roadmap.</li>
                        <li>Leading strategic projects for the organization.</li>
                        <li>Helping CTO in managing the product line.</li>
                        <li>Hiring right talent.</li>
                        <li>Helping internal stakeholders and investors understand the value of the current vision and work with them to realise it.</li>
                      </ul>
                      <a href="https://www.linkedin.com/in/chintangatecha/" target="_blank" class="blue-light">Linkedin <img src="assets/img/left-arrow.svg"></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>