import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import {HomeComponent} from './components/pages/home/home.component'
import {AboutComponent} from './components/pages/about/about.component';
import {ByindustryComponent} from './components/pages/byindustry/byindustry.component'
import {ContactUsComponent} from './components/pages/contact-us/contact-us.component';
import {SITSensorComponent} from './components/pages/sitsensor/sitsensor.component';
import {EPITomeComponent} from './components/pages/e-pitome/e-pitome.component';
import { TelcoInfrastructureComponent } from './components/pages/telco-infrastructure/telco-infrastructure.component';
import { IntelligentTrafficComponent } from './components/pages/intelligent-traffic/intelligent-traffic.component';
import { SupplyChainLogisticsComponent } from './components/pages/supply-chain-logistics/supply-chain-logistics.component';
import { SmartCitiesComponent } from './components/pages/smart-cities/smart-cities.component';
import { AllUseCaseComponent } from './components/pages/all-use-case/all-use-case.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { PlatformComponent } from './components/pages/platform/platform.component';
import { WhySenstraComponent } from './components/pages/why-senstra/why-senstra.component';
import { ResourcesComponent } from './components/pages/resources/resources.component';
import { AllSolusionComponent } from './components/pages/all-solusion/all-solusion.component';
import { WorkforceManagementComponent } from './components/pages/workforce-management/workforce-management.component';
import { TelcosComponent } from './components/pages/telcos/telcos.component';
import { ExclusiveSignsComponent } from './components/pages/exclusive-signs/exclusive-signs.component';
import { WhyIotStartupsComponent } from './components/pages/why-iot-startups/why-iot-startups.component';
import { ReportComponent } from './components/pages/report/report.component';
import { CheckCompatibilityComponent } from './components/pages/check-compatibility/check-compatibility.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { WebThreeComponent } from './components/pages/web-three/web-three.component';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 250],
};

const routes: Routes = [
  { path:'', component:HomeComponent},
  { path:'home', component:HomeComponent},
  { path:'about', component:AboutComponent},
  { path:'by-industry', component:ByindustryComponent},
  { path:'contact-us', component:ContactUsComponent},
  { path:'sitsensor', component:SITSensorComponent},
  { path:'epitome', component:EPITomeComponent},
  { path:'telco-infrastructure', component:TelcoInfrastructureComponent},
  { path:'intelligent-traffic', component:IntelligentTrafficComponent},
  { path:'utilities-power', component:SupplyChainLogisticsComponent },
  { path:'workforce-management', component:WorkforceManagementComponent },
  { path:'all-solutions', component: AllSolusionComponent},
  { path:'resources', component:ResourcesComponent},
  { path:'why-senstra', component:WhySenstraComponent},
  { path:'platform', component:PlatformComponent},
  { path:'services', component:ServicesComponent},
  { path:'all-use-case', component:AllUseCaseComponent},
  { path:'smart-cities', component:SmartCitiesComponent },
  { path:'telcos', component:TelcosComponent},
  { path:'exclusive-signs', component:ExclusiveSignsComponent},
  { path:'why-iot-startups', component:WhyIotStartupsComponent},
  { path:'report', component:ReportComponent},
  { path:'check-compatibility', component:CheckCompatibilityComponent},
  { path:'privacy-policy', component:PrivacyPolicyComponent},
  { path:'web3', component:WebThreeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
})
export class AppRoutingModule { }
