<div class="mat-dialog-content_new" mat-dialog-content>
  <div class="modal-dialog modal-lg team_modal" role="document">
    <div class="modal-content">
    	<div class="modal-body">
	        <button type="button" class="close" (click)="onNoClick()">
	          <span aria-hidden="true"><img src="assets/img/cancel.svg"></span>
	        </button>
	          <div class="row modal_bg_left">
                  <div class="col-md-5 col-sm-12 col-12">
                    <div class="team_left">
                      <img src="assets/img/Peter Inge.png">
                      <h4 class="blue-dark">Peter Inge</h4>
                      <p>Chairman of the Board</p>
                    </div>
                  </div>
                  <div class="col-md-7 col-sm-7 col-12">
                    <div class="team_right">
                      <p>
                        Peter is the Chairman of the Board at Senstra. He has comprehensive experience across the Data & Analytics landscape, he is an experienced Data Strategist & Data Scientist who has a strong track record of building high performing Data, Data Science and Big Data capability in commercial organisations. Peter balances deep technical capabilities with business acumen and comprehensive grasp of organisation strategy.
                      </p>
                      <p>Peter has almost 20 years experience working across a Predictive and Statistical Modelling, Prescriptive Modelling & Optimization, Machine Learning, Data Modelling, Data Visualization as well as Software Engineering. In addition, Peter has also done significant work in Information Management, Data Governance, Information Security and Fraud and Forensic Analysis. Peter also has experience and capabilities in Behavioral Economics and Decision Theory</p>
                      <p>Peter has worked across many domains including Marketing and Customer Analytics, Pricing, Risk, Supply Chain and Logistics, Finance, Supply and Trading, Human Capital and Work Force Analytics.</p>
                      <a href="https://www.linkedin.com/in/peter-inge-0429827/" target="_blank" class="blue-light">Linkedin <img src="assets/img/left-arrow.svg"></a>
                    </div>
                  </div>
                </div>
        </div>
    </div>
   </div>
 </div>