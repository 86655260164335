import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-neha',
  templateUrl: './neha.component.html',
  styleUrls: ['./neha.component.css']
})
export class NehaComponent implements OnInit {

  
 constructor( public dialogRef: MatDialogRef<NehaComponent>){}

  onNoClick(): void {
    this.dialogRef.close();
  }


  ngOnInit(): void {
  }

}
