import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-chintan',
  templateUrl: './chintan.component.html',
  styleUrls: ['./chintan.component.css']
})
export class ChintanComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<ChintanComponent>){}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
