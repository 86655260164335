 <div class="mat-dialog-content_new" mat-dialog-content>
  <div class="modal-dialog modal-lg team_modal" role="document">
    <div class="modal-content">
    	<div class="modal-body">
	        <button type="button" class="close" (click)="onNoClick()">
	          <span aria-hidden="true"><img src="assets/img/cancel.svg"></span>
	        </button>
                <div class="row modal_bg_left">
                  <div class="col-md-5 col-sm-12 col-12">
                    <div class="team_left">
                      <img src="assets/img/Hayden Van Hamond.png">
                      <h4 class="blue-dark">Hayden Van Hamond</h4>
                      <p>Senior Product Design Engineer</p>
                    </div>
                  </div>
                  <div class="col-md-7 col-sm-7 col-12">
                    <div class="team_right">
                      <p>Hayden oversees the technical developments in Senstra’s product pipeline. He has a background in Mechatronics Engineering with well founded experience in Product Design, Research and Development. His technical expertise and team management skills allow for high impact work-flow and improved solution turn-around times.
                      </p>
                      <p class="normal_p">At Senstra, Hayden is responsible for:</p>
                      <ul>
                        <li>Planning, mapping and implementing product road maps and development cycles.</li>
                        <li>Design, development and deployment of new products & deliverables. </li>
                        <li>Utilising market analytics to improve existing products and systems. </li>
                        <li>Expanding product range and building value for customers. </li>
                      </ul>
                      <a href="https://www.linkedin.com/in/hayden-van-hamond/" target="_blank" class="blue-light">Linkedin <img src="assets/img/left-arrow.svg"></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>