import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-kenneth-lam',
  templateUrl: './kenneth-lam.component.html',
  styleUrls: ['./kenneth-lam.component.css']
})
export class KennethLamComponent implements OnInit {

 constructor( public dialogRef: MatDialogRef<KennethLamComponent>){}

  onNoClick(): void {
    this.dialogRef.close();
  }


  ngOnInit(): void {
  }

}
