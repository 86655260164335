import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import * as AOS from 'aos';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequestDemoComponent } from '../../modals/request-demo/request-demo.component';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  customOptions: any = {
    loop: true,
    autoplay:true,
    autoplayHoverPause: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
     autoplayTimeout:100000,
    navSpeed: 1000,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  }

  email: string;
  
  constructor(public dialog: MatDialog) {}

  openDialog(): void {
    const dialogRef = this.dialog.open(RequestDemoComponent,  {
      maxWidth: '50vw',
      maxHeight: '75vh',
      height: '100%',
      width: '100%'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.email = result;
    });
  }

  ngOnInit(): void {

 $(document).ready(function($) {
    var $slide = $('.slide'),
        $slideGroup = $('.slide-group'),
        $bullet = $('.bullet');

    var slidesTotal = ($slide.length - 1),
        current = 0,
        isAutoSliding = true;

    $bullet.first().addClass('current');

    var clickSlide = function() {
        //stop auto sliding
        window.clearInterval(autoSlide);
        isAutoSliding = false;

        var slideIndex = $bullet.index($(this));

        updateIndex(slideIndex);
    };

    var updateIndex = function(currentSlide) {
        if (isAutoSliding) {
            if (current === slidesTotal) {
                current = 0;
            } else {
                current++;
            }
        } else {
            current = currentSlide;
        }

        $bullet.removeClass('current');
        $bullet.eq(current).addClass('current');

        transition(current);
    };

    var transition = function(slidePosition) {
        $slideGroup.animate({
            'top': '-' + slidePosition + '00%'
        });
    };

    $bullet.on('click', clickSlide);

    var autoSlide = window.setInterval(updateIndex, 4000);
});
   
 
  }

   toExploreSolusion(){
document.getElementById("ExploreSolusion").scrollIntoView({behavior:'smooth', block: 'center'});

}
}
