import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-nicola-dorling',
  templateUrl: './nicola-dorling.component.html',
  styleUrls: ['./nicola-dorling.component.css']
})
export class NicolaDorlingComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<NicolaDorlingComponent>){}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
