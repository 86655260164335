<!--====================slider section====================-->
<section class="prod_padd">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
        <div class="pro_box">
          <div class="img-contain">
            <img src="assets/img/Image 73.png">
          </div>
          <div class="pro-detail">
            <h3>ePITome</h3>
            <a download="Senstra_ePITome.pdf" href="assets/Brochure/Senstra_ePITome.pdf" class="dwLoad-btn">Download Brochure</a>
          </div>
          
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
        <div class="pro_box">
          <div class="img-contain">
            <img src="assets/img/CBFPI_wo logo.png">
          </div>
          <div class="pro-detail">
            <h3>CBFPI</h3>
            <a download="Senstra_CBFPI.pdf" href="assets/Brochure/Senstra_CBFPI.pdf" class="dwLoad-btn">Download Brochure</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
        <div class="pro_box">
          <div class="img-contain">
            <img src="assets/img/Image 74.png">
          </div>
          <div class="pro-detail">
            <h3>ReVolt</h3>
            <a download="Senstra_ReVolt.pdf" href="assets/Brochure/Senstra_ReVolt.pdf" class="dwLoad-btn">Download Brochure</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
        <div class="pro_box">
          <div class="img-contain">
            <img src="assets/img/Layer 1.png">
          </div>
          <div class="pro-detail">
            <h3>CBISM</h3>
            <a download="Senstra_CBISM.pdf" href="assets/Brochure/Senstra_CBISM.pdf" class="dwLoad-btn">Download Brochure</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
        <div class="pro_box">
          <div class="img-contain">
            <img src="assets/img/Layer 4.png">
          </div>
          <div class="pro-detail">
            <h3>CBRDU</h3>
            <a download="Senstra_CBRDU.pdf" href="assets/Brochure/Senstra_CBRDU.pdf" class="dwLoad-btn">Download Brochure</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
        <div class="pro_box">
          <div class="img-contain">
            <img src="assets/img/Layer 3.png">
          </div>
          <div class="pro-detail">
            <h3>IDTRM</h3>
            <a download="Senstra_IDTRM.pdf" href="assets/Brochure/Senstra_IDTRM.pdf" class="dwLoad-btn">Download Brochure</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--====================slider section end====================-->
<div class="clearfix"></div>
<section class="about_menu" id="mainNav" data-aos="fade-up">
  <div class="container">
    <div class="nav_center">
      <ul class="nav bottom_nav" id="mainNav">
        <li class="nav-item"><a (click)="toVideo()" class="js-scroll-trigger">Video</a></li>
        <li class="nav-item"><a class="js-scroll-trigger" (click)="toBenefitsandusps()"> Benefits & USPs</a></li>
        <li class="nav-item"><a class="js-scroll-trigger" (click)="tofeatures()"> Features</a></li>
        <!-- <li class="nav-item"><a class="js-scroll-trigger" (click)="tousecase()"> Use Case</a></li> -->
        <!-- <li class="nav-item"><a class="js-scroll-trigger" (click)="toReadiness()"> Readiness Challenge</a></li> -->
        <!--   <li class="nav-item"><a class="js-scroll-trigger" (click)="toExploded()" >Exploded View</a></li> -->
        <!-- <li class="nav-item"><a class="js-scroll-trigger" (click)="toTechSpecs()"> Tech Specs</a></li> -->
        <li class="nav-item"><a class="js-scroll-trigger" (click)="toDemo()">Demo</a></li>
      </ul>
    </div>
  </div>
</section>
<!-- <div id="Revolutionise">
  <section class="Revolutionise-your-business text-center">
    <div class="container">
      <h3 class="blue-dark e_head" data-aos="fade-up">ePITome</h3>
      <p data-aos="fade-up"> ePITome is an AWS qualified real-time asset monitoring solution. ePITome leverages the power of IoT and smart sensors to communicate real-time statistics and changes in the asset environment to deliver live alerts through Email, SMS or Senstra’s Android/iOS app.</p>
    </div>
  </section>
</div>
<div class="clearfix"></div> -->
<section class="ePITome_bottom">
  <div class="container">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-4 col-sm-12 col-12" style="margin: 0px 15px 20px 15px">
        <div id="epitomie_videonew">
          <div class="row">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/U7r9od4Ofrg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <a href="https://www.youtube.com/watch?v=U7r9od4Ofrg" target="_blank"> Revolt Demonstration | Senstra </a>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-12" style="margin: 0px 15px 20px 15px">       
        <div id="epitomie_videonew">
          <div class="row">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/DW8fQOd6oto" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <a href="https://www.youtube.com/watch?v=DW8fQOd6oto" target="_blank"> Senstra - ePITome Lite </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-4 col-sm-12 col-12" style="margin: 0px 15px 20px 15px">
        <div id="epitomie_videonew">
          <div class="row">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/3k_k_AwtzC4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <a href="https://www.youtube.com/watch?v=3k_k_AwtzC4" target="_blank"> Senstra - ePITome Device and Installation Overview </a>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-12" style="margin: 0px 15px 20px 15px">       
        <div id="epitomie_videonew">
          <div class="row">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/jx3HjK0sqNM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <a href="https://www.youtube.com/watch?v=jx3HjK0sqNM" target="_blank"> Senstra - ePITome </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--====================Benefits====================-->
<section id="Benefitsandusps">
  <section class="Benefits_epitome">
    <div class="container">
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <div class="row Our-usps-box">
            <h1 class="gray_color" data-aos="fade-right">Benefits</h1>
            <div class="col-md-3 col-12" data-aos="fade-up">
              <div class="Benefits_epitome_box">
                <div class="quick_div">
                  <img src="assets/img/Quick Deployment.svg" alt="Quick-Deployment">
                </div>
                <h4>Quick Deployment</h4>
              </div>
            </div>
            <div class="col-md-3 col-12" data-aos="fade-up">
              <div class="Benefits_epitome_box">
                <div class="quick_div">
                  <img src="assets/img/Free_support.svg" alt="Free-Support">
                </div>
                <h4>Free Support</h4>
              </div>
            </div>
            <div class="col-md-3 col-12" data-aos="fade-up">
              <div class="Benefits_epitome_box">
                <div class="quick_div">
                  <img src="assets/img/Cost.svg" alt="Cost-Friendly">
                </div>
                <h4>Cost Friendly</h4>
              </div>
            </div>
            <div class="col-md-3 col-12" data-aos="fade-up">
              <div class="Benefits_epitome_box">
                <div class="quick_div">
                  <img src="assets/img/Customisable.svg" alt="Customisable">
                </div>
                <h4>Customisable</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>
  </section>
  <!--====================Benefits====================-->
  <section class="Benefits_epitome">
    <div class="container">
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <div class="row Our-usps-box">
            <h1 class="gray_color" data-aos="fade-right">USPs</h1>
            <div class="col-md-3 col-12" data-aos="fade-up">
              <div class="Benefits_epitome_box">
                <div class="quick_div">
                  <img src="assets/img/Remote-Intrusion-Detection.svg" alt="Remote-Intrusion-Detection">
                </div>
                <h4>Remote Intrusion Detection</h4>
              </div>
            </div>
            <div class="col-md-3 col-12" data-aos="fade-up">
              <div class="Benefits_epitome_box">
                <div class="quick_div">
                  <img src="assets/img/Remote-Deployability.svg" alt="Remote-Deployability">
                </div>
                <h4>Remote Deployability</h4>
              </div>
            </div>
            <div class="col-md-3 col-12" data-aos="fade-up">
              <div class="Benefits_epitome_box">
                <div class="quick_div">
                  <img src="assets/img/Stand-Alone-Power-Supply.svg" alt="Stand-Alone-Power-Supply">
                </div>
                <h4>Stand Alone Power Supply</h4>
              </div>
            </div>
            <div class="col-md-3 col-12" data-aos="fade-up">
              <div class="Benefits_epitome_box">
                <div class="quick_div">
                  <img src="assets/img/Software.svg" alt="Easily-Customisable-Software">
                </div>
                <h4>Easily Customisable Software</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>
  </section>
</section>
<div class="clearfix"></div>
<section class="ePITome_features" id="features">
  <div class="container">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10 col-sm-12 col-12">
        <div class="row">
          <div class="col-md-6 col-sm-12 col-12">
            <div class="ePITome_features_left" data-aos="fade-up">
              <h2 class="blue-dark"> Features</h2>
              <ul>
                <li>Durable in rough outdoor environment with upto 10 years of battery life</li>
                <li> Real time secured data transmission to Cloud platform </li>
                <li>REST API for ingestion to your own platform</li>
                <li> Real time dashboard and notifications via SMS and email </li>
                <li>IOS and Android App for Intrusion Auditing</li>
              </ul>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 col-12">
            <div class="ePITome_features_right" data-aos="fade-up">
              <h2 class="blue-dark" style="font-size: 0px;">Technology</h2>
              <ul>
                <li>Sensor agnostic (digital, analog, UART etc.) </li>
                <li> Low power bluetooth technology v5 </li>
                <li>NB-IoT, CAT-M1, EGPRS for low power and low cost mass deployment.</li>
                <li>Pay for what you use</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="clearfix"></div>
<!-- <section class="Use-Cases Epitome_use-case" id="usecase">
  <div class="container">
    <div class="row">
      <div class="col-md-1 col-12"></div>
      <div class="col-md-10 col-sm-12 col-12">
        <h1 class="gray_color" data-aos="fade-left">Use Cases </h1>
        <div class="row use-case-top_div m-t-0" id="Temperature" data-aos="fade-up">
          <div class="col-md-6 col-sm-12 col-12">
            <div class="use-case-left">
              <h3 class="blue-dark"> Temperature and Humidity Sensing</h3>
              <img src="assets/img/Temperature-and-Humidity-Sensing.jpg" alt="Temperature-and-Humidity-Sensing">
              <p>Our nodes can provide multi-point temperature and humidity readings.</p>
              <ul>
                <li>Industry standard Temperature and Humidity sensor reducing the risk of critical assets going down and ensuring business continuity.</li>
                <li> Multiple temperature sensors can connected per device to provide a range of readings.</li>
                <li> Data is captured and ingested at the frequency that matters to you.</li>
                <li> Email, SMS and system alerts when thresholds are crossed to reduce the risk of damage to what matters to you.</li>
              </ul>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 col-12">
            <div class="use-case-right">
              <img src="assets/img/Temperature-and-Humidity-Sensing.jpg" alt="Temperature-and-Humidity-Sensing">
            </div>
          </div>
        </div>
        <div class="row use-case-top_div" id="Pressure" data-aos="fade-up">
          <div class="col-md-6 col-sm-12 col-12">
            <div class="use-case-left">
              <h3 class="blue-dark"> Realtime Water Level Indication</h3>
              <img src="assets/img/Realtime-Water-Level-Indication.jpg" alt="Realtime-Water-Level-Indication">
              <p>Ultrasonic sensors to detect water levels and alert our clients of potential flooding or water ingress.
              </p>
              <ul>
                 <li>Industry standard Temperature and Humidity sensor reducing the risk of critical assets going down and ensuring business continuity.</li>
                <li> Automatically be informed of flooding or water ingress into areas where sensitive equipment sits.</li>
                <li> Ultrasonic sensor capable of real-time readings to the centimetre.</li>
                <li>Data is captured when it matters to you.</li>
                <li>Email, SMS and system alerts when thresholds are crossed.</li>
              </ul>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 col-12">
            <div class="use-case-right">
              <img src="assets/img/Realtime-Water-Level-Indication.jpg" alt="Realtime-Water-Level-Indication">
            </div>
          </div>
        </div>
        <div class="row use-case-top_div" id="Intrusion-detection" data-aos="fade-up">
          <div class="col-md-6 col-sm-12 col-12">
            <div class="use-case-left">
              <h3 class="blue-dark">Intrusion detection</h3>
              <img src="assets/img/Intrusion-detection.jpg" alt="Intrusion-detection">
              <p>Detect intrusion into restricted areas and protect critical assets.</p>
              <ul>
                <li>Real-Time intrusion detection allowing you to act instantly and protect critical assets.</li>
                <li>Device will wake and provide real time alerts when an intrusion is detected.</li>
                <li>Real time alerts can be customised for the channel that suits you whether it be email, sms or system alerts.</li>
                <li> Rest-API access for further data analysis.</li>
              </ul>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 col-12">
            <div class="use-case-right">
              <img src="assets/img/Intrusion-detection.jpg" alt="Intrusion-detection">
            </div>
          </div>
        </div>
        <div class="row use-case-top_div" id="Light" data-aos="fade-up">
          <div class="col-md-6 col-sm-12 col-12">
            <div class="use-case-left">
              <h3 class="blue-dark">Asset access audit and Technician assistance</h3>
              <img src="assets/img/Asset-access-audit-and-Technician-assistance.jpg" alt="Asset-access-audit-and-Technician-assistance">
              <p>Our Android/IOS app alongside our IoT products and cloud solution offerings allows technicians in-field to see realtime readings and record the work they've conducted and allow you to track your technicians time and progress.</p>
              <ul>
                <li>Custom built Android/IOS Application suitable for businesses and their workforce.</li>
                <li>App displays historical and real time telemetry and work data for the user.</li>
                <li> Provides transparency to what technicians are doing in-field. </li>
                <li> Notifications generated based on technician's work.</li>
              </ul>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 col-12">
            <div class="use-case-right">
              <img src="assets/img/Asset-access-audit-and-Technician-assistance.jpg" alt="Asset-access-audit-and-Technician-assistance">
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-1 col-12"></div>
    </div>
  </div>
</section>
<section class="How-it-works" id="Readiness" data-aos="fade-up">
  <div class="container">
    <div class="row">
      <div class="col-md-1 col-12"></div>
      <div class="col-md-10 col-12">
        <div class="row">
          <div class="col-md-6 col-sm-12 col-12">
            <div class="How-it-works-left">
              <h2 class="blue-dark">How it works</h2>
              <h3>Readiness Challenge</h3>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 col-12">
            <div class="use-case-right How-it-works-right">
              <button type="button" class="btn btn-primary" (click)="openDialog2()">Request a trial</button>
              <a class="btn btn-secoundry Compatibility" [routerLink]="'/check-compatibility'" target="_blank">Check your compatibility</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-1 col-12"></div>
    </div>
  </div>
</section> -->
<!--====================img div====================-->
<!-- <section class="ePITome_img" id="Exploded" data-aos="fade-up">
  <img src="assets/img/ePITome-banner.png">
</section> -->
<!--====================Tech_Specs====================-->
<!-- <div id="TechSpecs">
  <section class="Tech_Specs">
    <div class="container">
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10 col-sm-12 col-12">
          <h1 class="gray_color" data-aos="fade-right">Tech Specs</h1>
          <div class="tech-spac_box-shodw" data-aos="fade-up">
            <div class="tech-specs_inner">
              <div class="row">
                <div class="col-md-6 col-12">
                  <div class="tech-specs_left">
                    <h3>CONNECTIVITY</h3>
                    <ul>
                      <li>
                        <div class="left_3">
                          <p class="blue-dark">Cellular Network</p>
                        </div>
                        <div class="left_7">
                          <p>CAT M1 /NB1</p>
                        </div>
                      </li>
                      <li>
                        <div class="left_3">
                          <p class="blue-dark">SIM</p>
                        </div>
                        <div class="left_7">
                          <p>Micro / eSIM</p>
                        </div>
                      </li>
                      <li>
                        <div class="left_3">
                          <p class="blue-dark">Antenna</p>
                        </div>
                        <div class="left_7">
                          <p>Internal (700-2700 MHz)</p>
                        </div>
                      </li>
                      <li>
                        <div class="left_3">
                          <p class="blue-dark">Local connectivity</p>
                        </div>
                        <div class="left_7">
                          <p>BLE v5.0</p>
                        </div>
                      </li>
                      <li>
                        <div class="left_3">
                          <p class="blue-dark">Data Transfer</p>
                        </div>
                        <div class="left_7">
                          <p>Secure MQTT</p>
                        </div>
                      </li>
                      <li>
                        <div class="left_3">
                          <p class="blue-dark">Hardware encryption</p>
                        </div>
                        <div class="left_7">
                          <p>ECDH and ECDSA</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="tech-specs_left">
                    <h3>ENCLOSURE</h3>
                    <ul>
                      <li>
                        <div class="left_3">
                          <p class="blue-dark">IP rating </p>
                        </div>
                        <div class="left_7">
                          <p>IP68</p>
                        </div>
                      </li>
                      <li>
                        <div class="left_3">
                          <p class="blue-dark">Material</p>
                        </div>
                        <div class="left_7">
                          <p>ABS </p>
                        </div>
                      </li>
                      <li>
                        <div class="left_3">
                          <p class="blue-dark">Seals</p>
                        </div>
                        <div class="left_7">
                          <p>Butyl Polymers</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                   <div class="tech-specs_left">
                    <h3>POWER</h3>
                    <ul>
                      <li>
                        <div class="left_3">
                          <p class="blue-dark">Battery supply voltage </p>
                        </div>
                        <div class="left_7">
                          <p>3.6 volt</p>
                        </div>
                      </li>
                      <li>
                        <div class="left_3">
                          <p class="blue-dark">Battery capacity</p>
                        </div>
                        <div class="left_7">
                          <p>6.0Ah</p>
                        </div>
                      </li>
                      <li>
                        <div class="left_3">
                          <p class="blue-dark">Battery life</p>
                        </div>
                        <div class="left_7">
                          <p>Upto 10 years</p>
                        </div>
                      </li>
                      <li>
                        <div class="left_3">
                          <p class="blue-dark">Battery Type </p>
                        </div>
                        <div class="left_7">
                          <p>Replaceable single-use lithium</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="tech-specs_right">
                    <h3>SENSORS</h3>
                    <ul>
                      <li>
                        <div class="left_3">
                          <p class="blue-dark"><b>Depth/obstruction</b></p>
                        </div>
                        <div class="left_7">
                          <p><b>Ultrasonic transducer</b></p>
                        </div>
                      </li>
                      <li>
                        <div class="left_3">
                          <p class="blue-dark">Minimum readable depth</p>
                        </div>
                        <div class="left_7">
                          <p>3 cm</p>
                        </div>
                      </li>
                       <li>
                        <div class="left_3">
                          <p class="blue-dark">Maximum readable depth</p>
                        </div>
                        <div class="left_7">
                          <p>450 cm</p>
                        </div>
                      </li>
                       <li>
                        <div class="left_3">
                          <p class="blue-dark"><b>Temperature sensor</b></p>
                        </div>
                        <div class="left_7">
                          <p><b>Digital probe</b></p>
                        </div>
                      </li>
                       <li>
                        <div class="left_3">
                          <p class="blue-dark">Minimum temperature</p>
                        </div>
                        <div class="left_7">
                          <p>-55°C</p>
                        </div>
                      </li>
                       <li>
                        <div class="left_3">
                          <p class="blue-dark">Maximum temperature</p>
                        </div>
                        <div class="left_7">
                          <p>125°C</p>
                        </div>
                      </li>
                      <li>
                        <div class="left_3">
                          <p class="blue-dark">Temperature accuracy</p>
                        </div>
                        <div class="left_7">
                          <p>±0.5°C</p>
                        </div>
                      </li>
                      <li>
                        <div class="left_3">
                          <p class="blue-dark"><b>Humidity sensor</b></p>
                        </div>
                        <div class="left_7">
                          <p><b>Capacitive</b></p>
                        </div>
                      </li>
                      <li>
                        <div class="left_3">
                          <p class="blue-dark">Minimum humidity</p>
                        </div>
                        <div class="left_7">
                          <p>0%RH</p>
                        </div>
                      </li>
                      <li>
                        <div class="left_3">
                          <p class="blue-dark">Maximum humidity</p>
                        </div>
                        <div class="left_7">
                          <p>100%RH</p>
                        </div>
                      </li>
                      <li>
                        <div class="left_3">
                          <p class="blue-dark"><b>Power Sensing</b></p>
                        </div>
                        <div class="left_7">
                          <p><b>Current Transformer</b></p>
                        </div>
                      </li>
                      <li>
                        <div class="left_3">
                          <p class="blue-dark">Dielectric strength</p>
                        </div>
                        <div class="left_7">
                          <p>1000 VAC/min</p>
                        </div>
                      </li>
                      <li>
                        <div class="left_3">
                          <p class="blue-dark">Rated input current</p>
                        </div>
                        <div class="left_7">
                          <p>0-10A</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                 
                </div>
              </div>
            </div>
            <div class="tech-footer">
              <button type="button" class="btn btn-primary" (click)="openDialog1()">Send it to your colleague</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div> -->
<!--====================Work Managment end====================-->
<section id="Demo">
  <section class="contact_us" data-aos="fade-up">
    <div class="container">
      <div class="contact_us_inner">
        <h2 class="blue-dark">Contact us for personalised demo</h2>
        <button type="button" class="btn btn-primary allsolusion" (click)="openDialog()">Request a demo</button>
      </div>
    </div>
  </section>
</section>
