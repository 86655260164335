
<!--====================slider section====================-->
<div class="slider_section bg-video-wrap text-center">
  <video src="assets/video/Senstra-video.mp4" loop muted autoplay></video>
  <div class="slider_home_text">
    <div class="container">
      <div class="slider_innertext">
        <h2 data-aos="fade-up">Sensing to make sense</h2>
        <h3 data-aos="fade-up"></h3>
        <div class="slide-wrap">
          <div class="slide-mask">
            <ul class="slide-group">
              <li class="slide">
                <h3>Connecting the unconnected with I<span style="text-transform: lowercase;">o</span>T to help you succeed</h3>
              </li>
              <li class="slide">
                <h3>Get unprecedented visibility using I<span style="text-transform: lowercase;">o</span>T and data analytics</h3>
              </li>
              <li class="slide">
                <h3>Safeguard and protect your operational costs with senstra I<span style="text-transform: lowercase;">o</span>T solutions</h3>
              </li>
            </ul>
          </div>
          <div class="text-center" data-aos="fade-up">
          <button type="button" class="btn btn-primary" [routerLink]="'/contact-us'" fragment="contactus_offset">Contact us</button>
          <button type="button" class="btn btn-secoundry" (click)="openDialog()">Request a demo</button>
        </div>
          <div class="slide-nav">
            <ul>
              <li class="bullet"></li>
              <li class="bullet"></li>
              <li class="bullet"></li>
            </ul>
          </div>
        </div>
        
      </div>
    </div>
  </div>

  <div class="moving-mouse-holder" >
    <a (click)="toExploreSolusion()">
  <div class="mouse">
    <div class="mouse-button">&nbsp;</div>
  </div>
  <div class="text">Scroll To Explore</div>
</a>
</div>
</div>
<!--====================slider section end====================-->
<div class="clearfix"></div>
<!--====================Explore====================-->
<div id="ExploreSolusion">
<section class="ExploreSolusion" >
  <div class="container">
    <div class="row">
      <div class="col-md-1 col-sm-6 col-12"></div>
      <div class="col-md-5 col-sm-12 col-12">

        <div class="text-left explore-solusion-left aos-init" data-aos="fade-up">
          <p><span class="blue-dark">Up to 80% of the maintenance cost</span> <br> for the assets could benefit from the power of IoT. With over $41.6 B connected devices by 2025; now more than ever it should be a pillar of your operational success and sustainability.</p>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 col-12">
        <div class="text-left explore-solusion-right aos-init" data-aos="fade-up">
          <p>Explore fit-for-purpose IoT based asset monitoring solutions and save millions of dollars on repair spend.</p>
        </div>
      </div>
      <div class="col-md-1 col-sm-6 col-12"></div>
      <div class="col-12 text-center aos-init" data-aos="fade-up">
        <button type="button" class="btn btn-primary width_auto" [routerLink]="'/all-solutions'">Explore our solutions</button>
      </div>
    </div>
  </div>
</section>
</div>
<!--====================Explore====================-->
<section class="Industries">
  <div class="container">
    <div class="row">
      <div class="col-md-1 col-12"></div>
      <div class="col-md-10 col-12">
        <h1 class="gray_color">Industries</h1>
        <div class="row industries-inner">
          <div class="col-md-7 col-12 ml-auto d-flex align-items-center mt-4 mt-md-0">
            <div class="industries-inner left_innner aos-init" data-aos="fade-up">
              <a [routerLink]="'/telco-infrastructure'" >
              <h2 class="blue-dark">Telco &amp; Infrastructure</h2>
              <img src="assets/img/Telco & Infrastructure.jpg" class="mobile_img_desk">
            </a>
              <p class="blue-dark">Proactively maintain your assets before damage to your key physical assets and potential reputational effect </p>
              <p>ePITome allows businesses to monitor and learn about their assets, collecting data and documenting events to enable proactive AI driven maintenance processes.</p>
              <a [routerLink]="'/telco-infrastructure'"  class="blue-light">Read More <img src="assets/img/left-arrow.svg"></a>
            </div>
          </div>
          <div class="col-md-5 col-12">
            <div class="industries_left aos-init" data-aos="fade-up">
              <a [routerLink]="'/telco-infrastructure'" >
                <img src="assets/img/Telco &amp; Infrastructure.jpg" class="img-fluid">
              </a>
            </div>
          </div>
        </div>
        <div class="row industries-inner">
          <div class="col-md-5 col-12">
            <div class="industries_left aos-init" data-aos="fade-up">
              <a [routerLink]="'/utilities-power'">
                <img src="assets/img/Supply-Chain-Logistics-desk.jpg" class="img-fluid">
                <!-- <h2 class="blue-dark">Supply Chain &amp; Logistics</h2> -->
              </a>
            </div>
          </div>
          <div class="col-md-7 col-12 ml-auto d-flex align-items-center mt-4 mt-md-0">
            <div class="industries-inner left_innner aos-init" data-aos="fade-up">
               <a [routerLink]="'/utilities-power'">
              <h2 class="blue-dark">Utilities (Power)</h2>
              <img src="assets/img/Supply-Chain-Logistics-desk.jpg" class="mobile_img_desk">
            </a>
              <p class="blue-dark">Senstra has a range of 4 devices specializing in the power industry. They are able to </p>
              <p>Remote reconnection or disconnection of electricity subscription, Fault Passage detection, Distribution Transformer Monitoring & Management and more.</p>
              <a [routerLink]="'/utilities-power'" class="blue-light">Read More <img src="assets/img/left-arrow.svg"></a>
            </div>
          </div>
        </div>
        <div class="row industries-inner rever_rw m_bottom">
          <div class="col-md-7 col-12 ml-auto d-flex align-items-center mt-4 mt-md-0">
            <div class="industries-inner left_innner aos-init" data-aos="fade-up">
               <a [routerLink]="'/smart-cities'">
              <h2 class="blue-dark traffic-transport">Smart Cities</h2>
              <img src="assets/img/Smart Cities.jpg" class="mobile_img_desk">
            </a>
              <p class="blue-dark">Collect and analyse data to build a more efficient, convenient and better city for the future </p>
              <p>Implementing IoT sensors within structures and across cities will produce more data from diverse datasets. This opens up opportunities such as evaluating structural health in the event of natural disasters or for more accurate evaluations by investors and occupiers.</p>
              <a [routerLink]="'/smart-cities'" class="blue-light">Read More <img src="assets/img/left-arrow.svg"></a>
            </div>
          </div>
          <div class="col-md-5 col-12">
            <div class="industries_left aos-init" data-aos="fade-up">
              <a [routerLink]="'/smart-cities'">
                <img src="assets/img/Smart Cities.jpg" class="img-fluid">
                <!--  <h2 class="blue-dark traffic-transport">Smart Cities</h2> -->
              </a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center aos-init" data-aos="fade-up">
            <button type="button" class="btn btn-secoundry allsolusion" [routerLink]="'/all-solutions'">All solutions</button>
          </div>
        </div>
      </div>
      <div class="col-md-1 col-12"></div>
    </div>
  </div>
</section>
<section class="our-usps">
  <div class="container">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="row Our-usps-box">
          <h1 class="gray_color">Our USPs</h1>
          <div class="col-md-4 col-12 aos-init" data-aos="fade-up">
            <a [routerLink]="'/why-senstra'"  fragment="Narrowdown" class="our-use_outerboxnew">
              <div class="our-inner-box">
                <div class="narrow">
                <img src="assets/img/challenges.svg">

                 </div>
                <h4>Narrow down on your challenges</h4>
              </div>
               <div class="button"><a [routerLink]="'/why-senstra'" fragment="Narrowdown" class="blue-light">Read More <img src="assets/img/left-arrow.svg"></a></div>
            </a>
          </div>
          <div class="col-md-4 col-12 aos-init" data-aos="fade-up">
            <a [routerLink]="'/why-senstra'" fragment="Stayinformed" class="our-use_outerboxnew">
              <div class="our-inner-box">
                 <div class="narrow">
                <img src="assets/img/real-time.svg">
              </div>
                <h4>Stay informed and alert in real-time</h4>
              </div>
               <div class="button"><a [routerLink]="'/why-senstra'" fragment="Stayinformed" class="blue-light">Read More <img src="assets/img/left-arrow.svg"></a></div>
            </a>
          </div>
          <div class="col-md-4 col-12 aos-init" data-aos="fade-up">
            <a [routerLink]="'/why-senstra'" fragment="ReduceMaintenance" class="our-use_outerboxnew">
              <div class="our-inner-box">
                 <div class="narrow">
                <img src="assets/img/costs.svg">
              </div>
                <h4>Reduce maintenance<br> costs</h4>
              </div>
               <div class="button"><a [routerLink]="'/why-senstra'" fragment="ReduceMaintenance" class="blue-light">Read More <img src="assets/img/left-arrow.svg"></a></div>
            </a>
          </div>
          <div class="col-md-4 col-12 aos-init" data-aos="fade-up">
            <a [routerLink]="'/why-senstra'" fragment="Smartdata" class="our-use_outerboxnew">
              <div class="our-inner-box">
                 <div class="narrow">
                <img src="assets/img/insights.svg">
              </div>
                <h4>Smart data for complete insights</h4>
              </div>
               <div class="button"><a [routerLink]="'/why-senstra'" fragment="Smartdata" class="blue-light">Read More <img src="assets/img/left-arrow.svg"></a></div>
            </a>
          </div>
          <div class="col-md-4 col-12 aos-init" data-aos="fade-up">
            <a [routerLink]="'/why-senstra'"  fragment="Stayconnected" class="our-use_outerboxnew">
              <div class="our-inner-box">
                 <div class="narrow">
                <img src="assets/img/connected.svg">
              </div>
                <h4>Stay connected <br> with IoT</h4>
              </div>
               <div class="button"><a [routerLink]="'/why-senstra'" fragment="Stayconnected" class="blue-light">Read More <img src="assets/img/left-arrow.svg"></a></div>
            </a>
          </div>
          <div class="col-md-4 col-12 aos-init" data-aos="fade-up">
            <a [routerLink]="'/why-senstra'" fragment="Developer" class="our-use_outerboxnew">
              <div class="our-inner-box">
                 <div class="narrow">
                <img src="assets/img/Developer friendly.svg">
              </div>
                <h4>Developer friendly <br> architecture</h4>
              </div>
               <div class="button"><a [routerLink]="'/why-senstra'" fragment="Developer"  class="blue-light">Read More <img src="assets/img/left-arrow.svg"></a></div>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <a [routerLink]="'/why-senstra'" class="btn btn-primary allsolusion why-senstraNew">Why senstra?</a>
      </div>
    </div>
  </div>
</section>
<section class="our-services">
  <div class="container">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="row service-box">
          <h1 class="gray_color">Services</h1>
          <div class="col-md-6 col-12">
            <div class="service-inner-box aos-init" data-aos="fade-up">
              <a class="Strategy_img" [routerLink]="'services/'" fragment="Strategy">
              <img src="assets/img/Strategy &amp; Design.svg">
              </a>
              <a [routerLink]="'services/'" fragment="Strategy">
              <h4 class="blue-dark">Strategy &amp; Design</h4></a>
              <p>We work with you to create a technology roadmap that suits your business and design the IoT solution that helps you achieve the strategy.</p>
              <a [routerLink]="'services/'" fragment="Strategy" class="blue-light">Read More <img src="assets/img/left-arrow.svg"></a>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="service-inner-box aos-init" data-aos="fade-up">
               <a class="Strategy_img" [routerLink]="'services/'" fragment="Research">
              <img src="assets/img/Research &amp; Solution.svg">
            </a>
             <a [routerLink]="'services/'" fragment="Research">
              <h4 class="blue-dark">Research &amp; Solution</h4>
            </a>
              <p>We research and develop the solution that is robust in the field, secure in the cloud and scalable for the future.</p>
              <a [routerLink]="'services/'" fragment="Research" class="blue-light">Read More <img src="assets/img/left-arrow.svg"></a>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="service-inner-box aos-init" data-aos="fade-up">
               <a class="Strategy_img" [routerLink]="'services/'" fragment="Cloud">
              <img src="assets/img/Cloud Deployment.svg"></a>
               <a [routerLink]="'services/'" fragment="Cloud">
              <h4 class="blue-dark">Cloud Deployment</h4></a>
              <p>We create and manage the solution whether it be on premise or in the cloud (AWS/Azure). We aim to build a custom, secure and robust way for your organization to ingest the data.</p>
              <a [routerLink]="'services/'" fragment="Cloud" class="blue-light">Read More <img src="assets/img/left-arrow.svg"></a>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="service-inner-box aos-init" data-aos="fade-up">
               <a class="Strategy_img DataAnalytics_icon" [routerLink]="'services/'" fragment="DataAnalytics">
              <img src="assets/img/Data Analytics &amp; Science.svg"></a>
               <a [routerLink]="'services/'" fragment="DataAnalytics">
              <h4 class="blue-dark">Data Analytics &amp; Science</h4></a>
              <p>We help you ingest, analyse and report on the collected telemetry data and provide insightful analysis using predictive intelligence and data science to drive business and ensure quick ROI.</p>
              <a [routerLink]="'services/'" fragment="DataAnalytics" class="blue-light">Read More <img src="assets/img/left-arrow.svg"></a>
            </div>
          </div>
         
      <div class="col-12 text-center aos-init" data-aos="zoom-in">
        <a [routerLink]="'/services'" class="btn btn-secoundry allsolusion">Explore all services</a>
      </div>
    
        </div>
        <div class="col-md-11">
        <div class="slider_bottom" style="text-align:center">
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
              <div class="blue_bg">
                <div class="row no-gutters">
                  <div class="col-md-4 col-12 ml-auto d-flex align-items-center mt-4 mt-md-0">
                    <div class="img_center_aps">
                      <img src="assets/img/APN-Logo_large.png">
                    </div>
                  </div>
                  <div class="col-md-8 col-12 ml-auto d-flex align-items-center mt-4 mt-md-0">
                    <div class="testinomial_div">
                      <h4>AWS PARTNER</h4>
                      <p>We’re Amazon Web Services (AWS) IoT partners!</p>
                      <p> Whilst we do provide solutions in both Azure and Amazon Web Services (AWS), we provide solutions in AWS to help integrate our platform into our client’s established AWS ecosystem.</p>
                      <p>We are also utilising AWS and Azure powered firmware on our IoT devices. This ensures our solution provides secure data and device connectivity to the cloud.</p>
                      <div class="numbers">
                        1
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="blue_bg">
                <div class="row no-gutters">
                  <div class="col-md-4 col-12 ml-auto d-flex align-items-center mt-4 mt-md-0">
                    <div class="img_center_aps">
                      <img src="assets/img/NSW-logo.png">
                    </div>
                  </div>
                  <div class="col-md-8 col-12 ml-auto d-flex align-items-center">
                    <div class="testinomial_div">
                      <h4>NSW FUNDING</h4>
                      <p>To secure this grant our solution was competitively assessed against stringent evaluation criteria. The innovation grant affirms the authenticity of the scalable technology solutions Senstra offers and our ambition to make the world better connected. Our B2B solution addresses high growth opportunities and resolves challenges faced by multiple industries in Australia and around the world. The approval by NSW Govt. validates the technical uniqueness of our solutions.</p>
                      <div class="numbers">
                        2
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
               <div class="blue_bg">
                <div class="row no-gutters">
                  <div class="col-md-4 col-12 ml-auto d-flex align-items-center mt-4 mt-md-0">
                    <div class="img_center_aps">
                      <img src="assets/img/logo-ublox.png">
                    </div>
                  </div>
                  <div class="col-md-8 col-12 ml-auto d-flex align-items-center">
                    <div class="testinomial_div">
                      <h4>u-Blox</h4>
                      <p>Senstra and U-blox have partnered up to create state of the art IoT technologies that bring asset management and operational excellence together for Telecommunications, Environment and Transportation industries.
                      </p>
                      <p> Their mission furthers our mission. U-blox best in class modems, helps us connect our device in challenging environments. Senstra’s Integrated approach to asset management produces up to 80% on operating savings and enables customers to move from reactive maintenance to preventive and predictive maintenance via smart data driven insights.</p>
                      <div class="numbers">
                        3
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
        </div>
        
        <div class="button_bottom">
          <button type="button" class="btn btn-primary">Success stories</button>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>
  </div>
</section>
<!--====================Work Managment====================-->
<!-- <section class="Work-Managment text-center aos-init" data-aos="fade-up">
  <div id="canvas_1"></div>
  <div class="container">
    <h2 class="blue-dark">Workforce Management</h2>
    <p class="blue-dark">Connecting you and your workforce</p>
    <p>Our ePITome device is outfitted with logging and sensing technology to allow operators to monitor the location of the technician to stay informed about their well-being and provide further detail for billing reconciliation. Our sensors also provide more data and insights to the workforce in the field when used with our iOS and Android apps.</p>
    <a [routerLink]="'workforce-management/'"  class="btn btn-secoundry allsolusion">Explore more</a>
  </div>
</section> -->
<!--====================Work Managment end====================-->
<!--====================Work Managment====================-->
<section class="Our-Insights mar_top">
  <div class="container">
    <h1 class="gray_color">Our <br>Insights</h1>
    <div class="row blog_inner mar_res">
      <div class="col-md-6 col-12 aos-init" data-aos="fade-up">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="blog_left">
              <p class="blog_itelic">Blog</p>
              <a [routerLink]="'/telcos'"  target="_blank">
              <h5 class="blue-dark">How Is IoT The Most Promising Innovation For Telcos</h5></a>
              <a [routerLink]="'/telcos'"  target="_blank">
              <img src="assets/img/Resources_blog_1.png" alt="img_1">
            </a>
              <p class="blog_p">What do you imagine when you think about the future of the Australian Telecommunication Industry? If you are someone from the Telecommunication business, then surely you have got your...</p>
              <a [routerLink]="'/telcos'"  target="_blank" class="blue-light">Read More <img src="assets/img/left-arrow.svg"></a>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="blog_img">
              <a [routerLink]="'/telcos'" target="_blank">
              <img src="assets/img/img_1.jpg" alt="img_1">
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12 aos-init" data-aos="fade-up">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="blog_left">
              <p class="blog_itelic">Article</p>
              <a [routerLink]="'/exclusive-signs'" target="_blank">
              <h5 class="blue-dark">4 Exclusive Signs Of Poor Asset Monitoring &amp; Soaring Need Of The Latest IoT Upgrade</h5></a>
               <a [routerLink]="'/exclusive-signs'" target="_blank">
              <img src="assets/img/Resources_blog_2.png" alt="img_1">
               </a>
              <p class="blog_p">What is the pioneering characteristic of an effective asset monitoring system? In a worldwide survey conducted by IBM, 75% of asset managers cited system reliability as the single...</p>
              <a [routerLink]="'/exclusive-signs'" target="_blank" class="blue-light">Read More <img src="assets/img/left-arrow.svg"></a>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="blog_img">
              <a [routerLink]="'/exclusive-signs'" target="_blank" >
              <img src="assets/img/img_2.jpg" alt="img_1">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row blog_inner">
      <div class="col-md-6 col-12 aos-init" data-aos="fade-up">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="blog_left">
              <p class="blog_itelic">Blog</p>
              <a [routerLink]="'/why-iot-startups'" target="_blank">
              <h5 class="blue-dark">Why IoT Startups Are More Innovative, Affordable, Competitive</h5></a>
              <a [routerLink]="'/why-iot-startups'" target="_blank">
              <img src="assets/img/Resources_blog_3.png" alt="img_1">
              </a>
              <p class="blog_p">The Australian Startups ecosystem has been emerging with full throttle delivering innovative services by redefining the status quo. When we talk about startups the first thing that pops into our minds is...</p>
              <a [routerLink]="'/why-iot-startups'" target="_blank" class="blue-light">Read More <img src="assets/img/left-arrow.svg"></a>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="blog_img">
               <a [routerLink]="'/why-iot-startups'" target="_blank">
              <img src="assets/img/img_3.jpg" alt="img_1">
            </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12 aos-init" data-aos="fade-up">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="blog_left">
              <p class="blog_itelic">Report</p>
              <a [routerLink]="'/report'" target="_blank">
              <h5 class="blue-dark">Senstra’s Annual Market Scan 2020</h5></a>
               <a [routerLink]="'/report'" target="_blank"><img src="assets/img/Resources_blog_4.jpg" alt="img_1"></a>
              <p class="blog_p">The dawn of the fourth Industrial Revolution or Industry 4.0 has made data the new oil with the
                introduction of a number of data-driven technologies like Cloud, Big Data, AR/VR, AI, IoT, etc.
              </p>
              <a [routerLink]="'/report'" target="_blank" class="blue-light">Read More <img src="assets/img/left-arrow.svg"></a>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="blog_img">
              <a [routerLink]="'/report'" target="_blank">
                 <img src="assets/img/img_4.jpg" alt="img_1">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center aos-init" data-aos="fade-up">
        <button type="button" class="btn btn-primary allsolusion" [routerLink]="'/resources'">See all</button>
      </div>
    </div>
  </div>
</section>
<!--====================Work Managment end====================-->
