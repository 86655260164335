<!--====================slider section====================-->
<section class="slider_section text-left all-use-case Traffic_banner" id="introduction">
    <div class="container">
        <div class="slider_innertext ">
            <h2 data-aos="fade-up" class="Intelligent-text">Intelligent Traffic and Transportation Management</h2>
            <p data-aos="fade-up" class="Intelligent-text_p">The Internet of Things (IoT) enables the transport industry by transforming how transportation systems gather and make use of data. Senstra solutions empowers people and objects in the transportation system, helping them make informed and automated decisions to improve traffic flows.</p>
            <div class="text-left" data-aos="fade-up">
                <button type="button" class="btn btn-primary" [routerLink]="'/contact-us'" fragment="contactus_offset" >Drop a query</button>
                <button type="button" class="btn btn-secoundry" (click)="openDialog()">Request a demo</button>
            </div>
        </div>
    </div>
     <div class="moving-mouse-holder" >
    <a (click)="toRevolutionise()">
  <div class="mouse">
    <div class="mouse-button">&nbsp;</div>
  </div>
  <div class="text">Scroll To Explore</div>
</a>
</div>
</section>
<!--====================slider section end====================-->
<div class="clearfix"></div>
 <section class="about_menu" id="mainNav">
        <div class="container">
            <div class="nav_center">
            <ul class="nav bottom_nav">    
              <li class="nav-item"><a (click)="toUseCases()"  class="js-scroll-trigger">Use Cases</a></li>
              <li class="nav-item"><a class="js-scroll-trigger" (click)="toResources()"> Resources</a></li>
              <li class="nav-item"><a class="js-scroll-trigger" (click)="toDemo()">  Demo</a></li>
             
           </ul>
       </div>
        </div>
    </section>
    <div id="Revolutionise">
<section class="Revolutionise-your-business text-center">
    <div class="container">
        <h3 class="blue-dark" data-aos="fade-up">Build value to the Transport Ecosystem with IoT</h3>
        <p data-aos="fade-up">Senstra IoT solutions help the transportation sector improve outcomes for cities, transport providers, and commuters. Across the transport ecosystem, our customised IoT solutions can create new efficiencies, reduce up to 80% maintenance cost and improve outcomes for all stakeholders.</p>
    </div>
</section>
</div>
<div class="clearfix"></div>
<section class="Use-Cases" id="UseCases">
    <div class="container">
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <h1 class="gray_color" data-aos="fade-left">Use Cases </h1>
                <div class="row use-case-top_div m-t-0" id="Temperature" data-aos="fade-up">
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-left">
                            <h3 class="blue-dark"> Water Level</h3>
                            <img src="assets/img/Realtime-Water-Level-Indication.jpg" alt="Realtime-Water-Level-Indication">
                            <p>Knowing the flooding activity near the most important asset is important for correct maintenance steps as well as audit. Our ultrasonic sensors can detect water level rising up and down to trigger alerts in a timely manner.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-right">
                            <img src="assets/img/Realtime-Water-Level-Indication.jpg" alt="Realtime-Water-Level-Indication">
                        </div>
                    </div>
                </div>
                <div class="row use-case-top_div" id="Intrusion-detection" data-aos="fade-up">
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-left">
                            <h3 class="blue-dark">Intrusion detection</h3>
                             <img src="assets/img/Intrusion-detection.jpg" alt="Intrusion-detection">
                            <p>Detect intrusion into restricted areas and protect critical assets.</p>
                            <ul>
                                <li>Real-Time intrusion detection allowing you to act instantly and protect critical assets.</li>
                                <li>Device will wake and provide real time alerts when an intrusion is detected.</li>
                                <li>Real time alerts can be customised for the channel that suits you whether it be email, sms or system alerts.</li>
                                <li> Rest-API access for further data analysis.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-right">
                            <img src="assets/img/Intrusion-detection.jpg" alt="Intrusion-detection">
                        </div>
                    </div>
                </div>
                
                <div class="row use-case-top_div" id="Light" data-aos="fade-up">
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-left">
                            <h3 class="blue-dark">Asset access audit and Technician assistance</h3>
                            <img src="assets/img/Asset-access-audit-and-Technician-assistance.jpg" alt="Asset-access-audit-and-Technician-assistance">
                            <p>Our Android/IOS app alongside our IoT products and cloud solution offerings allows technicians in-field to see realtime readings and record the work they've conducted and allow you to track your technicians time and progress.</p>
                            <ul>
                                <li>Custom built Android/IOS Application suitable for businesses and their workforce.</li>
                                <li>App displays historical and real time telemetry and work data for the user.</li>
                                <li> Provides transparency to what technicians are doing in-field. </li>
                                <li> Notifications generated based on technician's work.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-right">
                            <img src="assets/img/Asset-access-audit-and-Technician-assistance.jpg" alt="Asset-access-audit-and-Technician-assistance">
                        </div>
                    </div>
                </div>
                <div class="row use-case-top_div" id="Power-Detection" data-aos="fade-up">
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-left">
                            <h3 class="blue-dark"> Power Detection</h3>
                             <img src="assets/img/Power-Detection.jpg" alt="Power-Detection">
                            <p>With planned and unplanned power outages bringing down the most valuable assets and thus depriving thousands of customers from using the network; our sensor can detect power failures leading to:</p>
                            <ul>
                              <li>Accurate problem identification and making third parties accountable</li>
                              <li>Reduce operation costs</li>
                              <li>Improving customer experience</li>
                              <li>Accurately calculate KPIs to account for these uncontrollable environmental factors</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 col-12">
                        <div class="use-case-right">
                            <img src="assets/img/Power-Detection.jpg" alt="Power-Detection">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
    </div>
</section>
<!--====================Our-Insights====================-->
<section class="Our-Insights resources_div" >
    <div class="container" id="Resources">
        <h1 class="gray_color">Our <br> Insights</h1>
        <div class="row blog_inner mar_res">
            <!--  <div class="col-md-6 col-12" data-aos="fade-up">
                        <div class="row">
                            <div class="col-md-6 col-12">
                                <div class="blog_left" >
                                    <p class="blog_itelic">Blog</p>
                                    <h5 class="blue-dark">How Is IoT The Most Promising Innovation For Telcos</h5>
                                     <img src="img/img_1.jpg" alt="img_1">
                                    <p class="blog_p">What do you imagine when you think about the future of the Australian Telecommunication Industry? If you are someone from the Telecommunication business, then surely you have got your thoughts rolling towards the $51 billion nbn HFC project and how it will turn out..</p>
                                    <a href="" class="blue-light">Read More <img src="img/left-arrow.svg"></a>
                                </div>
                            </div>
                            <div class="col-md-6 col-12">
                                <div class="blog_img">
                                    <img src="img/img_1.jpg" alt="img_1">
                                </div>
                            </div>
                        </div>
                    </div> -->
             <div class="col-md-6 col-12 aos-init" data-aos="fade-up">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="blog_left">
              <p class="blog_itelic">Article</p>
              <a [routerLink]="'/exclusive-signs'" target="_blank">
                <h5 class="blue-dark">4 Exclusive Signs Of Poor Asset Monitoring &amp; Soaring Need Of The Latest IoT Upgrade</h5>
              </a>
              <a [routerLink]="'/exclusive-signs'" target="_blank">
                <img src="assets/img/Resources_blog_2.png" alt="img_1">
              </a>
              <p class="blog_p">What is the pioneering characteristic of an effective asset monitoring system? In a worldwide survey conducted by IBM, 75% of asset managers cited system reliability as the single...</p>
              <a [routerLink]="'/exclusive-signs'" target="_blank" class="blue-light">Read More <img src="assets/img/left-arrow.svg"></a>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="blog_img">
              <a [routerLink]="'/exclusive-signs'" target="_blank">
                <img src="assets/img/img_2.jpg" alt="img_1">
              </a>
            </div>
          </div>
        </div>
      </div>
            <div class="col-md-6 col-12 aos-init" data-aos="fade-up">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="blog_left">
              <p class="blog_itelic">Blog</p>
              <a [routerLink]="'/why-iot-startups'" target="_blank">
                <h5 class="blue-dark">Why IoT Startups Are More Innovative, Affordable, Competitive</h5>
              </a>
              <a [routerLink]="'/why-iot-startups'" target="_blank">
                <img src="assets/img/Resources_blog_3.png" alt="img_1">
              </a>
              <p class="blog_p">The Australian Startups ecosystem has been emerging with full throttle delivering innovative services by redefining the status quo. When we talk about startups the first thing that pops into our minds is... </p>
              <a [routerLink]="'/why-iot-startups'" target="_blank" class="blue-light">Read More <img src="assets/img/left-arrow.svg"></a>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="blog_img">
              <a [routerLink]="'/why-iot-startups'" target="_blank">
                <img src="assets/img/img_3.jpg" alt="img_1">
              </a>
            </div>
          </div>
        </div>
      </div>
        </div>
        <div class="row blog_inner">
           <div class="col-md-6 col-12" data-aos="fade-up">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="blog_left">
              <p class="blog_itelic">Report</p>
              <a [routerLink]="'/report'" target="_blank">
                <h5 class="blue-dark">Senstra's Annual Market Scan 2020</h5>
              </a>
              <a [routerLink]="'/report'" target="_blank"><img src="assets/img/Resources_blog_4.jpg" alt="img_1"></a>
              <p class="blog_p">The dawn of the fourth Industrial Revolution or Industry 4.0 has made data the new oil with the introduction of a number of data-driven technologies like Cloud, Big Data, AR/VR, AI, IoT, etc.</p>
              <a [routerLink]="'/report'" target="_blank" class="blue-light">Read More <img src="assets/img/left-arrow.svg"></a>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="blog_img">
              <a [routerLink]="'/report'" target="_blank">
                <img src="assets/img/img_4.jpg" alt="img_1">
              </a>
            </div>
          </div>
        </div>
      </div>
        </div>
        <div class="row">
            <div class="col-12 text-center" data-aos="fade-up">
                <button type="button" class="btn btn-secoundry allsolusion" [routerLink]="'/resources'">See all</button>
            </div>
        </div>
    </div>
</section>
<!--====================Work Managment end====================-->
<section id="Demo">
<section class="contact_us" data-aos="fade-up">
    <div class="container">
        <div class="contact_us_inner">
            <h2 class="blue-dark">Contact us for personalised demo</h2>
            <button type="button" class="btn btn-primary allsolusion" (click)="openDialog()">Request a demo</button>
        </div>
    </div>
</section>
</section>