
<!--====================slider section====================-->
<section class="slider_section text-left all-use-case Why-Senstra_banner" id="introduction">
 <div class="container">
    <div class="slider_innertext ">
      <h2 data-aos="fade-up">Why Senstra</h2>
      <p data-aos="fade-up" class="Intelligent-text_p">We understand all your business needs and provide fit-for-purpose business specific IoT solutions. Our team understands that no product can be fit for all use cases and hence we design products keeping your use cases in mind and ensure successful integration of IoT in your business ecosystem. Our expertise in data analysis and data science sets us apart. We ensure that our clients not only collect the correct data but also analyse and use it for their organisation.</p>
      <div class="text-left" data-aos="fade-up">
        <button type="button" class="btn btn-primary" [routerLink]="'/contact-us'" fragment="contactus_offset"  >Drop a query</button>
        <button type="button" class="btn btn-secoundry" (click)="openDialog()">Request a demo</button>
      </div>
    </div>
  </div>
   <div class="moving-mouse-holder" >
    <a (click)="toRevolutionise()">
  <div class="mouse">
    <div class="mouse-button">&nbsp;</div>
  </div>
  <div class="text">Scroll To Explore</div>
</a>
</div>
</section>
<!--====================slider section end====================-->
<div class="clearfix"></div>
<section class="about_menu" id="mainNav">
  <div class="container">
    <div class="nav_center">
      <ul class="nav bottom_nav" >
      
        <li class="nav-item"><a (click)="toOurUsps()" class="js-scroll-trigger"> Our USPs </a></li>
      </ul>
    </div>
  </div>
</section>
<div id="Revolutionise">
<section class="Revolutionise-your-business text-center">
  <div class="container">
    <h3 class="blue-dark" data-aos="fade-up">Why Senstra</h3>
    <p data-aos="fade-up">Senstra provides highly customized, battery powered, NB-IoT network enabled devices that can incorporate sensors in a-la-carte model. Each sensor is customizable in terms of its data sending frequency, alert threshold and REST API with a variety of variables to not only display in our dashboard but also to ingest data into your own platform.</p>
  </div>
</section>
</div>
<div class="clearfix"></div>
<section class="Use-Cases" id="OurUsps">
  <div class="container">
    <div class="row">
      <div class="col-md-1 col-12"></div>
      <div class="col-md-10 col-12">
        <h1 class="gray_color" data-aos="fade-left">Our USPs</h1>
        <div id="Narrowdown">
        <div class="row use-case-top_div m-t-0"data-aos="fade-up">
          <div class="col-md-6 col-sm-12 col-12">
            <div class="use-case-left">
              <h3 class="blue-dark"> Narrow down on your challenges</h3>
               <img src="assets/img/Narrow down.jpg" alt="Strategy & Design">
              <p>With the leadership team's background as management consultants; we focus heavily on understanding the challenges of the business in ever-changing environments. We consult with you to narrow down the challenges before jumping into solution mode.</p>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 col-12">
            <div class="use-case-right">
              <img src="assets/img/Narrow down.jpg" alt="Strategy & Design">
            </div>
          </div>
        </div>
      </div>
      <div id="Stayinformed">
        <div class="row use-case-top_div"  data-aos="fade-up">
          <div class="col-md-6 col-sm-12 col-12">
            <div class="use-case-left">
              <h3 class="blue-dark">Stay informed and alert</h3>
                <img src="assets/img/Stay informed.jpg" alt="Group 2288">
              <p>Our end-to-end solution realises criticality of timeliness. We allow you to set customised rule engine for your assets which can trigger the alert and inform you via SMS, email, or a dashboard notification.
              </p>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 col-12">
            <div class="use-case-right">
              <img src="assets/img/Stay informed.jpg" alt="Group 2288">
            </div>
          </div>
        </div>
      </div>
      <div id="ReduceMaintenance">
        <div class="row use-case-top_div"  data-aos="fade-up">
          <div class="col-md-6 col-sm-12 col-12">
            <div class="use-case-left">
              <h3 class="blue-dark">Reduce maintenance costs</h3>
               <img src="assets/img/Reduce maintenance.jpg" alt="Reduce maintenance">
              <p>With our experience in various maintenance programs; we have tailored our firmware of the device, AWS architecture, and dashboard to drive down maintenance costs of the assets.</p>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 col-12">
            <div class="use-case-right">
              <img src="assets/img/Reduce maintenance.jpg" alt="Reduce maintenance">
            </div>
          </div>
        </div>
      </div>
      <div id="Smartdata">
        <div class="row use-case-top_div"  data-aos="fade-up">
          <div class="col-md-6 col-sm-12 col-12">
            <div class="use-case-left">
              <h3 class="blue-dark">Smart data for complete insights</h3>
               <img src="assets/img/Smart data.jpg" alt="Smart data">
              <p>Most IoT companies focus on the devices and what it can do. While working with a lot of organizations; we have realised that focusing on business decisions and actionable insights are what brings short and long term success. We work with you to complete the missing data so that you can get actionable insights from your assets to drive informed business decisions.</p>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 col-12">
            <div class="use-case-right">
              <img src="assets/img/Smart data.jpg" alt="Smart data">
            </div>
          </div>
        </div>
      </div>
      <div id="Stayconnected">
        <div class="row use-case-top_div"  data-aos="fade-up">
          <div class="col-md-6 col-sm-12 col-12">
            <div class="use-case-left">
              <h3 class="blue-dark">Stay connected with IoT</h3>
               <img src="assets/img/Stay connected.jpg" alt="GStay connected">
              <p>We focus on your biggest business problems and help you connect the currently unconnected asset and bring it all together in a dashboard.</p>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 col-12">
            <div class="use-case-right">
              <img src="assets/img/Stay connected.jpg" alt="GStay connected">
            </div>
          </div>
        </div>
      </div>
      <div id="Developer">
        <div class="row use-case-top_div" data-aos="fade-up">
          <div class="col-md-6 col-sm-12 col-12">
            <div class="use-case-left">
              <h3 class="blue-dark">Developer friendly architecture</h3>
               <img src="assets/img/Developer friendly.jpg" alt="Group 2289">
              <p>Our architecture focuses heavily on how you and your developers can integrate seamlessly with our platform. Using separate REST API call for each of the sensor data point allows you to ingest data easily and helps you integrate with your existing dashboard or platform.</p>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 col-12">
            <div class="use-case-right">
              <img src="assets/img/Developer friendly.jpg" alt="Group 2289">
            </div>
          </div>
        </div>
      </div>
      </div>
      <div class="col-md-1 col-12"></div>
    </div>
  </div>
</section>

