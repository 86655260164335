import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { RequestDemo } from '../../../classes/request-demo';
import { RequestDemoService } from '../../../services/request-demo.service';

interface DialogData {
  email: string;
}

@Component({
  selector: 'app-request-demo',
  templateUrl: './request-demo.component.html',
  styleUrls: ['./request-demo.component.css']
})
export class RequestDemoComponent implements OnInit {

  model = new RequestDemo();
  submitted = false;
  error: {};
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{9,10}$";

  constructor(
    private cmspageService: RequestDemoService,
    public dialogRef: MatDialogRef<RequestDemoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {} 

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.submitted = true;
    return this.cmspageService.contactForm(this.model).subscribe(
      data => this.model = data,
      error => this.error = error
    );
  }

  ngOnInit(): void {
  }

}
