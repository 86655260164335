<section class="slider_section text-left all-use-case Contact_us">
   <div class="moving-mouse-holder" >
    <a (click)="tocontactusoffset()">
  <div class="mouse">
    <div class="mouse-button">&nbsp;</div>
  </div>
  <div class="text">Scroll To Explore</div>
</a>
</div>
</section>
<!--====================banner section end====================-->
<!--====================contact form section end====================-->
<div class="clearfix"></div>
<div id="contactusoffset">
<section class="contact_inner" id="contactus_offset">
    <div class="container">
        <div class="row">
            <div class="col-md-1 col-12">
            </div>
            <div class="col-md-10 col-12">
                <div class="Send_Now">
                    <h1 class="gray_color" data-aos="fade-left">Contact Us</h1>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="send_now_left">
                                <div class="address">
                                  <h3>Office</h3>
                                  <p>23 Atchison St, St Leonards, NSW, 2065, Australia</p>
                                </div>
                                 <div class="address">
                                  <h3>Email</h3>
                                  <p><a routerLink=".">iot@senstra.com.au</a></p>
                                </div>
                                 <div class="address">
                                  <h3>Contact</h3>
                                  <p><a routerLink=".">+61 2 8011 3975</a></p>
                                </div>
                                 <div class="address">
                                  <h3>Linkedin</h3>
                                  <p><a  href="https://www.linkedin.com/company/senstraiot" target="_blank">company/SenstraloT</a></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="send_right">
                                <div class="sp5_right">
                                    <h3 class="blue-dark">GET IN TOUCH</h3>
                                    
                                      <form (ngSubmit)="onSubmit()" #contactForm="ngForm">
                                        <div class="row" [hidden]="submitted">
                                        <div class="col-md-6 col-12">
                                            <div class="form-group">
                                              <label>Name*</label>
                                              <input type="text" name="name" id="name" [(ngModel)]="model.name" class="form-control" required #name="ngModel" placeholder="Your Name">
                                              <div *ngIf="name.invalid && (name.dirty || name.touched)" class="error" >
                                                <div *ngIf="name.errors.required">
                                                  Name is required.
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-12">
                                          <div class="form-group">
                                            <label>Email*</label>
                                            <input type="text" name="email" id="email" [(ngModel)]="model.email" class="form-control" required email #email="ngModel" placeholder="Email Address">
                                            <div *ngIf="email.invalid && (email.dirty || email.touched)" class="error">
                                              <div *ngIf="email.errors.required">Email is required.</div>
                                              <div *ngIf="email.errors.email">Email must be a valid email address.</div>
                                            </div>
                                          </div>
                                        </div>
                                         <div class="col-md-6 col-12">
                                          <div class="form-group">
                                            <label>Mobile*</label>
                                            <input type="text" name="phone" id="phone" [(ngModel)]="model.phone" [pattern]="mobNumberPattern" class="form-control" required #phone="ngModel" placeholder="Your Mobile No.">
                                            <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="error">
                                            <div *ngIf="phone.errors.required">Mobile is required.</div>
                                            <div *ngIf="phone.errors.pattern">Mobile not a valid number.</div> 
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-md-6 col-12">
                                          <div class="form-group">
                                            <label>Subject*</label>
                                            <input type="text" name="subject" id="subject" [(ngModel)]="model.subject" class="form-control" required #subject="ngModel" placeholder="Your Subject">
                                            <div *ngIf="subject.invalid && (subject.dirty || subject.touched)" class="error">
                                            <div *ngIf="subject.errors.required">Subject is required.</div>
                                            </div>
                                          </div>
                                        </div>
                                       
                                        <div class="col-md-12 col-12">
                                          <div class="form-group">
                                            <label>Message*</label>
                                            <textarea name="message" id="message" [(ngModel)]="model.message" rows="5" class="form-control" placeholder="Start Typing Here" required #message="ngModel"></textarea>
                                            <div *ngIf="message.invalid && (message.dirty || message.touched)" class="error">
                                              <div *ngIf="message.errors.required">Message is required.</div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-12">
                                            <button [disabled]="!contactForm.form.valid" type="submit" class="btn btn-secondary SUbmit_btn">Submit</button>
                                        </div>                                     
                                    </div>
                                    </form>
                                </div>
                                <div class="service-error" *ngIf="error">
                                  <h1>{{error.errorTitle}}</h1>
                                  <h3>{{error.errorDesc}}</h3>
                                </div>
                                <div [hidden]="!submitted" class="contact-message">
                                  <div *ngIf="!error" class="contact-success">
                                    <h4> Hi! Thanks for dropping your query. One of our specialised consultants will be in touch with you very soon.</h4>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-1 col-12">
            </div>
        </div>
    </div>
</section>
</div>

