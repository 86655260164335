


<div id="feedback" class="desk_view" *ngIf="isShow"><a  download="Senstra_Brochure.pdf" href="assets/Brochure/Senstra_Brochure.pdf"  class="btn btn-primary">Download brochure</a></div>
<!--====================slider section====================-->
<section class="slider_section text-left all-use-case Resources_banner">
   <div class="container">
    <div class="slider_innertext ">
      <h2 data-aos="fade-up">Resources</h2>
      <p data-aos="fade-up" class="Intelligent-text_p">
The fundamental capabilities of IoT are excellent, keeping in mind that IoT could be worth $116 billion in Australia’s economy by the year 2025. Our resources are focused to help you gauge how you can leverage IoT to create positive strains across your business spectrum and increase your toplines profits.</p>
      <div class="text-left" data-aos="fade-up">
       <button type="button" class="btn btn-primary" [routerLink]="'/contact-us'" fragment="contactus_offset"  >Drop a query</button>
        <button type="button" class="btn btn-secoundry" (click)="openDialog()">Request a demo</button>
      </div>
    </div>
  </div>
  <div class="moving-mouse-holder" >
    <a (click)="toRevolutionise()">
  <div class="mouse">
    <div class="mouse-button">&nbsp;</div>
  </div>
  <div class="text">Scroll To Explore</div>
</a>
</div>
</section>
<!--====================slider section end====================-->
<div class="clearfix"></div>
<section class="Revolutionise-your-business text-center" id="Revolutionise">
    <div class="container">
        <h3 class="blue-dark" data-aos="fade-up">Every resource you need to sense the IoT era for your business</h3>
       <!--  <p class="blue-dark" data-aos="fade-up"><i> Every resource you need to sense the IoT era for your business</i></p> -->
        <p data-aos="fade-up">Explore extensively researched and insightful resources to keep up-to-date with the latest industry trends and IoT updates.</p>
        <div class="Download_brochure">
        
       </div>
    </div>
</section>
<!--====================Our-Resources====================-->
<section class="Our-Resources Our-Insights">
    <div class="container">
        <div class="row">
            <div class="col-md-1 col-12"></div>
            <div class="col-md-10 col-12">
               <h1 class="gray_color">Our <br> Insights</h1>
                <div class="row">
                   
                    <div class="col-md-6 col-12">
                        <div class="blog_left_details" data-aos="fade-up">
                            <a [routerLink]="'/telcos'" target="_blank" class="desk__respurce">
                            <img src="assets/img/Resources_blog_1.png" alt="Resources_blog_1">
                            </a>
                            <p class="blog_itelic">Blog</p>
                            <a [routerLink]="'/telcos'" target="_blank">
                            <h5 class="blue-dark">How Is IoT The Most Promising Innovation For Telcos</h5>
                            </a>
                             <a [routerLink]="'/telcos'" target="_blank" class="mobie_respurce">
                            <img src="assets/img/Resources_blog_1.png" alt="Resources_blog_1">
                            </a>
                            <p class="blog_p">What do you imagine when you think about the future of the Australian Telecommunication Industry? If you are someone from the Telecommunication business, then surely you have got your thoughts rolling towards the $51 billion nbn HFC project and how it will turn out.</p>
                            <a [routerLink]="'/telcos'" target="_blank" class="blue-light">Read More <img src="assets/img/left-arrow.svg"></a>
                        </div> 
                    </div>
                    <div class="col-md-6 col-12">
                        <div class="blog_left_details" data-aos="fade-up">
                            <a [routerLink]="'/exclusive-signs'" target="_blank" class="desk__respurce">
                            <img src="assets/img/Resources_blog_2.png" alt="Resources_blog_2">
                        </a>
                            <p class="blog_itelic">Article</p>
                            <a [routerLink]="'/exclusive-signs'" target="_blank">
                            <h5 class="blue-dark">4 Exclusive Signs Of Poor Asset Monitoring & Soaring Need Of The Latest IoT Upgrade</h5>
                        </a>
                        <a [routerLink]="'/exclusive-signs'" target="_blank" class="mobie_respurce">
                            <img src="assets/img/Resources_blog_2.png" alt="Resources_blog_2">
                        </a>
                            <p class="blog_p">What is the pioneering characteristic of an effective asset monitoring system? In a worldwide survey conducted by IBM, 75% of asset managers cited system reliability as the single most fundamental reason to invest in Enterprise Asset Management (EAM)</p>
                            <a [routerLink]="'/exclusive-signs'" target="_blank" class="blue-light">Read More <img src="assets/img/left-arrow.svg"></a>
                        </div>
                    </div>
                    <div class="col-md-6 col-12">
                        <div class="blog_left_details" data-aos="fade-up">
                            <a [routerLink]="'/why-iot-startups'" target="_blank" class="desk__respurce">
                            <img src="assets/img/Resources_blog_3.png" alt="Resources_blog_2">
                        </a>
                            <p class="blog_itelic">Blog</p>
                            <a  [routerLink]="'/why-iot-startups'" target="_blank">
                            <h5 class="blue-dark">Why IoT Startups Are More Innovative, Affordable, Competitive</h5></a>
                             <a [routerLink]="'/why-iot-startups'" target="_blank" class="mobie_respurce">
                            <img src="assets/img/Resources_blog_3.png" alt="Resources_blog_2">
                        </a>
                            <p class="blog_p">The Australian Startups ecosystem has been emerging with full throttle delivering innovative services by redefining the status quo. When we talk about startups, the first thing that pops into our minds is their agility, adrenalised vigour, fast development cycles, and 360-degree flexibility in terms of time, product customisations, costs, and much more</p>
                            <a  [routerLink]="'/why-iot-startups'" target="_blank" class="blue-light">Read More <img src="assets/img/left-arrow.svg"></a>
                        </div>
                    </div>
                    <div class="col-md-6 col-12">
                        <div class="blog_left_details" data-aos="fade-up">
                            <a  [routerLink]="'/report'" target="_blank" class="desk__respurce">
                            <img src="assets/img/Resources_blog_4.jpg" alt="Resources_blog_4">
                            </a>
                            <p class="blog_itelic">Report</p>
                            <a [routerLink]="'/report'" target="_blank">
                            <h5 class="blue-dark">IoT - A Promising Innovation For The Emerging Australia</h5>
                        </a>
                         <a  [routerLink]="'/report'" target="_blank" class="mobie_respurce"> 
                            <img src="assets/img/Resources_blog_4.jpg" alt="Resources_blog_4">
                            </a>
                            <p class="blog_p">The Fourth Industrial Revolution or Industry 4.0 has made data, the new oil. Its advent has spearheaded data-driven technologies like Cloud, Big Data, AR/VR, AI, IoT and many more such breakthrough technologies.</p>
                            <a href="https://www.senstra.com.au/senstra_report_2020_highres.pdf" target="_blank" class="blue-dark">Download Now</a><span class="border_width"> | </span>
                            <a [routerLink]="'/report'" target="_blank" class="blue-light">Read More <img src="assets/img/left-arrow.svg"></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-1 col-12"></div>
        </div>
    </div>
</section>

<div class="desk_mobile" *ngIf="isShow" ><a  download="Senstra_Brochure.pdf" href="assets/Brochure/Senstra_Brochure.pdf"  class="btn btn-primary">Download brochure</a></div>