<div mat-dialog-content>
  <div class="modal-body Request_demo_new">
    <button type="button" class="close" (click)="onNoClick()">
      <span aria-hidden="true"><img src="assets/img/cancel.svg"></span>
    </button>
    <div class="sp5_right ml-auto d-flex align-items-center">
      <div class="Request_form_width">
        <form (ngSubmit)="onSubmit()" #contactForm="ngForm">
          <div class="row" [hidden]="submitted">
            <div class="col-md-12 col-12">
              <h2>Send It To Your Colleague</h2>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label>Name*</label>
                <input type="text" name="name" id="name" [(ngModel)]="model.name" class="form-control" required #name="ngModel" placeholder="Your Name">
                <div *ngIf="name.invalid && (name.dirty || name.touched)" class="error">
                  <div *ngIf="name.errors.required">
                    Name is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label>Email*</label>
                <input type="text" name="email" id="email" [(ngModel)]="model.email" class="form-control" required email #email="ngModel" placeholder="Email Address">
                <div *ngIf="email.invalid && (email.dirty || email.touched)" class="error">
                  <div *ngIf="email.errors.required">Email is required.</div>
                  <div *ngIf="email.errors.email">Email must be a valid email address.</div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label>Designation*</label>
                <input type="text" name="designation" id="designation" [(ngModel)]="model.designation" class="form-control" required #designation="ngModel" placeholder="Your Designation">
                <div *ngIf="designation.invalid && (designation.dirty || designation.touched)" class="error">
                  <div *ngIf="designation.errors.required">Designation is required.</div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label>Company Name*</label>
                <input type="text" name="comp_name" id="comp_name" [(ngModel)]="model.comp_name" class="form-control" required #comp_name="ngModel">
                <div *ngIf="comp_name.invalid && (comp_name.dirty || comp_name.touched)" class="error">
                  <div *ngIf="comp_name.errors.required">Company Name is required.</div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label>Sent To</label>
                <input type="text" name="coll_name" id="coll_name" [(ngModel)]="model.coll_name" class="form-control" required #coll_name="ngModel" placeholder="Receiver's Name">
                <div *ngIf="coll_name.invalid && (coll_name.dirty || coll_name.touched)" class="error">
                  <div *ngIf="coll_name.errors.required">
                    Receiver's Name is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label>Email ID</label>
                <input type="text" name="coll_email" id="coll_email" [(ngModel)]="model.coll_email" class="form-control" required coll_email #coll_email="ngModel" placeholder="Receiver's Email ID">
                <div *ngIf="coll_email.invalid && (coll_email.dirty || coll_email.touched)" class="error">
                  <div *ngIf="coll_email.errors.required">Receiver's Email ID is required.</div>
                  <div *ngIf="coll_email.errors.email">Receiver's Email ID must be a valid email address.</div>
                </div>
              </div>
            </div>
            <div class="col-12 submit_center">
              <button type="submit" class="btn btn-secondary SUbmit_btn">Submit</button>
            </div>
          </div>
        </form>
        <div class="service-error" *ngIf="error">
          <h1>{{error.errorTitle}}</h1>
          <h3>{{error.errorDesc}}</h3>
        </div>
        <div [hidden]="!submitted" class="contact-message">
          <div *ngIf="!error" class="contact-success">
            <h4>Hi! We have sent ePITome’s Technical Specification to the requested email ID.</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
