import { Component } from '@angular/core';

import * as AOS from 'aos';
import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Senstra';
  
  ngOnInit() {
   AOS.init({
      duration: 1200
    });

  
     
      /*=============================================
      =       Menu sticky & Scroll to top          =
      =============================================*/
      var windows = $(window);
      var screenSize = windows.width();
      var sticky = $('.header-sticky');
      var $html = $('html');
      var $body = $('body');
  
      windows.on('scroll', function () {
          var scroll = windows.scrollTop();
          var headerHeight = sticky.height();
  
          if (screenSize >= 320) {
              if (scroll < headerHeight) {
                  sticky.removeClass('is-sticky');
              } else {
                  sticky.addClass('is-sticky');
              }
          }
  
     });
      
      
      /*=========================================
      =            Preloader active            =
      ===========================================*/
  
      windows.on('load', function(){
          $(".preloader-activate").removeClass('preloader-active');
      });
      
      
      // jQuery(window).on('load', function(){
      //     setTimeout(function(){
      //     jQuery('.open_tm_preloader').addClass('loaded');
      //     }, 1000);
      // });
      
  
     
      
      /*===========================================
      =            Submenu viewport position      =
      =============================================*/
      
      if ($(".has-children--multilevel-submenu").find('.submenu').length) {
          var elm = $(".has-children--multilevel-submenu").find('.submenu');
          
          elm.each(function(){
              var off = $(this).offset();
              var l = off.left;
              var w = $(this).width();
              var docH = windows.height();
              var docW = windows.width() - 10;
              var isEntirelyVisible = (l + w <= docW);
  
              if (!isEntirelyVisible) {
                  $(this).addClass('left');
              }
          });
      }
      /*==========================================
      =            mobile menu active            =
      ============================================*/
      
      $("#mobile-menu-trigger").on('click', function(){
          $("#mobile-menu-overlay").addClass("active");
          $body.addClass('no-overflow');
      });
      
      $("#mobile-menu-close-trigger").on('click', function(){
          $("#mobile-menu-overlay").removeClass("active");
          $body.removeClass('no-overflow');
      });

      
      $(".offcanvas-navigation ul li a").on('click', function(){
          $("#mobile-menu-overlay").removeClass("active");
          $body.removeClass('no-overflow');
      });
      
      /*Close When Click Outside*/
      $body.on('click', function(e){
          var $target = e.target;
          if (!$($target).is('.mobile-menu-overlay__inner') && !$($target).parents().is('.mobile-menu-overlay__inner') && !$($target).is('#mobile-menu-trigger') && !$($target).is('#mobile-menu-trigger i')){
              $("#mobile-menu-overlay").removeClass("active");
              $body.removeClass('no-overflow');
          }
          if (!$($target).is('.search-overlay__inner') && !$($target).parents().is('.search-overlay__inner') && !$($target).is('#search-overlay-trigger') && !$($target).is('#search-overlay-trigger i')){
              $("#search-overlay").removeClass("active");
              $body.removeClass('no-overflow');
          }
      });
      
      
      /*===================================
      =           Menu Activeion          =
      ===================================*/
      var cururl = window.location.pathname;
      var curpage = cururl.substr(cururl.lastIndexOf('/') + 1);
      var hash = window.location.hash.substr(1);
      if((curpage == "" || curpage == "/" || curpage == "admin") && hash=="")
          {
          //$("nav .navbar-nav > li:first-child").addClass("active");
          } else {
              $(".navigation-menu li").each(function()
          {
              $(this).removeClass("active");
          });
          if(hash != "")
              $(".navigation-menu li a[href*='"+hash+"']").parents("li").addClass("active");
          else
          $(".navigation-menu li a[href*='"+curpage+"']").parents("li").addClass("active");
      }
      
      
      /*=========================================
      =             open menu Active            =
      ===========================================*/
       $('.openmenu-trigger').on('click', function (e) {
          e.preventDefault();
          $('.open-menuberger-wrapper').addClass('is-visiable');
      });
  
      $('.page-close').on('click', function (e) {
          e.preventDefault();
          $('.open-menuberger-wrapper').removeClass('is-visiable');

      });
      
        
      /*=========================================
      =             open menu Active            =
      ===========================================*/
      $("#open-off-sidebar-trigger").on('click', function(){
          $("#page-oppen-off-sidebar-overlay").addClass("active");
          $body.addClass('no-overflow');
      });
      
      $("#menu-close-trigger").on('click', function(){
          $("#page-oppen-off-sidebar-overlay").removeClass("active");
          $body.removeClass('no-overflow');
      });
      
      /*=============================================
      =            search overlay active            =
      =============================================*/
      
      $("#search-overlay-trigger").on('click', function(){
          $("#search-overlay").addClass("active");
          $body.addClass('no-overflow');
      });
      
      $("#search-close-trigger").on('click', function(){
          $("#search-overlay").removeClass("active");
          $body.removeClass('no-overflow');
      });
      
      /*=============================================
      =            hidden icon active            =
      =============================================*/
      
      // $("#hidden-icon-trigger").on('click', function(){
      //     $("#hidden-icon-wrapper").toggleClass("active");
      // });
      
  
      /*=============================================
      =            newsletter popup active            =
      =============================================*/
      
      $("#newsletter-popup-close-trigger").on('click', function(){
          $("#newsletter-popup").removeClass("active");
      });
      
      
      /*=========================================
      =             open menu Active            =
      ===========================================*/
       $('.share-icon').on('click', function (e) {
          e.preventDefault();
          $('.entry-post-share').toggleClass('opened');
      });
  
      $body.on("click", function () {
          $(".entry-post-share").removeClass('opened');
      });
      // Prevent closing dropdown upon clicking inside the dropdown
      $(".entry-post-share").on("click", function(e) {
          e.stopPropagation();
      });
      
      /*=============================================
      =            offcanvas mobile menu            =
      =============================================*/
      var $offCanvasNav = $('.offcanvas-navigation'),
          $offCanvasNavSubMenu = $offCanvasNav.find('.sub-menu');
      
      /*Add Toggle Button With Off Canvas Sub Menu*/
      $offCanvasNavSubMenu.parent().prepend('<span class="menu-expand"><i class="fa fa-angle-down" aria-hidden="true"></i></span>');
      
      /*Close Off Canvas Sub Menu*/
      $offCanvasNavSubMenu.slideUp();
      
      /*Category Sub Menu Toggle*/
      
      $offCanvasNav.on('click', 'li a, li .menu-expand', function(e) {
          var $this = $(this);
          if ( ($this.parent().attr('class').match(/\b(menu-item-has-children|has-children|has-sub-menu)\b/)) && ($this.attr('href') === '#' || $this.hasClass('menu-expand')) ) {
              e.preventDefault();
              if ($this.siblings('ul:visible').length){
                  $this.parent('li').removeClass('active');
                  $this.siblings('ul').slideUp();                  
              } else {
                  $this.parent('li').addClass('active');
                  $this.closest('li').siblings('li').removeClass('active').find('li').removeClass('active');
                  $this.closest('li').siblings('li').find('ul:visible').slideUp();
                  $this.siblings('ul').slideDown();
              }
          }
      });
      
    // Smooth scrolling using jQuery easing
    $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function() {
      if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
          $('html, body').animate({
            scrollTop: (target.offset().top - 50)
          }, 1000, "easeInOutExpo");
          return false;
        }
      }
    });
  
   
    // Activate scrollspy to add active class to navbar items on scroll
    // $('body').scrollspy({
    //   target: '#mainNav',
    //   offset: 50
    // });

  }
}
