import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import { MaterialModule } from './material.module';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/pages/home/home.component';
import { AboutComponent } from './components/pages/about/about.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ByindustryComponent } from './components/pages/byindustry/byindustry.component';

import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
import { SITSensorComponent } from './components/pages/sitsensor/sitsensor.component';
import { EPITomeComponent } from './components/pages/e-pitome/e-pitome.component';
import { TelcoInfrastructureComponent } from './components/pages/telco-infrastructure/telco-infrastructure.component';
import { IntelligentTrafficComponent } from './components/pages/intelligent-traffic/intelligent-traffic.component';
import { SupplyChainLogisticsComponent } from './components/pages/supply-chain-logistics/supply-chain-logistics.component';
import { SmartCitiesComponent } from './components/pages/smart-cities/smart-cities.component';
import { AllUseCaseComponent } from './components/pages/all-use-case/all-use-case.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { PlatformComponent } from './components/pages/platform/platform.component';
import { WhySenstraComponent } from './components/pages/why-senstra/why-senstra.component';
import { ResourcesComponent } from './components/pages/resources/resources.component';
import { AllSolusionComponent } from './components/pages/all-solusion/all-solusion.component';
import { WorkforceManagementComponent } from './components/pages/workforce-management/workforce-management.component';
import { TelcosComponent } from './components/pages/telcos/telcos.component';
import { ExclusiveSignsComponent } from './components/pages/exclusive-signs/exclusive-signs.component';
import { WhyIotStartupsComponent } from './components/pages/why-iot-startups/why-iot-startups.component';
import { ReportComponent } from './components/pages/report/report.component';
import { CheckCompatibilityComponent } from './components/pages/check-compatibility/check-compatibility.component';
import { RequestDemoComponent } from './components/modals/request-demo/request-demo.component';
import { YourcolleagueComponent } from './components/modals/yourcolleague/yourcolleague.component';
import { RequestATrailComponent } from './components/modals/request-a-trail/request-a-trail.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { KylePattisonComponent } from './components/modals/kyle-pattison/kyle-pattison.component';
import { PeterIngeComponent } from './components/modals/peter-inge/peter-inge.component';
import { HemPanickerComponent } from './components/modals/hem-panicker/hem-panicker.component';
import { NicolaDorlingComponent } from './components/modals/nicola-dorling/nicola-dorling.component';
import { KennethLamComponent } from './components/modals/kenneth-lam/kenneth-lam.component';
import { ChintanComponent } from './components/modals/chintan/chintan.component';
import { NehaComponent } from './components/modals/neha/neha.component';
import { HaydenComponent } from './components/modals/hayden/hayden.component';
import { SanketComponent } from './components/modals/sanket/sanket.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    ByindustryComponent,
    ContactUsComponent,
    SITSensorComponent,
    EPITomeComponent,
    TelcoInfrastructureComponent,
    IntelligentTrafficComponent,
    SupplyChainLogisticsComponent,
    SmartCitiesComponent,
    AllUseCaseComponent,
    ServicesComponent,
    PlatformComponent,
    WhySenstraComponent,
    ResourcesComponent,
    AllSolusionComponent,
    WorkforceManagementComponent,
    TelcosComponent,
    ExclusiveSignsComponent,
    WhyIotStartupsComponent,
    ReportComponent,
    CheckCompatibilityComponent,
    RequestDemoComponent,
    YourcolleagueComponent,
    RequestATrailComponent,
    KylePattisonComponent,
    PeterIngeComponent,
    HemPanickerComponent,
    NicolaDorlingComponent,
    KennethLamComponent,
    ChintanComponent,
    NehaComponent,
    HaydenComponent,
    SanketComponent, 

  ],
  imports: [
    BrowserModule,
    CarouselModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    NgxUsefulSwiperModule,
    BrowserAnimationsModule,
    MaterialModule,
    SlickCarouselModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
