     <section class="slider_section text-left all-use-case supply_new" id="introduction">
        <div class="container">
            <div class="slider_innertext ">
                <h2 data-aos="fade-up">Utilities (Power)</h2>
                <p data-aos="fade-up" class="Intelligent-text_p">
                  Senstra has a range of 4 devices specializing in the power industry. They are able to 
                  <br />
                  Remote reconnection or disconnection of electricity subscription, Fault Passage detection, Distribution Transformer Monitoring & Management,
                  Real-time energy auditing, PTR Losses and Health monitoring, Substation energy losses, Feeder Reliability and Outage Alerts.
                </p>
                <div class="text-left" data-aos="fade-up">
                    <button type="button" class="btn btn-primary" [routerLink]="'/contact-us'" >Drop a query</button>
        <button type="button" class="btn btn-secoundry" (click)="openDialog()">Request a demo</button>
                </div>
            </div>
        </div>
         <div class="moving-mouse-holder" >
    <a (click)="toRevolutionise()">
  <div class="mouse">
    <div class="mouse-button">&nbsp;</div>
  </div>
  <div class="text">Scroll To Explore</div>
</a>
</div>
    </section>

     <!--====================slider section end====================-->
    <div class="clearfix"></div>
            

   <section class="about_menu" id="mainNav">
        <div class="container">
            <div class="nav_center">
            <ul class="nav bottom_nav">    
              <li class="nav-item"><a class="js-scroll-trigger" (click)="toResources()"> Resources</a></li>
              <li class="nav-item"><a class="js-scroll-trigger" (click)="toDemo()">  Demo</a></li>
           </ul>
       </div>
        </div>
    </section>
     
    <div id="Revolutionise">
     <section class="Revolutionise-your-business text-center">
         <div class="container">
             <h3 class="blue-dark" data-aos="fade-up">Senstra has a range of 4 devices specializing in the power industry. They are able to </h3>
             <p>
              Remote reconnection or disconnection of electricity subscription
            </p>
            <p>
              Fault Passage detection
            </p>
            <p>
              Distribution Transformer Monitoring & Management
            </p>
            <p>
              Real-time energy auditing
            </p>
            <p>
              PTR Losses and Health monitoring
            </p>
            <p>
              Substation energy losses
            </p>
            <p>
              Feeder Reliability and Outage Alerts.
            </p>
         </div>
     </section>
     </div>
     <div class="clearfix"></div>
    <!--====================Our-Insights====================-->
    <section class="Our-Insights resources_div" >
        <div class="container" id="Resources">
          <h1 class="gray_color">Our <br> Insights</h1>
                <div class="row blog_inner mar_res">
                   <!--  <div class="col-md-6 col-12" data-aos="fade-up">
                        <div class="row">
                            <div class="col-md-6 col-12">
                                <div class="blog_left" >
                                    <p class="blog_itelic">Blog</p>
                                    <h5 class="blue-dark">How Is IoT The Most Promising Innovation For Telcos</h5>
                                     <img src="img/img_1.jpg" alt="img_1">
                                    <p class="blog_p">What do you imagine when you think about the future of the Australian Telecommunication Industry? If you are someone from the Telecommunication business, then surely you have got your thoughts rolling towards the $51 billion nbn HFC project and how it will turn out..</p>
                                    <a href="" class="blue-light">Read More <img src="img/left-arrow.svg"></a>
                                </div>
                            </div>
                            <div class="col-md-6 col-12">
                                <div class="blog_img">
                                    <img src="img/img_1.jpg" alt="img_1">
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-md-6 col-12 aos-init" data-aos="fade-up">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="blog_left">
              <p class="blog_itelic">Article</p>
              <a [routerLink]="'/exclusive-signs'" target="_blank" >
                <h5 class="blue-dark">4 Exclusive Signs Of Poor Asset Monitoring &amp; Soaring Need Of The Latest IoT Upgrade</h5>
              </a>
              <a [routerLink]="'/exclusive-signs'" target="_blank">
                <img src="assets/img/Resources_blog_2.png" alt="img_1">
              </a>
              <p class="blog_p">What is the pioneering characteristic of an effective asset monitoring system? In a worldwide survey conducted by IBM, 75% of asset managers cited system reliability as the single...</p>
              <a [routerLink]="'/exclusive-signs'" target="_blank" class="blue-light">Read More <img src="assets/img/left-arrow.svg"></a>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="blog_img">
              <a [routerLink]="'/exclusive-signs'" target="_blank">
                <img src="assets/img/img_2.jpg" alt="img_1">
              </a>
            </div>
          </div>
        </div>
      </div>
                    <div class="col-md-6 col-12 aos-init" data-aos="fade-up">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="blog_left">
              <p class="blog_itelic">Blog</p>
              <a [routerLink]="'/why-iot-startups'" target="_blank">
                <h5 class="blue-dark">Why IoT Startups Are More Innovative, Affordable, Competitive</h5>
              </a>
              <a [routerLink]="'/why-iot-startups'" target="_blank">
                <img src="assets/img/Resources_blog_3.png" alt="img_1">
              </a>
              <p class="blog_p">The Australian Startups ecosystem has been emerging with full throttle delivering innovative services by redefining the status quo. When we talk about startups the first thing that pops into our minds is... </p>
              <a [routerLink]="'/why-iot-startups'" target="_blank" class="blue-light">Read More <img src="assets/img/left-arrow.svg"></a>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="blog_img">
              <a [routerLink]="'/why-iot-startups'" target="_blank">
                <img src="assets/img/img_3.jpg" alt="img_1">
              </a>
            </div>
          </div>
        </div>
      </div>
                </div>
                <div class="row blog_inner">
                   
                     <div class="col-md-6 col-12" data-aos="fade-up">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="blog_left">
              <p class="blog_itelic">Report</p>
              <a [routerLink]="'/report'" target="_blank">
                <h5 class="blue-dark">Senstra's Annual Market Scan 2020</h5>
              </a>
              <a [routerLink]="'/report'" target="_blank"><img src="assets/img/Resources_blog_4.jpg" alt="img_1"></a>
              <p class="blog_p">The dawn of the fourth Industrial Revolution or Industry 4.0 has made data the new oil with the introduction of a number of data-driven technologies like Cloud, Big Data, AR/VR, AI, IoT, etc.</p>
              <a [routerLink]="'/report'" target="_blank" class="blue-light">Read More <img src="assets/img/left-arrow.svg"></a>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="blog_img">
              <a [routerLink]="'/report'" target="_blank">
                <img src="assets/img/img_4.jpg" alt="img_1">
              </a>
            </div>
          </div>
        </div>
      </div>
                </div>
                <div class="row">
                    <div class="col-12 text-center" data-aos="fade-up">
                        <button type="button" class="btn btn-secoundry allsolusion" [routerLink]="'/resources'">See all</button>
                    </div>
                </div>
        </div>
    </section>
    <!--====================Work Managment end====================-->
    <section  id="Demo">
    <section class="contact_us" data-aos="fade-up">
        <div class="container">
            <div class="contact_us_inner">
                <h2 class="blue-dark">Contact us for personalised demo</h2>
                <button type="button" class="btn btn-primary allsolusion" (click)="openDialog()">Request a demo</button>
                 
            </div>
        </div>
    </section>
    </section>

 