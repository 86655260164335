<!--====================slider section====================-->
<section class="slider_section text-left all-use-case Platform_banner" id="introduction">
  <div class="container">
    <div class="slider_innertext ">
      <h2 data-aos="fade-up">Platform</h2>
      <p data-aos="fade-up">Empower your business with our platform 'Heartbeat' which provides actionable insights, and accurate models and enables data-driven intelligent business decisions. Senstra’s AI-driven data analytics platform enables you easy access to telemetry data about your critical assets and tools for data visualization to draw business-specific insights.</p>
      <div class="text-left" data-aos="fade-up">
         <button type="button" class="btn btn-primary" [routerLink]="'/contact-us'" fragment="contactus_offset" >Drop a query</button>
        <button type="button" class="btn btn-secoundry" (click)="openDialog()">Request a demo</button>
        <a href="https://platform.senstra.com.au/index.html" class="btn btn-primary login_lineheight" target="_blank">login</a>
      </div>
    </div>
  </div>
  <div class="moving-mouse-holder" >
    <a (click)="toRevolutionise()">
  <div class="mouse">
    <div class="mouse-button">&nbsp;</div>
  </div>
  <div class="text">Scroll To Explore</div>
</a>
</div>
</section>
<!--====================slider section end====================-->
<div class="clearfix"></div>
<section class="about_menu" id="mainNav">
  <div class="container">
    <div class="nav_center">
      <ul class="nav bottom_nav" >
        <li class="nav-item"><a  (click)="toplateform ()" class="js-scroll-trigger">Platform</a></li>
      </ul>
    </div>
  </div>
</section>
<div id="Revolutionise">
<section class="Revolutionise-your-business text-center">
  <div class="container">
    <h3 class="blue-dark" data-aos="fade-up">Make data intelligence your biggest business asset</h3>
    <p data-aos="fade-up">Proactively maintain your assets before damage to your key physical assets and potential reputational effect. ePITome allows businesses to monitor and learn about their assets, collecting data and documenting events to enable proactive AI driven maintenance processes.</p>
  </div>
</section>
</div>
<div class="clearfix"></div>
<section class="Use-Cases" id="plateform">
  <div class="container">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10 col-12">
        <h1 class="gray_color" data-aos="fade-left">Platform</h1>
        <div class="row use-case-top_div m-t-0" id="Temperature" data-aos="fade-up">
          <div class="col-md-6 col-sm-12 col-12">
            <div class="use-case-left">
              <h3 class="blue-dark"> Get beyond just actionable insights</h3>
               <img src="assets/img/Platform-1.jpg" alt="Strategy & Design">
              <p>Our highly customized platform allows you to not only get the broad level view of all the assets deployed in the network, but also allows you to dive deeper into each asset to get detailed information around its history, performance and overall health.</p>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 col-12">
            <div class="use-case-right">
              <img src="assets/img/Platform-1.jpg" alt="Strategy & Design">
            </div>
          </div>
        </div>
        <div class="row use-case-top_div" id="Pressure" data-aos="fade-up">
          <div class="col-md-6 col-sm-12 col-12">
            <div class="use-case-left">
              <h3 class="blue-dark"> Experience seamless integration with rest APIs</h3>
               <img src="assets/img/Platform-2.jpg" alt="Group 2288">
              <p>Our platform uses microservices architecture that decouples REST API for each of the sensors and the front end to support Apps and Web applications. Each sensor comes with its own API; so if you want to use your own platform instead of Senstra’s to generate the insights, real time alerts or notifications to the team; you can do so with easy REST API access that is highly customizable to suit your needs.
              </p>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 col-12">
            <div class="use-case-right">
              <img src="assets/img/Platform-2.jpg" alt="Group 2288">
            </div>
          </div>
        </div>
        <div class="row use-case-top_div" id="Intrusion-detection" data-aos="fade-up">
          <div class="col-md-6 col-sm-12 col-12">
            <div class="use-case-left">
              <h3 class="blue-dark">Enjoy total customisation to meet business needs</h3>
              <img src="assets/img/Platform-3.jpg" alt="Group 2289">
              <p>Our platform also allows you to customise threshold for the notification for each sensor for each device giving you complete control of your assets. Now, your KPIs can drive your monitoring requirements.</p>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 col-12">
            <div class="use-case-right">
              <img src="assets/img/Platform-3.jpg" alt="Group 2289">
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>
  </div>
</section>
