import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequestDemoComponent } from '../modals/request-demo/request-demo.component';
import { Router, NavigationStart } from '@angular/router';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  solutions_menu_hover: boolean = false;
  products_menu_hover: boolean = false;
  about_menu_hover: boolean = false;

 email: string;
  
  constructor(public dialog: MatDialog, private router: Router) {
    router.events.subscribe((val) =>{
      if(val instanceof NavigationStart) {
        // check the url if it is ('all-use-case') and contain sticky header then adjust the view
        if(val.url.includes('all-use-case#')){
          setTimeout(() => {
            var element = document.getElementById(val.url.split('#')[1]);
         
            element.scrollIntoView({
              block: "end",
              behavior: "smooth",
            });         
          }, 250);
        }          
      }
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(RequestDemoComponent,  {
      maxWidth: '50vw',
      maxHeight: '75vh',
      height: '100%',
      width: '100%'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.email = result;
    });
  }

  ngOnInit(): void {
  }
  
}
