<div class="mat-dialog-content_new" mat-dialog-content>
  <div class="modal-dialog modal-lg team_modal" role="document">
    <div class="modal-content">
    	<div class="modal-body">
	        <button type="button" class="close" (click)="onNoClick()">
	          <span aria-hidden="true"><img src="assets/img/cancel.svg"></span>
	        </button>
                <div class="row modal_bg_left">
                  <div class="col-md-5 col-sm-12 col-12">
                    <div class="team_left">
                      <img src="assets/img/Hem Panicker.png">
                      <h4 class="blue-dark">Hem Panicker</h4>
                      <p>Chief Customer Officer</p>
                    </div>
                  </div>
                  <div class="col-md-7 col-sm-7 col-12">
                    <div class="team_right">
                      <p>Hem oversees the business development of Senstra. He is in charge of locating, developing, defining, and closing business relationships. Additionally, Hem helps with the following:
                      </p>
                      <ul>
                        <li>Helps with pivotal decision making of the company.</li>
                        <li>Evaluates options and resolves internal priorities.</li>
                        <li>Tracks individual contributors and their accomplishments towards business development.</li>
                        <li>Discovers and explores business opportunities.</li>
                        <li>Screens potential business opportunities by analyzing market strategies, deal requirements, and financials.</li>
                      </ul>
                      <a href="https://www.linkedin.com/in/hempanicker/" target="_blank" class="blue-light">Linkedin <img src="assets/img/left-arrow.svg"></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>