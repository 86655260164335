<section class="privay_policy"> 
	<div class="container">
		<h5>Privacy Policy</h5>
		<p>We at Senstra IoT Solutions (“Senstra”, “we”, “us”, “our”) respect your privacy. This Privacy Policy (“Policy”) explains the privacy practices for our website at senstra.com.au (the “Website”). It also describes the rights and options available to you with respect to your information</p>
		<p>The Website provides content and information about Senstra, our products, and services (“Content”).</p>
		<h5 class="h5_p">Personal data collected</h5>
		<p>We collect the information you provide us voluntarily and knowingly. You are not legally required to provide us your information.</p>
		<p><strong>Inquires:</strong> You can submit an inquiry through any of our online contact forms. You will be asked to provide information such as your name, email address, contact number, country of origin, the topic of your inquiry, and the text of your message. The mandatory fields for completion will be marked. We refer to this entire data as “Inquiry Information”.
        </p>
        <p><strong>Demo/Trial:</strong> You can request for a demo or trial through any of our online ‘Request a demo’ or ‘Request a Trial’ form.  You will be asked to provide information such as your name, email address, contact number, designation, organization name, country of origin, and the text of your message. The mandatory fields for completion will be marked. We refer to this entire data as “Demo/Trial Request.”
        </p>
        <p><strong>We also collect analytics information about your use of the Website and your interaction with our email messages.</strong></p>

        <p>When you visit our Website, we will record and collect certain information in relation to your visit and use of the Website and your interaction with our email messages, including IP address (and the general location corresponding to the address), time and date of access, type of browser used, language use, links clicked and the web pages you accessed. We refer to this data as “Analytics Information”.</p>

        <h5>How we use your personal data</h5>
        <h5 class="h5_p respond">To respond to your enquiry</h5>
        <p>We will use your Inquiry Information to contact you about your inquiry and handle your inquiry. </p>

        <h5 class="h5_p respond">We will send you our newsletter if you indicated your desire to receive it.</h5>

        <p>If you indicated your desire to receive our newsletter, we will send you our newsletter from time to time. You can always opt-out from our newsletter by following the unsubscribe link included in each newsletter, or by contacting us and with your opt-out request.</p>

        <p>We use Analytics Information to improve and enhance the Website, our services and our email communications. We also use it for business administration purposes.</p>

        <p>We use Analytics Information to improve the Website and enrich the content on the Website, our email communications, and the products and services we offer to customers. We also use the Analytical Information to adapt the Website, its content, and services and our email communications, to users’ preferences. Finally, we will use the Analytics Information to understand the market in which Senstra operates in and for managerial reporting and business planning.</p>

        <div class="">
       <h5 class="h5_p respond">When and how we share personal data with others</h5>
        <p>We will not share your information with third-parties, except in the events listed below or when you provide us your explicit and informed consent.</p>
        <ul>
        	<li>We share your information with our subsidiaries.</li>
        	<li>We will share your information with members of our family group of companies, who help us process the data for the purpose set out above.</li>
        	<li>We share your information with our service providers helping us operate our business, the Website, and the products.</li>
        	<li>We will share your personal information with our service providers as well as professional consultants such as tax advisors and auditors, who assist us with the internal operations of our Website and other aspects of our business. These companies are authorized to use your personal information only as necessary to provide these services to us and not for their own promotional purposes. We do not sell your personal information to third parties.</li>
        	<li>If you have breached the terms of use of the Website, this Policy or any other agreement you have with Senstra, abused your rights to use the Website, or violated any applicable law. Your information will be shared with competent authorities and with third parties (such as legal counsels and advisors), for the purpose of handling the violation or breach.</li>
        	<li>We will share your information if we are legally required.</li>
        	<li>If we are required to disclose your information by a judicial, governmental, or regulatory authority.</li>
        	<li>We will share your information with third-party in any event of a change in Senstra’s structure.</li>
        	<li>If the operation of Senstra or the Website is organized within a different framework or through another legal structure or entity (such as due to a merger or acquisition, we will share your information to enable the structural change.</li>
        </ul>
        </div>
        
        <div class="privacy_margin">
        	 <h5>Cookies and other trackers</h5>
        	<h5 class="h5_p respond">What are cookies?</h5>
            <p>Cookies are text files, comprised of a small amount of data, that are saved on your computer or another device (e.g. smartphone, tablet, etc.) when you use the internet and visit various websites.</p>
            <p>The information that the cookies maintain is read by the website you visit, during the session of your visit to the website (these are called ‘session’ cookies), and when you return to visit it again (these are called ‘persistent’ cookies).</p>
            <p>We also use techniques called web beacons and web pixels for purposes similar to the use of cookies.</p>
            <p>We use cookies necessary to operate the website, for website statistics, for marketing purposes and to remember your website preferences.</p>
            <p>We use cookies for a number of purposes, as briefly explained below. By using the Website, you consent to our use of these cookies</p>
            <p><strong>Necessary:</strong> Cookies that are strictly necessary for the functioning of the Website. The Website cannot operate properly without these cookies. You can set your browser to block or alert you about these cookies, but some parts of the Website may not function properly.</p>
            <p><strong>Preferences:</strong> Cookies that remember your choices when you use the Website and allow the Website to react by reflecting your preferences.</p>
            <p><strong>Statistics:</strong> Cookies that help us understand how you and other users interact with our Website by collecting data that does not directly identify you.</p>
            <p><strong>Marketing:</strong> Cookies that track your use of the Website and allow us to tailor content, both on and off the Website, that we believe is relevant to you.</p>
           <h5 class="h5_p respond">You can always delete or disable cookies.</h5>
            <p>You can always delete the cookies saved on your device through your computer browser or your device. You can also disable cookies for future uses through the settings options of your computer browser or device.</p>
            <h5 class="h5_p respond">Security and data retention</h5>
            <p>We will retain your personal information for as long as we need it to operate the Website and interact with customers, and thereafter as needed for record-keeping matters.</p>
            <p>We retain personal data for the duration needed to support our ordinary business activities operating the Website and interacting with customers. Thereafter, we will still retain your personal information as necessary to comply with our legal obligations, resolve disputes, establish and defend legal claims, and enforce our agreements.</p>
            <h5 class="h5_p respond">We implement measures to secure your information.</h5>
            <p>We implement measures to reduce the risks of damage, loss of information, and unauthorized access or use of information. However, these measures do not provide absolute information security. Therefore, although efforts are made to secure your personal information, it is not guaranteed, and you cannot expect that the Service will be immune to information security risks.</p>
            <p>The Service is not intended for minors under the age of 18. IF YOU ARE UNDER THE AGE OF 18 YOU MAY NOT USE THE SERVICE IN ANY WAY.</p>
            <p>Changes to this Privacy Policy<br>
            If we change this Policy, we will post the updated policy on the Website.</p>
            <p>From time to time, we may change this Policy, in which case we post the updated Policy on the Website. The latest version of the Policy will always be accessible on the Website.</p>
        </div>

       
        	

      
        <div class="privacy_margin">
        	<h5 class="h5_p respond">Contact us</h5>
            <p>You can contact us at <a href="mailTo:iot@senstra.com.au">iot@senstra.com.au</a></p>
            <p>Should you have any inquiries, complaints or suggestions, you may contact us at <a href="mailTo:iot@senstra.com.au">iot@senstra.com.au</a> We will do our best to resolve your issue in a timely manner.</p>
            <p>Last Update: September 1, 2020</p>
            
        </div>

	</div>
</section>