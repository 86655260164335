import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequestDemoComponent } from '../../modals/request-demo/request-demo.component';


@Component({
  selector: 'app-all-use-case',
  templateUrl: './all-use-case.component.html',
  styleUrls: ['./all-use-case.component.css']
})
export class AllUseCaseComponent implements OnInit {



 email: string;
  
  constructor(public dialog: MatDialog) {}

  openDialog(): void {
    const dialogRef = this.dialog.open(RequestDemoComponent,  {
      maxWidth: '50vw',
      maxHeight: '75vh',
      height: '100%',
      width: '100%'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.email = result;
    });
  }
  ngOnInit(): void {

   $(document).ready(function($) {

  var $navBar = $('#mainNav');
  var showNavBarMinimum = $('#introduction').height() - $navBar.height();

  var wasNavBarVisible = false;
  // change nav bar visibility on scroll
  function onScroll() {
    var isNavBarVisible = window.pageYOffset >= showNavBarMinimum;
    if ( isNavBarVisible != wasNavBarVisible ) {
      $navBar.toggleClass('show');
      wasNavBarVisible = isNavBarVisible;
    }
  }
  // initial check
  onScroll();

  // http://davidwalsh.name/function-debounce
  function debounce( fn, wait ) {
    var timeout;
    return function() {
      var _this = this;
      var args = arguments;
      var later = function() {
        timeout = null;
        fn.apply( _this, args );
      };
      clearTimeout( timeout );
      timeout = setTimeout( later, wait || 100 );
    };
  }

  $(window).scroll( debounce( onScroll, 30 ) );

 
  });
  }

 
toRevolutionise(){
document.getElementById("Revolutionise").scrollIntoView({behavior:"smooth" , block: "start"});
}
 toTemperature(){
document.getElementById("Temperature").scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
}
 toPressure(){
document.getElementById("Pressure").scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
}
 toPowerDetection(){
document.getElementById("PowerDetection").scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
}
 toMotion(){
document.getElementById("Motion").scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
}
 toVibration(){
document.getElementById("Vibration").scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
}
 toDistanceLevel(){
document.getElementById("DistanceLevel").scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
}
 toGyroscope(){
document.getElementById("Gyroscope").scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
}
 toGasDetection(){
document.getElementById("GasDetection").scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
}
 toIntrusiondetection(){
document.getElementById("Intrusiondetection").scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
}
 toLight(){
document.getElementById("Light").scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
}

 

}
